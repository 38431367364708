import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ToastContainer, toast } from "react-toastify";
import Slider from "react-slick";
import { UserContext } from "../App";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Select from "react-select";
import dragg from "./drag-handle-minor.svg";
import ConfirmationPopUp from "./ConfirmationPopUp.js";

import { Draggable } from "@hello-pangea/dnd";

import "./quotationCreation.css";
import "./QueryDetails.css";
const RowProduct = ({
  row,
  open,
  handleProductDelete,
  handleValueEdited,
  vendorsList,
  warrantyDurationOptions,
  setStoredItemsChecklist,
  // handleChangeVendor,
  index,
  widthMap,
  onRowClick,
  queryId,
}) => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  // const [open, setOpen] = useState(false);
  const [editedValue, setEditedValue] = useState(`${row.cpegst}`);
  const [openCell, setOpenCell] = useState(true);
  const [openCell2, setOpenCell2] = useState(false);
  const [openCell3, setOpenCell3] = useState(true);
  const [openCell4, setOpenCell4] = useState(false);
  const [openCell5, setOpenCell5] = useState(true);
  const [openCell6, setOpenCell6] = useState(true);
  const [openCell7, setOpenCell7] = useState(true);
  const [editedValue4, setEditedValue4] = useState(
    row.minspegst ? `${row.minspegst}` : ""
  );
  const [editedValue5, setEditedValue5] = useState(row.mrp ? `${row.mrp}` : "");
  const [editedValue3, setEditedValue3] = useState(
    row.mspegst ? `${row.mspegst}` : ""
  );
  const [quantity, setQuantity] = useState(`${row.quantity}`);
  const [editedValue2, setEditedValue2] = useState(`${row.margin}`);
  // console.log(editedValue2);

  const [spegst, setSpegst] = useState(`${row.spegst}`);

  const [spigst, setSpigst] = useState(`${row.spigst}`);
  const [description, setDescription] = useState(
    row.description ? `${row.description}` : ""
  );
  const [isEditDescription, setIsEditeDescription] = useState(false);

  const [openvendorPopUp, setOpenVendorPopUp] = useState(false);
  const [openWarrantyPopUp, setOpenWarrantyPopUp] = useState(false);
  const [attachedImage, setAttachedImage] = useState("");
  const [uploadImageLoader, setUploadImageLoader] = useState(false);
  const [imagerow, setImageRow] = useState(row.image);
  const [warranty_duration, setWarrantyDuration] = useState(
    row.warranty_duration
  );
  const [showDeleteImagePopUp, setShowDeleteImagePopUp] = useState(false);
  const [showSaveSkuPopUp, setShowSaveSkuPopUp] = useState(false);
  const [deletImageIndex, setDeletImageIndex] = useState();
  // console.log(row.image);

  const [amount, setAmount] = useState(
    (row.cpegst * quantity * (1 + parseFloat(row.gst) / 100)) /
      (1 - parseFloat(row.margin) / 100) || ""
  );

  const changeVendor = (newVendor) => {
    const selectedItems = JSON.parse(localStorage.getItem("selectedItems"));

    const selectedSku = selectedItems.find(
      (itemAdded) => itemAdded.sku === row.sku
    );
    // console.log(selectedSku);
    if (selectedSku) {
      selectedSku.partner_name = newVendor.value;
      selectedSku.prospect_id_Vendor = newVendor.prospect_id;
    }
    row.partner_name = newVendor.value;
    row.prospect_id_Vendor = newVendor.prospect_id;

    localStorage.setItem("selectedItems", JSON.stringify(selectedItems));
  };

  const handleChangeVendor = () => {
    // setSelectedVendorSku(`${row.sku}`);
    setOpenVendorPopUp(true);
  };

  const handleShowDeleteImagePopUp = (index) => {
    setDeletImageIndex(index);
    setShowDeleteImagePopUp(true);
  };
  const handleHideDeleteImagePopUp = () => {
    setShowDeleteImagePopUp(false);
  };
  const changeWarranty = (newWarranty) => {
    const selectedItems = JSON.parse(localStorage.getItem("selectedItems"));

    const selectedSku = selectedItems.find(
      (itemAdded) => itemAdded.sku === row.sku
    );
    // console.log(newWarranty);
    if (selectedSku) {
      selectedSku.warranty_duration = newWarranty.value;
    }
    row.warranty_duration = newWarranty.value;
    localStorage.setItem("selectedItems", JSON.stringify(selectedItems));
    if (queryId) {
      const cachedData = JSON.parse(
        localStorage.getItem(`queryDetailsData_${queryId}`)
      );
      cachedData.itemDetails = selectedItems;
      localStorage.setItem(
        `queryDetailsData_${queryId}`,
        JSON.stringify(cachedData)
      );
    }
  };

  const handleSaveSkuPopupOpen = () => {
    setShowSaveSkuPopUp(true);
  };

  const handleSaveSkuPopupClose = () => {
    setShowSaveSkuPopUp(false);
  };

  const handleChangeWarranty = () => {
    setOpenWarrantyPopUp(true);
  };
  const handleUploadPic = async (e) => {
    // e.preventDefault();

    let responseImageData;
    let retryCount = 0;
    const maxRetries = 2;
    if (attachedImage !== "") {
      setUploadImageLoader(true);
      while (retryCount <= maxRetries) {
        try {
          const formData = new FormData();
          formData.append("file", attachedImage);

          const responseImage = await fetch("/quotegen/uploadImages", {
            method: "POST",
            body: formData,
          });

          if (!responseImage.ok) {
            throw new Error("Failed to upload files.");
          }

          responseImageData = await responseImage.json();
          if (responseImageData.success) {
            const updateSelectedItems = JSON.parse(
              localStorage.getItem("selectedItems")
            );
            updateSelectedItems.forEach((item) => {
              if (item.sku === row.sku) {
                item.image.push(responseImageData.imageLink);
              }
            });

            localStorage.setItem(
              `selectedItems`,
              JSON.stringify(updateSelectedItems)
            );
            setStoredItemsChecklist(updateSelectedItems);

            const cachedQuery = window.location.href.split("/")[4];
            if (cachedQuery) {
              const updateCacheData = JSON.parse(
                localStorage.getItem(`queryDetailsData_${cachedQuery}`)
              );
              updateCacheData.itemDetails = updateSelectedItems;
              localStorage.setItem(
                `queryDetailsData_${cachedQuery}`,
                JSON.stringify(updateCacheData)
              );
            }

            // const newImage = row.image.push(responseImageData.imageLink);
            // console.log(newImage);
            // setImageRow(newImage);
            setImageRow((prevImageRow) => [
              ...prevImageRow,
              responseImageData.imageLink,
            ]);

            toast.success(`Image Uploaded Successfully for SKU ${row.sku}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
            const chooseImageAttachment = document.getElementById(
              "choose-image-attachment"
            );
            chooseImageAttachment.value = null;
            break;
            // fileInputRef.current.reset();
          }
          retryCount++;
          //  else {
          //   setUploadImageLoader(false);
          //   setAttachedImage("");
          //   return;
          // }
        } catch (error) {
          if (retryCount <= maxRetries) {
            retryCount++;
            continue;
          } else {
            console.error("Error occurred during image upload:", error);
            toast.error(`Error occurred during image upload`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } finally {
          setAttachedImage("");
          setUploadImageLoader(false);
        }
      }
    } else {
      alert("No image selected");
    }
  };

  const handleDelPic = (index) => {
    setUploadImageLoader(true);
    const updateSelectedItems = JSON.parse(
      localStorage.getItem("selectedItems")
    );
    updateSelectedItems.filter((item) => {
      if (item.sku === row.sku) {
        item.image.splice(index, 1);
      }
    });

    localStorage.setItem(`selectedItems`, JSON.stringify(updateSelectedItems));
    setStoredItemsChecklist(updateSelectedItems);
    const cachedQuery = window.location.href.split("/")[4];
    if (cachedQuery) {
      const updateCacheData = JSON.parse(
        localStorage.getItem(`queryDetailsData_${cachedQuery}`)
      );

      updateCacheData.itemDetails = updateSelectedItems;
      localStorage.setItem(
        `queryDetailsData_${cachedQuery}`,
        JSON.stringify(updateCacheData)
      );
    }
    // const newRow = imagerow.splice(index, 1);
    // console.log(newRow);
    // setImageRow(newRow);
    const newImagerow = imagerow.filter((_, idx) => idx !== index);
    setImageRow(newImagerow);

    toast.success(`Image Deleted For SKU ${row.sku}`, {
      position: toast.POSITION.TOP_RIGHT,
    });
    setUploadImageLoader(false);
    setShowDeleteImagePopUp(false); //
  };

  useEffect(() => {
    setAmount(
      (row.cpegst * quantity * (1 + parseFloat(row.gst) / 100)) /
        (1 - parseFloat(row.margin) / 100) || ""
    );
    setSpegst(`${row.cpegst / (1 - parseFloat(row.margin) / 100)}`);
    setSpigst(
      `${
        (row.cpegst * (1 + parseFloat(row.gst) / 100)) /
        (1 - parseFloat(row.margin) / 100)
      }`
    );
    setEditedValue2(`${row.margin}`);
  }, [row.cpegst, quantity, row.margin, row.gst, imagerow]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    setWarrantyDuration(`${row.warranty_duration}`);
    setEditedValue(`${row.cpegst}`);
    setEditedValue2(`${row.margin}`);
    setEditedValue3(row.mspegst ? `${row.mspegst}` : "");
    setEditedValue4(row.minspegst ? `${row.minspegst}` : "");
    setEditedValue5(row.mrp ? `${row.mrp}` : "");
    setAmount(
      (row.cpegst * quantity * (1 + parseFloat(row.gst) / 100)) /
        (1 - parseFloat(row.margin) / 100) || ""
    );

    setImageRow(row.image);
    setDescription(`${row.description}`);
    setSpigst(
      `${
        (row.cpegst * (1 + parseFloat(row.gst) / 100)) /
        (1 - parseFloat(row.margin) / 100)
      }`
    );
    setSpegst(`${row.cpegst / (1 - parseFloat(row.margin) / 100)}`);
    setQuantity(row.quantity);
  }, [row]);

  const warrantyStyles = {
    menu: (provided) => ({
      ...provided,
      height: "fit-content",
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "40vh",
    }),
  };
  const vendorStyles = {
    menu: (provided) => ({
      ...provided,
      height: "fit-content",
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "54vh",
    }),
  };

  const handleNavigateToVendors = () => {
    navigate("/vendorsDetails");
  };

  const [savingSku, setSavingSku] = useState(false);

  const handleSaveSku = async () => {
    setSavingSku(true);
    try {
      const toPass = {
        sku: row.sku,
        description: description,
        images: imagerow,
      };
      const saveResponse = await fetch("/quotegen/saveSku", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(toPass),
      });

      if (saveResponse.ok) {
        toast.success(`SKU Updated Successfully for SKU ${row.sku}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        const errorData = await saveResponse.json();
        throw new Error(errorData.message || "Unknown error");
      }
    } catch (error) {
      console.error("Error updating SKU:", error);
      toast.error(`SKU Update Unsuccessful for SKU ${row.sku}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setSavingSku(false);
      setShowSaveSkuPopUp(false);
    }
  };

  return (
    <React.Fragment>
      <Draggable draggableId={`${row.sku}${index}`} index={index}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div
              style={{
                position: "absolute",
              }}
            >
              <img
                src={dragg}
                alt=""
                style={{
                  width: "15px",
                  height: "20px",
                  marginTop: "48px",
                  opacity: "0.7",
                }}
              />
            </div>

            <TableRow
              sx={{
                "& > *": { borderBottom: "unset" },
                border: "1px solid rgb(224, 224, 224,1 )",
              }}
              style={{ backgroundColor: "white", userSelect: "none" }}
            >
              <TableCell
                align="center"
                sx={{ width: "35px", minWidth: "35px" }}
              >
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={onRowClick}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                align="center"
                style={{
                  fontWeight: 500,
                  whiteSpace: "pre-wrap",
                }}
                sx={{
                  width: widthMap["Product Details"],
                  minWidth: widthMap["Product Details"],
                }}
              >
                <div>{row.name}</div>
                <div>{row.brand}</div>
                <div>{row.size}</div>
                <div>{row.sku}</div>
              </TableCell>
              {user && user.role === "category" && (
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  style={{
                    fontWeight: 500,
                    maxWidth: 200,
                    whiteSpace: "pre-wrap",
                    color: "#0081df",
                  }}
                  sx={{
                    width: widthMap["Partner Name"],
                    minWidth: widthMap["Partner Name"],
                  }}
                  onClick={() => handleChangeVendor()}
                >
                  {row.partner_name}
                </TableCell>
              )}
              <TableCell
                component="th"
                scope="row"
                align="center"
                style={{
                  fontWeight: 500,
                  maxWidth: 200,
                  whiteSpace: "pre-wrap",
                  color: user && user.role === "sales" ? "" : "#0081df",
                }}
                sx={{
                  width: widthMap["Warranty"],
                  minWidth: widthMap["Warranty"],
                }}
                onClick={() =>
                  user &&
                  (user.role === "category" ||
                    user.role === "superuser" ||
                    user.role === "pricing")
                    ? handleChangeWarranty()
                    : undefined
                }
              >
                {row.warranty_duration}
              </TableCell>
              {user && user.role !== "sales" && (
                <TableCell
                  align="center"
                  style={{
                    fontWeight: 500,
                    color:
                      user &&
                      user.role === "category" &&
                      window.location.pathname !== "/manualQuotation"
                        ? "#0081df"
                        : "",
                    cursor: "pointer",
                  }}
                  sx={{
                    width: widthMap["Cost Per Unit(Excl GST)"],
                    minWidth: widthMap["Cost Per Unit(Excl GST)"],
                  }}
                  onClick={() => setOpenCell(true)}
                >
                  {openCell &&
                  user &&
                  user.role === "category" &&
                  window.location.pathname !== "/manualQuotation" ? (
                    <TextField
                      variant="filled"
                      value={editedValue}
                      // onChange={handleValueEdited}
                      onChange={(e) => {
                        const newValue = e.target.value.replace(/[^0-9.]/g, "");
                        setEditedValue(newValue);
                      }}
                      onBlur={() => {
                        handleValueEdited("cpegst", editedValue);
                      }}
                      onKeyDown={(e) =>
                        e.key === "Enter"
                          ? handleValueEdited("cpegst", editedValue)
                          : null
                      }
                      style={{
                        minWidth: "80px",
                        width: "fit-content",
                        height: "50px",
                      }}
                      inputProps={{ style: { background: "white" } }}
                    />
                  ) : (
                    editedValue
                  )}
                </TableCell>
              )}
              <TableCell
                align="center"
                style={{ fontWeight: 500 }}
                sx={{ width: widthMap["GST%"], minWidth: widthMap["GST%"] }}
              >
                {row.gst}
              </TableCell>
              <TableCell
                align="center"
                style={{
                  fontWeight: 500,
                  color: user && user.role === "category" ? "#0081df" : "",
                  cursor: "pointer",
                }}
                sx={{
                  width: widthMap["Market Selling Price(Excl GST)"],
                  minWidth: widthMap["Market Selling Price(Excl GST)"],
                }}
                onClick={() => setOpenCell5(true)}
              >
                {openCell5 && user && user.role === "category" ? (
                  <TextField
                    value={editedValue3}
                    type="number"
                    variant="filled"
                    style={{
                      minWidth: "80px",
                      width: "fit-content",
                      height: "50px",
                    }}
                    inputProps={{ style: { background: "white" } }}
                    // onChange={handleValueEdited}
                    onChange={(e) => setEditedValue3(e.target.value)}
                    onBlur={() => {
                      handleValueEdited("mspegst", editedValue3);
                    }}
                    onKeyDown={(e) =>
                      e.key === "Enter"
                        ? handleValueEdited("mspegst", editedValue3)
                        : null
                    }
                    // fullWidth
                  />
                ) : editedValue3.trim() === "" ? (
                  "-"
                ) : (
                  parseFloat(editedValue3).toFixed(2)
                )}
              </TableCell>
              <TableCell
                align="center"
                style={{
                  fontWeight: 500,
                  color: user && user.role === "category" ? "#0081df" : "",
                  cursor: "pointer",
                }}
                sx={{
                  width: widthMap["Minimum Selling Price(Excl GST)"],
                  minWidth: widthMap["Minimum Selling Price(Excl GST)"],
                }}
                onClick={() => setOpenCell6(true)}
              >
                {/* {row.mrp}{" "} */}
                {openCell6 && user && user.role === "category" ? (
                  <TextField
                    value={editedValue4}
                    type="number"
                    variant="filled"
                    style={{
                      minWidth: "80px",
                      width: "fit-content",
                      height: "50px",
                    }}
                    inputProps={{ style: { background: "white" } }}
                    onChange={(e) => setEditedValue4(e.target.value)}
                    onBlur={() => {
                      handleValueEdited("minspegst", editedValue4);
                    }}
                    onKeyDown={(e) =>
                      e.key === "Enter"
                        ? handleValueEdited("minspegst", editedValue4)
                        : null
                    }
                    // fullWidth
                  />
                ) : editedValue4.trim() === "" ? (
                  "-"
                ) : (
                  parseFloat(editedValue4).toFixed(2)
                )}
              </TableCell>
              <TableCell
                align="center"
                style={{
                  fontWeight: 500,
                  color: user && user.role === "category" ? "#0081df" : "",
                  cursor: "pointer",
                }}
                sx={{ width: widthMap["MRP"], minWidth: widthMap["MRP"] }}
                onClick={() => setOpenCell7(true)}
              >
                {openCell7 && user && user.role === "category" ? (
                  <TextField
                    value={editedValue5}
                    type="number"
                    variant="filled"
                    style={{
                      width: "fit-content",
                      height: "50px",
                    }}
                    inputProps={{ style: { background: "white" } }}
                    onChange={(e) => setEditedValue5(e.target.value)}
                    onBlur={() => {
                      handleValueEdited("mrp", editedValue5);
                    }}
                    onKeyDown={(e) =>
                      e.key === "Enter"
                        ? handleValueEdited("mrp", editedValue5)
                        : null
                    }
                    // fullWidth
                  />
                ) : editedValue5.trim() === "" ? (
                  "-"
                ) : (
                  parseFloat(editedValue5).toFixed(2)
                )}
              </TableCell>
              {user && user.role !== "sales" && (
                <TableCell
                  align="center"
                  style={{
                    fontWeight: 500,
                    color:
                      user &&
                      (user.role === "superuser" || user.role === "pricing")
                        ? "#0081df"
                        : "",
                    cursor: "pointer",
                  }}
                  sx={{
                    width: widthMap["Margin(in %)"],
                    minWidth: widthMap["Margin(in %)"],
                  }}
                  onClick={() => setOpenCell2(true)}
                >
                  {openCell2 &&
                  user &&
                  (user.role === "superuser" || user.role === "pricing") ? (
                    <TextField
                      value={editedValue2}
                      variant="filled"
                      style={{ width: "fit-content", height: "50px" }}
                      inputProps={{
                        style: {
                          background: "white",
                          fontSize: "0.875rem",
                        },
                      }}
                      onChange={(e) => {
                        setEditedValue2(e.target.value);
                      }}
                      onFocus={() => {
                        setOpenCell2(true);
                      }}
                      onBlur={() => {
                        handleValueEdited("margin", editedValue2);
                        setOpenCell2(false);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === "Tab") {
                          handleValueEdited("margin", editedValue2);
                          setOpenCell2(false);
                        }
                      }}
                      // fullWidth
                    />
                  ) : (
                    `${parseFloat(row.margin).toFixed(2)}%`
                  )}
                </TableCell>
              )}
              <TableCell
                align="center"
                style={{
                  fontWeight: 500,
                  color:
                    user &&
                    (user.role === "superuser" || user.role === "pricing")
                      ? "#0081df"
                      : "",
                  cursor: "pointer",
                }}
                sx={{
                  width: widthMap["Selling Price(Excl GST)"],
                  minWidth: widthMap["Selling Price(Excl GST)"],
                }}
                onClick={() => setOpenCell4(true)}
              >
                {openCell4 &&
                user &&
                (user.role === "superuser" || user.role === "pricing") ? (
                  <TextField
                    value={spegst}
                    variant="filled"
                    style={{
                      minWidth: "80px",
                      width: "fit-content",
                      height: "50px",
                    }}
                    inputProps={{
                      style: { background: "white", fontSize: "0.875rem" },
                    }}
                    onChange={(e) => {
                      const newValue = e.target.value.replace(/[^0-9.]/g, "");
                      setSpegst(newValue);
                    }}
                    onBlur={() => {
                      handleValueEdited("spegst", spegst);
                      // setOpenCell4(false);
                    }}
                    onFocus={() => {
                      setOpenCell4(true);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === "Tab") {
                        handleValueEdited("spegst", spegst);
                        setOpenCell4(false); // Close the cell when Enter key is pressed
                      }
                    }}

                    // fullWidth
                  />
                ) : (
                  // parseFloat(spegst).toFixed(2)

                  `${parseFloat(spegst).toFixed(2)}`
                )}
              </TableCell>
              <TableCell
                align="center"
                style={{
                  fontWeight: 500,
                }}
                sx={{
                  width: widthMap["Selling Price(Incl GST)"],
                  minWidth: widthMap["Selling Price(Incl GST)"],
                }}
              >
                {parseFloat(spigst).toFixed(2)}
              </TableCell>
              <TableCell
                align="center"
                style={{ fontWeight: 500, color: "#0081df", cursor: "pointer" }}
                onClick={() => setOpenCell3(true)}
                sx={{
                  width: widthMap["Quantity"],
                  minWidth: widthMap["Quantity"],
                }}
              >
                {openCell3 ? (
                  <TextField
                    variant="filled"
                    value={quantity}
                    style={{ width: "fit-content", height: "50px" }}
                    inputProps={{ style: { background: "white" } }}
                    onChange={(e) => setQuantity(e.target.value)}
                    onBlur={() => {
                      handleValueEdited("quantity", quantity);
                    }}
                    onKeyDown={(e) =>
                      e.key === "Enter"
                        ? handleValueEdited("quantity", quantity)
                        : null
                    }
                  />
                ) : (
                  row.quantity
                )}
              </TableCell>
              <TableCell
                align="center"
                sx={{ width: widthMap["Amount"], minWidth: widthMap["Amount"] }}
              >
                {parseFloat(amount).toFixed(2)}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  width: widthMap["Delete from Quote"],
                  minWidth: widthMap["Delete from Quote"],
                }}
              >
                <IconButton
                  aria-label="delete"
                  size="large"
                  onClick={handleProductDelete}
                >
                  <DeleteIcon style={{ color: "#960000" }} />
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                colSpan={11}
                style={{
                  paddingBottom: 0,
                  paddingTop: 0,
                  border: "1px solid rgba(224, 224, 224, 1)",
                }}
              >
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box
                    sx={{
                      margin: 1,
                      height: "fit-content",
                      display: "flex",
                      gap: "30px",
                      width: "100vw",
                      padding: "10px 0px 10px 20px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "150px",
                        alignItems: "flex-start",
                        "& > *:not(:last-child)": {
                          marginBottom: "15px",
                        },

                        width: "220px",
                      }}
                    >
                      <Box>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            color: "#3279b3",
                            fontFamily: "Inter-Bold, Helvetica",
                            fontSize: "16px",
                            fontWeight: 700,
                          }}
                        >
                          Brand
                        </Typography>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            color: "#333333",
                            fontFamily: "Inter-Bold, Helvetica",
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          {row.brand}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            color: "#3279b3",
                            fontFamily: "Inter-Bold, Helvetica",
                            fontSize: "16px",
                            fontWeight: 700,
                          }}
                        >
                          Size/Specification
                        </Typography>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            color: "#333333",
                            fontFamily: "Inter-Bold, Helvetica",
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          {row.size}
                        </Typography>
                        {/* <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            color: "#333333",
                            fontFamily: "Inter-Bold, Helvetica",
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          {row.Medical_Field_2}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            color: "#333333",
                            fontFamily: "Inter-Bold, Helvetica",
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          {row.Medical_Field_3}
                        </Typography> */}
                      </Box>
                      <Box>
                        {/* <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            color: "#3279b3",
                            fontFamily: "Inter-Bold, Helvetica",
                            fontSize: "16px",
                            fontWeight: 700,
                          }}
                        >
                          Value 3
                        </Typography>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            color: "#333333",
                            fontFamily: "Inter-Bold, Helvetica",
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        ></Typography> */}
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "150px",
                        alignItems: "flex-start",
                        "& > *:not(:last-child)": {
                          marginBottom: "15px",
                        },
                        width: "210px",
                      }}
                    >
                      <Box>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            color: "#3279b3",
                            fontFamily: "Inter-Bold, Helvetica",
                            fontSize: "16px",
                            fontWeight: 700,
                          }}
                        >
                          Product Categories
                        </Typography>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            color: "#333333",
                            fontFamily: "Inter-Bold, Helvetica",
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          {row.prodcat}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            color: "#3279b3",
                            fontFamily: "Inter-Bold, Helvetica",
                            fontSize: "16px",
                            fontWeight: 700,
                          }}
                        >
                          Medical Fields
                        </Typography>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            color: "#333333",
                            fontFamily: "Inter-Bold, Helvetica",
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          {row.category}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            color: "#333333",
                            fontFamily: "Inter-Bold, Helvetica",
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          {row.Medical_Field_2}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            color: "#333333",
                            fontFamily: "Inter-Bold, Helvetica",
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          {row.Medical_Field_3}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            color: "#3279b3",
                            fontFamily: "Inter-Bold, Helvetica",
                            fontSize: "16px",
                            fontWeight: 700,
                          }}
                        >
                          HSN Code
                        </Typography>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            color: "#333333",
                            fontFamily: "Inter-Bold, Helvetica",
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          {row.HSN}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        width: "240px",
                        margin: "0",
                        display: "flex",
                        flexDirection: "column",
                        gap: "13px",
                      }}
                    >
                      <Slider {...settings}>
                        {Array.isArray(imagerow) &&
                          imagerow.map((url, index) => (
                            <div key={index}>
                              {user && user.role !== "sales" && (
                                <button
                                  className="del-file-btn"
                                  onClick={() =>
                                    handleShowDeleteImagePopUp(index)
                                  }
                                  style={{
                                    minWidth: "20px",
                                    background: "#A80000",
                                    boxShadow: "none",
                                    marginLeft: "75%",
                                    marginBottom: "3px",
                                  }}
                                >
                                  Delete
                                </button>
                              )}
                              <img
                                src={url}
                                alt={`Slide ${index + 1}`}
                                style={{
                                  width: "100%",
                                  height: "200px",
                                  position: "relative",
                                }}
                              />
                            </div>
                          ))}
                      </Slider>
                      {user && user.role !== "sales" && (
                        <div>
                          <input
                            type="file"
                            // value={attachedImage}
                            className="choose-file"
                            id="choose-image-attachment"
                            accept="image/jpeg, image/png, image/jpg"
                            style={{ marginTop: "8px" }}
                            required
                            onChange={(e) => {
                              const file = e?.target?.files[0];
                              if (file.size <= 2 * 1024 * 1024) {
                                setAttachedImage(file);
                              } else {
                                alert("File size exceeds 2 MB limit.");
                                e.target.value = null;
                              }
                            }}
                            onClick={(event) => {
                              event.target.value = null;
                            }}
                          />

                          {uploadImageLoader ? (
                            <CircularProgress />
                          ) : (
                            <button
                              className="add-file-btn"
                              onClick={(e) => handleUploadPic()}
                              style={{ width: "40%", background: "#489A34" }}
                            >
                              Upload
                            </button>
                          )}
                        </div>
                      )}
                    </Box>

                    <Box
                      sx={{
                        height: "fit-content",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        marginLeft: "15px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#3279b3",
                          fontFamily: "Inter-Bold, Helvetica",
                          fontSize: "16px",
                          fontWeight: 700,
                          height: "fit-content",
                        }}
                        component="div"
                      >
                        Description
                      </Typography>
                      <TextField
                        variant="outlined"
                        multiline
                        rows={5}
                        fullWidth
                        value={description}
                        inputProps={{ style: { fontSize: "12px" } }}
                        // InputProps={{ disableUnderline: true }}
                        onChange={(e) => setDescription(e.target.value)}
                        InputProps={{
                          readOnly: !isEditDescription,
                        }}
                        sx={{
                          height: "fit-content",
                          overflowY: "scroll",
                          border: "1px solid #ccc",
                          padding: "8px",
                          width: "500px",
                          backgroundColor: "#f6f6f6",
                          borderRadius: "3px",
                        }}
                      />
                      {user && user.role !== "sales" && (
                        <div>
                          {isEditDescription ? (
                            <Button
                              variant="contained"
                              onClick={() => {
                                handleValueEdited("description", description);
                                setIsEditeDescription(false);
                              }}
                              sx={{
                                backgroundColor: "#489a33",
                                width: "10%",
                              }}
                              size="medium"
                            >
                              Save
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              sx={{
                                width: "10%",
                              }}
                              size="medium"
                              onClick={() => setIsEditeDescription(true)}
                            >
                              Edit
                            </Button>
                          )}
                        </div>
                      )}
                      {user &&
                        (user.role === "superuser" ||
                          user.email === "umang.ranjan@zoplar.com" ||
                          user.role === "category") && (
                          <div
                            style={{
                              marginTop: "20px",
                            }}
                          >
                            {savingSku ? (
                              <h3>Saving...</h3>
                            ) : (
                              <button
                                style={{
                                  backgroundColor: "#489A34",
                                  border: "0px",
                                  padding: "10px",
                                  borderRadius: "3px",
                                  cursor: "pointer",
                                  fontFamily: "Inter",
                                  fontSize: "14px",
                                  fontStyle: "normal",
                                  color: "white",
                                }}
                                onClick={handleSaveSkuPopupOpen}
                              >
                                Save SKU
                              </button>
                            )}
                          </div>
                        )}
                    </Box>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
            <Dialog
              open={openvendorPopUp}
              onClose={() => setOpenVendorPopUp(false)}
              sx={{
                position: "absolute",
                left: "31%",
                top: "20%",
                width: "fit-content",
                maxWidth: "2000px !important",

                padding: "0px ",
                height: "fit-content",
              }}
            >
              <DialogTitle
                sx={{
                  width: "25vw",
                  height: "fit-content",
                  padding: "15px 23px 5px 23px !important",
                }}
              >
                Select Vendor for SKU {`${row.sku}`}
              </DialogTitle>
              <DialogContent
                sx={{
                  width: "32vw",
                  height: "68vh",
                  paddingTop: "5px !important",
                  paddingBottom: "0px",
                  position: "relative",
                }}
              >
                <Select
                  onChange={(newValue) => {
                    changeVendor(newValue);
                    setOpenVendorPopUp(false);
                  }}
                  options={vendorsList}
                  placeholder="Select Partner"
                  styles={vendorStyles}
                />
                {user && user.role === "category" && (
                  <button
                    style={{
                      position: "absolute",
                      bottom: "2px",
                      right: "5px",
                      background: "#1976d2",
                      border: "none",
                      color: "white",
                      borderRadius: "3px",
                      fontFamily: "Inter",
                      padding: "8px 6px",
                      cursor: "pointer",
                    }}
                    onClick={handleNavigateToVendors}
                  >
                    Create Vendor
                  </button>
                )}
              </DialogContent>
            </Dialog>
            <Dialog
              open={openWarrantyPopUp}
              onClose={() => setOpenWarrantyPopUp(false)}
              sx={{
                position: "absolute",
                left: "35%",
                top: "23%",
                width: "fit-content",
                maxWidth: "2000px !important",
                height: "fit-content",
              }}
            >
              <DialogTitle
                sx={{
                  width: "25vw",
                  height: "fit-content",
                  padding: "15px 23px 5px 23px !important",
                }}
              >
                Select Warranty for SKU {`${row.sku}`}
              </DialogTitle>
              <DialogContent
                sx={{
                  width: "25vw",
                  height: "50vh",
                  paddingTop: "5px !important",
                }}
              >
                <Select
                  // value={vendorsList}
                  onChange={(newValue) => {
                    changeWarranty(newValue);
                    setWarrantyDuration(newValue);
                    setOpenWarrantyPopUp(false);
                  }}
                  options={warrantyDurationOptions}
                  placeholder="Select Warranty"
                  styles={warrantyStyles}
                  value={warranty_duration}
                />
              </DialogContent>
            </Dialog>
            <div>
              {showDeleteImagePopUp && (
                <ConfirmationPopUp
                  show={showDeleteImagePopUp}
                  onClose={handleHideDeleteImagePopUp}
                  onConfirm={() => handleDelPic(deletImageIndex)}
                  close={handleHideDeleteImagePopUp}
                  confirm={() => handleDelPic(deletImageIndex)}
                  title={"Do you like to delete the image from product?"}
                  message={
                    "The image will be deleted and you have to upload it again."
                  }
                />
              )}
            </div>
            <div>
              {showSaveSkuPopUp && (
                <ConfirmationPopUp
                  show={showSaveSkuPopUp}
                  onClose={handleSaveSkuPopupClose}
                  onConfirm={handleSaveSku}
                  close={handleSaveSkuPopupClose}
                  confirm={handleSaveSku}
                  title={`Do you like to save ${row.sku}?`}
                  message={
                    "This will change the details in the SKU for future quotations as well"
                  }
                />
              )}
            </div>
          </div>
        )}
      </Draggable>
      <ToastContainer autoClose={1000} />
    </React.Fragment>
  );
};

export default RowProduct;
