import React from "react";
import "./NoAccess.css";
import { ReactComponent as Error } from "./noskufound.svg";

const NoAccess = () => {
  return (
    <div className="noaccess">
      <Error />
      <h3>You are not authorized to access this page</h3>
    </div>
  );
};

export default NoAccess;
