import React from "react";
import "./NoAccess.css";
import { ReactComponent as Error } from "./noskufound.svg";

const NotReg = () => {
  return (
    <div className="noaccess">
      <Error />
      {/* <h1>Sorry!</h1> */}
      <h3>
        You are not a registered user
      </h3>
      <h4>Please contact Admin for access</h4>
    </div>
  );
};

export default NotReg;
