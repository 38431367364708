import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmationPopUp = ({ show, close, confirm, title, message }) => {
  return (
    <Dialog
      open={show}
      TransitionComponent={Transition}
      keepMounted
      disableBackdropClick
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          close();
        }
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>No</Button>
        <Button onClick={confirm}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationPopUp;
