import { createContext, useState, useEffect } from "react";
import axios from "axios";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import LoginAdmin from "./components/loginAdmin";
import AdminProductList from "./adminproductlist";
import SKU from "./components/Sku";
import Opendetail from "./components/Opendetail";
import BlogCreate from "./components/BlogCreate";
import NoAccess from "./components/NoAccess";
import NotReg from "./components/NotReg";
import UserAccess from "./components/Useraccess";
import NotFound from "./components/Notfound";
import LsqQuery from "./quoteGeneration/LsqQuery";
import QuotationCreation from "./quoteGeneration/quotationCreation";
import ManualQuotation from "./quoteGeneration/manualQuotation";
import DetailedPriceList from "./quoteGeneration/detailedPriceList";

import Vendors from "./quoteGeneration/vendors";
// import "./App.css";

export const UserContext = createContext();

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setIsLoading] = useState(false);
  // const [renderCount, setRenderCount] = useState(true);

  const location = useLocation();

  const retrieveUser = async () => {
    try {
      setIsLoading(true);
      // Make a request to your backend to retrieve user data
      const response = await axios.get("/user"); // Replace with your actual backend route
      if (response.data && response.data.user) {
        setUser(response.data.user);
      } else {
        setUser(null);
      } // Set user data in state
    } catch (error) {
      // Handle error (e.g., user not authenticated)
      setUser(null);
    } finally {
      setIsLoading(false); // Set loading state to false once data is fetched
    }
  };

  useEffect(() => {
    retrieveUser();
  }, []);

  const ProtectedRoute = ({ component: Component, requiredRoles, ...rest }) => {
    // Check if the user data is still being fetched

    if (loading) {
      return <p>Loading.....</p>;
    }

    if (!user) {
      return <NoAccess />;
    }

    // Check if user is not available or doesn't have the required role
    if (!requiredRoles.includes(user.role)) {
      return <Navigate to="/unauthorized" />;
    } else {
      return <Component {...rest} />;
    }
  };

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <div className="App">
        <Header />
        {location.pathname !== "/" &&
          location.pathname !== "/notregistered" && <Sidebar />}
        <Routes>
          {/* <Route
            path="/"
            element={
              user &&
              (user.role === "Product_Creator" ||
                user.role === "pricing" ||
                user.role === "superuser") ? (
                <Navigate to="/quotegen" replace />
              ) : user && user.role === "category" ? (
                <Navigate to="/quotegen" replace />
              ) : user && user.role === "sales" ? (
                <Navigate to="/quotegen" replace />
              ) : (
                <LoginAdmin />
              )
            }
          /> */}
          <Route
            path="/"
            element={
              loading ? (
                <p>Loading.....</p>
              ) : user ? (
                <Navigate to="/queryDetailsLsq" replace />
              ) : (
                <LoginAdmin />
              )
            }
          />

          <Route
            path="/adminproduct/*"
            element={
              <ProtectedRoute
                component={AdminProductList}
                requiredRoles={["Product_Creator", "superuser", "category"]}
              />
            }
          />
          <Route
            path="/adminsku/*"
            element={
              <ProtectedRoute
                component={SKU}
                requiredRoles={["Product_Creator", "superuser", "category"]}
              />
            }
          />
          <Route
            path="/adminopendetail/*"
            element={
              <ProtectedRoute
                component={Opendetail}
                requiredRoles={["Product_Creator", "superuser", "category"]}
              />
            }
          />
          <Route
            path="/userdetails/*"
            element={
              <ProtectedRoute
                component={UserAccess}
                requiredRoles={["superuser"]}
              />
            }
          />
          <Route
            path="/blogcreate/*"
            element={
              <ProtectedRoute
                component={BlogCreate}
                requiredRoles={["Blog_Editor", "superuser"]}
              />
            }
          />
          <Route
            path="/queryDetailsLsq/*"
            element={
              <ProtectedRoute
                component={LsqQuery}
                requiredRoles={["superuser", "pricing", "category", "sales"]}
              />
            }
          />
          <Route
            path="/quotationCreation/:queryId"
            element={
              <ProtectedRoute
                component={QuotationCreation}
                requiredRoles={["superuser", "pricing", "category", "sales"]}
              />
            }
          />
          <Route
            path="/quotationCreation/:queryId/:quotationId"
            element={
              <ProtectedRoute
                component={QuotationCreation}
                requiredRoles={["superuser", "pricing", "category", "sales"]}
              />
            }
          />
          <Route
            path="/vendorsDetails"
            element={
              <ProtectedRoute
                component={Vendors}
                requiredRoles={["superuser", "pricing", "category", "sales"]}
              />
            }
          />
          <Route
            path="/manualQuotation"
            element={
              <ProtectedRoute
                component={ManualQuotation}
                requiredRoles={["superuser", "pricing", "category", "sales"]}
              />
            }
          />
          <Route
            path="/priceList"
            element={
              <ProtectedRoute
                component={DetailedPriceList}
                requiredRoles={["superuser", "category"]}
              />
            }
          />
          {/* <Route
            path="/quotationCreation/:queryId"
            element={<QuotationCreation />}
          /> */}
          {/* <Route
            path="/quotationCreation/:queryId/:quotationId"
            element={<QuotationCreation />}
          /> */}

          {/* <Route path="/vendors" element={<Vendors />}></Route> */}
          {/* <Route path="/manualQuotation" element={<ManualQuotation />} /> */}

          <Route path="/unauthorized" element={<NoAccess />} />

          <Route path="/notregistered" element={<NotReg />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </div>
    </UserContext.Provider>
  );
};

export default App;
