import Button from "react-bootstrap/esm/Button";
import "./useraccess.css";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dialog from "@mui/material/Dialog";
import { DialogContent } from "@mui/material";

const UserAccess = () => {
  const [user, setUser] = useState([]);
  const [showtoggle, setshowtoggle] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    role: "", // Default selected gender
  });
  const [userToDelete, setUserToDelete] = useState(null);

  const opendeletetoggle = (email) => {
    setUserToDelete(email);
    setshowtoggle(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleEditClick = (email) => {
    // Open the role dropdown when "Edit" is clicked
    setOpenDialog(true);
    setSelectedUser(email);
  };

  const getUser = async () => {
    try {
      const response = await fetch("/userdetail", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch brands");
      }
      const result = await response.json();
      setUser(result);
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  };

  const createUser = async (e) => {
    e.preventDefault();
    try {
      const { email, role } = formData;
      if (!email || !role) {
        toast.error("Fill all the Data", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (!email.includes("@zoplar.com")) {
        toast.error("Only Zoplar Employees can be Registered", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        const response = await fetch("/user", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, role }),
        });
        const result = await response.json();
        // console.log(result);
        if (result.exist) {
          toast.error("User Already Added!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.success("User Added!", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const handleRoleSelect = async (selectedRole, email) => {
    try {
      const response = await fetch(`/editrole/${email}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ role: selectedRole }),
      });

      if (!response.ok) {
        // console.log("Failed to update user role");
      }

      const result = await response.json();
      // console.log(result);
      if (result.error) {
        toast.error(result.error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.success(result.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      setSelectedUser(null);
      setOpenDialog(false);

      getUser();
    } catch (error) {
      console.error("Error updating user role:", error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleDeleteForRow = async (email) => {
    // console.log(email)
    try {
      const response = await fetch(`/userdelete/${email}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        // console.log("Failed to delete user");
      }

      const result = await response.json();
      // console.log(result);
      if (result.error) {
        toast.error(result.error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.success(result.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      setshowtoggle(false);
      setUserToDelete(null);
      // Refresh the user data after deletion
      getUser();
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("Internal Server Error", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div className="adminuser">
      <h1 id="userheader">User Access</h1>
      <div className="access-container">
        <h2 id="userheader2">Add User </h2>
        <form className="userform" onSubmit={handleSubmit}>
          <input
            className="userinput"
            placeholder="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />

          <select
            style={{ color: "#c9c9c9" }}
            type="text"
            id="userrole"
            name="role"
            value={formData.role}
            onChange={handleChange}
          >
            <option value="" disabled selected>
              Role
            </option>
            <option value="Blog_Editor">Blog Editor</option>
            <option value="pricing">Pricing</option>
            <option value="category">Category</option>
            <option value="sales">Sales</option>
            {/* <option value="Product Cataloguing">ProductCataloguing</option> */}
          </select>
          <Button onClick={createUser} className="useraddbutton">
            Add
          </Button>
        </form>
        <div className="usertable">
          <table
            style={
              {
                width: "100%",
                border: "1px solid #c9c9c9",
              }
            }
          >
            <thead>
              <tr
                style={
                  {
                    fontWeight: "bold",
                  }
                }
              >
                <td>Name</td>
                <td>Email</td>
                <td>Role</td>
                <td>Edit Role</td>
                <td>Delete</td>
              </tr>
            </thead>
            <tbody>
              {user.map((user) => (
                <tr key={user.email}>
                  <td>{user.username || user.Name}</td>
                  <td>{user.email}</td>
                  <td>{user.role}</td>
                  <td>
                    <button onClick={() => handleEditClick(user.email)}
                      style={
                        {
                          backgroundColor: "#399FF5",
                          fontFamily: "Inter",
                          border: "1px solid #399FF5",
                          width: "50px",
                          borderRadius: "3px",
                          color: "white",
                        }
                      }
                    >
                      Edit
                    </button>
                  </td>
                  <td>
                    <button onClick={() => opendeletetoggle(user.email)}
                      style={
                        {
                          backgroundColor: "#FE6464",
                          fontFamily: "Inter",
                          border: "1px solid #FE6464",
                          width: "60px",
                          borderRadius: "3px",
                          color: "white",
                        }
                      }
                    >
                      Delete
                    </button>
                  </td>

                  {showtoggle && userToDelete === user.email && (
                    <div className="admindeletepopup">
                      <p>
                        Are you sure you want to <br></br> <span> Delete </span>{" "}
                        the user?
                      </p>
                      <div className="admindeletepopupbuttons">
                        <button
                          className="no"
                          onClick={() => setshowtoggle(false)}
                        >
                          No
                        </button>
                        <button
                          className="yes"
                          onClick={() => handleDeleteForRow(user.email)}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  )}

                  <Dialog
                    open={openDialog}
                    onClose={() => setOpenDialog(false)}
                    sx={{
                      cursor: "pointer",
                  }}
                  BackdropProps={{
                    style: {
                        backgroundColor: "rgba(0, 0, 0, 0)",
                        border: "none",
                    }
                }}
                  >
                    <DialogContent
                      sx={{
                        padding: " 0px",
                      }}
                    >
                      <div className="roledropdown">
                        <p
                          onClick={() =>
                            handleRoleSelect("pricing", selectedUser)
                          }
                        >
                          Pricing
                        </p>
                        <p
                          onClick={() =>
                            handleRoleSelect("category", selectedUser)
                          }
                        >
                          Category
                        </p>
                        <p
                          onClick={() =>
                            handleRoleSelect("sales", selectedUser)
                          }
                        >
                          Sales
                        </p>
                        <p
                          onClick={() =>
                            handleRoleSelect("Blog_Editor", selectedUser)
                          }
                        >
                          Blog Editor
                        </p>
                      </div>
                    </DialogContent>
                  </Dialog>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div></div>
      </div>

      <ToastContainer autoClose={1000} />
    </div>
  );
};

export default UserAccess;
