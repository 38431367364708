import React, { useState } from "react";
import "./Imageuploadpopup.css";
import { TailSpin } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BlogUploadPopup = ({ onClose, titleBlog, onUpdateResult }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageSelection = () => {
    if (selectedFile) {
      onUpdateResult(titleBlog, selectedFile);
      onClose(); // Close the popup after selecting the image
    } else {
      toast.error("Please select an image.");
    }
  };

  return (
    <div className="blog-imageuploadpopup">
      <h5>Select Picture</h5>
      <div className="adminuploadcontent">
        <div className="imagelarge">
          <img className="adminimageuploadpic" src={imagePreview} />
          <label className="uploadlabel" htmlFor={`fileInput-${titleBlog}`}>
            Select File
          </label>
          <input
            type="file"
            id={`fileInput-${titleBlog}`}
            className="file-input"
            accept="image/*"
            onChange={(e) => handleFileChange(e)}
          />
          <button
            className="upload-button"
            onClick={() => handleImageSelection()}
          >
            Select Image
          </button>
        </div>
      </div>
      <div className="adminpopupfooter">
        <button className="adminbuttonsubmit" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default BlogUploadPopup;
