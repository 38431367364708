import React, { useEffect, useState, useContext, useRef } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableSortLabel,
  AppBar,
} from "@mui/material";
import PDFView from "./PDFView.js";

import Toolbar from "@mui/material/Toolbar";

import Typography from "@mui/material/Typography";

import CloseIcon from "@mui/icons-material/Close";

import Slide from "@mui/material/Slide";
import { PDFViewer } from "@react-pdf/renderer";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Pagination from "@mui/material/Pagination";
import { ReactComponent as Cross } from "../icons/cross.svg";
import Stack from "@mui/material/Stack";
import { ReactComponent as Filter } from "../icons/filter.svg";
import { Helmet } from "react-helmet";

import "./QueryDetails.css";
import "./LsqQuery.css";

import {
  Button,
  CircularProgress,
  Container,
  Grid,
  TextField,
} from "@mui/material";
import { Menu, MenuButton, MenuItem, Dropdown } from "@mui/joy";

import { Link, useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { UserContext } from "../App";

import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { ReactComponent as Search } from "../icons/search.svg";

const LsqQuery = () => {
  const [loading, setIsloading] = useState(false);
  const [queryData, setQueryData] = useState([]);
  const [page, setPage] = useState(1);
  const [queryStatus, setQueryStatus] = useState("");
  const [fromManual, setFromManual] = useState(false);
  const [fromGeneric, setFromGeneric] = useState(false);
  const [createdBy, setCreatedBy] = useState("");
  const [open, setOpen] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [selectedQuery, setSelectedQuery] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [prevQuotationData, setPrevQuotationData] = useState([]);
  const [openPrevQuotationPopUp, setOpenPrevQuotationPopUp] = useState(false);
  const [popUpLoading, setPopUpLoading] = useState(false);
  const [queryPerPage, setQueryPerPage] = useState(1);
  const [totalQuery, setTotalQuery] = useState(1);
  const [source, setSource] = useState("");
  const { user } = useContext(UserContext);
  const [openremarkPopUp, setOpenRemarkPopUp] = useState(false);
  const [searchText, setSearchText] = useState("");
  const menuRef = useRef(null);
  const [openRow, setOpenRow] = useState(null);

  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRowClick = (rowId) => {
    setOpenRow((prevRowId) => (prevRowId === rowId ? null : rowId));
  };

  const divStyle = {
    display: "flex",
    position: "absolute",
    top: "16%",
    width: "93%",
    // left: "15%",
    marginLeft: "7%",
    textAlign: "right",
    flexDirection: "column",
    paddingBottom: "5%",
  };

  const [selectedDate, setSelectedDate] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
  };

  const handleFilter = () => {
    setMenuAnchorEl(null);
    setSelectedDate(dateRange);
    setPage(1);
    // getQueries();
  };

  const handleSearchInputChange = (event) => {
    setSearchText(event.target.value); // Update state with input value
  };

  const getQueries = async (e) => {
    try {
      setIsloading(true);

      let apiUrl = `/quotegen?page=${page}&status=${queryStatus}&fromManualQuotation=${fromManual}&fromGenericQuotation=${fromGeneric}&source=${source}&createdBy=${createdBy}&search=${searchText}`;
      if (selectedDate[0].startDate && selectedDate[0].endDate) {
        apiUrl += `&startingDate=${selectedDate[0].startDate}&endingDate=${selectedDate[0].endDate}`;
      }

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { data, totalQuery, queryPerPage } = await response.json();
      setQueryPerPage(queryPerPage);
      setTotalQuery(totalQuery);
      setQueryData(data);

      setMenuAnchorEl(null);
    } catch (error) {
      console.log("Try Again");
    } finally {
      setIsloading(false);
    }
  };

  const handlePopupSelection = (selectedOption) => {
    setOpenPopUp(false);

    if (selectedOption === "pendingOnPricing") {
      updateQueries(selectedQuery, selectedOption);
    }

    if (selectedOption === "pendingOnCategory") {
      handleOpenRemarkPopUp();
    }
  };

  const handleOpenRemarkPopUp = () => {
    setOpenRemarkPopUp(true);
  };

  const sendToCategoryWithRemarks = async (remarks) => {
    const response = await fetch(
      `/quotegen/quotationCreation/${selectedQuery}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          updatedStatus: "pendingOnCategory",
          remarksForCategory: remarks,
        }),
      }
    );
    const { success } = await response.json();

    if (success) {
      getQueries();
      setOpenRemarkPopUp(false);
    }
  };

  const updateQueries = async (queryId, updatedStatus) => {
    const response = await fetch(`/quotegen/quotationCreation/${queryId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ updatedStatus }),
    });
    const { success } = await response.json();

    if (success) {
      getQueries();
    }
  };

  const showPreviousQuotationData = async (queryId) => {
    setOpenPrevQuotationPopUp(true);
    setPopUpLoading(true);
    const response = await fetch(`/quotegen/getPreviousQuotation/${queryId}`, {
      method: "GET",
    });
    const { success, data: previousQuotationData } = await response.json();

    if (success) {
      setPrevQuotationData(previousQuotationData);
      setPopUpLoading(false);
    }
  };

  useEffect(() => {
    getQueries();
    localStorage.removeItem("selectedItems");
    window.scrollTo(0, 0);
  }, [
    queryStatus,
    page,
    fromManual,
    source,
    fromGeneric,
    createdBy,
    selectedDate,
  ]);

  const tableHeader = [
    "Query ID",
    "Raised date",
    "Raised by",
    "Product",
    "Name of Establishment",
    "Name of POC",
    "Urgent Query?",
    "City",
    "Warmth",
    "Last Modified",
    "Status",
  ];

  const tableHeaderkeys = {
    "Query ID": "queryId",
    "Raised date": "createdAt",
    "Raised by": "email",
    Product: "productName",
    "Name of Establishment": "establishmentname",
    "Name of POC": "pocName",
    "Urgent Query?": "urgentQuery",
    City: "city",
    Warmth: "warmth",
    "Last Modified": "updatedAt",
    Status: "status",
  };

  const tableHeaderCategory = [
    "Query ID",
    "Raised date",
    "Raised by",
    "Product",
    "Name of Establishment",
    "Name of POC",
    "Urgent Query?",
    "City",
    "Warmth",
    "Last Modified",
    "Status",
  ];

  const handleCreateQuotation = () => {
    navigate("/manualQuotation");
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSort = (column) => {
    const isAsc = sortBy === column && sortOrder === "asc";
    setSortBy(column);
    setSortOrder(isAsc ? "desc" : "asc");
  };

  const sortedData =
    queryData &&
    queryData.sort((a, b) => {
      const valueA = a[sortBy] || "";
      const valueB = b[sortBy] || "";
      if (sortOrder === "asc") {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });

  return (
    <div style={divStyle}>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div style={{ position: "absolute", left: "-2.5%", width: "90vw" }}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Admin Panel - Zoplar</title>
          </Helmet>
          {/* <Typography
            variant="h4"
            width="100%"
            align="center"
            paddingBottom="1.9%"
            paddingTop="2%"
            fontFamily="'Inter', 'Helvetica', sans-serif"
            fontSize="32px"
            fontWeight={600}
          >
            Quotation Request
          </Typography> */}
          <Container
            sx={{
              marginBottom: "1%",
              maxWidth: "100% !important",
              padding: "0px 0px 0px 0px !important",
            }}
          >
            <Grid
              container
              spacing={5}
              sx={{
                display: "flex",
                width: "100%",
                gap: "10px",
                marginLeft: "0%",
              }}
            >
              {/* <Grid item sx={{ paddingLeft: "0px !important" }}>
                <Dropdown>
                  <MenuButton
                    onClick={handleOpen}
                    size="lg"
                    endDecorator={
                      // <FilterAltOutlinedIcon sx={{ color: "white" }} />
                      <Filter />
                    }
                    sx={{
                      color: "black !important",
                      backgroundColor: "white",
                      borderTopLeftRadius: "7px",
                      borderBottomLeftRadius: "7px",
                      borderTopRightRadius: "0px",
                      borderBottomRightRadius: "0px",
                      // boxShadow: "0px 4px 4px #00000040",
                      width: "120px",
                      fontWeight: 500,
                      fontSize: "16px",
                      // "&:hover": {
                      //   backgroundColor: "#399FF5!important",
                      //   color:'white !important'
                      // },
                      alignContent: "initial",
                      borderRight: "none",
                    }}
                  >
                    Date
                  </MenuButton>

                  <Menu
                    sx={{
                      padding: "0px",
                      border: "0px",
                    }}
                  >
                    <MenuItem
                      sx={{
                        borderTopLeftRadius: "7px",
                        borderBottomLeftRadius: "7px",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["DateCalendar", "DateCalendar"]}
                        >
                          <DemoItem>
                            <DateCalendar
                              value={datevalue}
                              onChange={(newDate) => setDateValue(newDate)}
                            />
                          </DemoItem>
                        </DemoContainer>
                      </LocalizationProvider>
                    </MenuItem>
                  </Menu>
                </Dropdown>
              
              </Grid> */}
              {/* <Grid item sx={{ paddingLeft: "0px !important" }}>
                <Menu
                  anchorEl={menuAnchorEl}
                  open={Boolean(menuAnchorEl)}
                  onClose={handleMenuClose}
                  sx={{
                    padding: "0px",
                    border: "0px",
                  }}
                >
                  <MenuItem
                    sx={{
                      borderTopLeftRadius: "7px",
                      borderBottomLeftRadius: "7px",
                      borderTopRightRadius: "0px",
                      borderBottomRightRadius: "0px",
                    }}
                    onClick={handleMenuClose}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateCalendar
                        value={selectedDate ? selectedDate : datevalue}
                        onChange={handleDateChange}
                      />
                    </LocalizationProvider>
                  </MenuItem>
                </Menu>

                <Button
                  onClick={handleMenuOpen}
                  sx={{
                    color: "black !important",
                    backgroundColor: "white",
                    borderTopLeftRadius: "7px",
                    borderBottomLeftRadius: "7px",
                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "0px",
                    width: "120px",
                    fontWeight: 500,
                    fontSize: "16px",
                    alignContent: "initial",
                    border: "1px solid rgb(203, 203, 203)",
                    borderRight: "none",
                    minHeight: "2.75rem",
                  }}
                  endIcon={<Filter />}
                >
                  Date
                </Button>
              </Grid> */}
              <div
                style={{ display: "flex", gap: "0px", alignItems: "flex-end" }}
              >
                <Grid item sx={{ paddingLeft: "0px !important" }}>
                  {/* <MenuButton
                    onClick={(e) => setMenuAnchorEl(e.currentTarget)}
                    size="lg"
                    ref={menuRef}
                  > */}
                  <Menu
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    // onClick={handleMenuClose}
                    onClose={handleMenuClose}
                    sx={{
                      padding: "0px",
                      border: "0px",
                    }}
                  >
                    <MenuItem
                      sx={{
                        borderTopLeftRadius: "7px",
                        borderBottomLeftRadius: "7px",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                      }}
                    >
                      <DateRangePicker
                        ranges={dateRange}
                        onChange={handleSelect}
                        showDateDisplay={false}
                        moveRangeOnFirstSelection={false}
                      />
                      <button
                        onClick={handleFilter}
                        style={{
                          backgroundColor: "#399FF5",
                          border: "1px solid #399FF5",
                          padding: "5px 10px",
                          borderRadius: "5px",
                          color: "white",
                          cursor: "pointer",
                          fontFamily: "Inter",
                        }}
                      >
                        Filter
                      </button>
                    </MenuItem>
                  </Menu>

                  <Button
                    onClick={(e) =>
                      menuAnchorEl
                        ? setMenuAnchorEl(null)
                        : setMenuAnchorEl(e.currentTarget)
                    }
                    sx={{
                      color: "black !important",
                      backgroundColor: "white",
                      borderTopLeftRadius: "7px",
                      borderBottomLeftRadius: "7px",
                      borderTopRightRadius: "0px",
                      borderBottomRightRadius: "0px",
                      width: "fit-content",
                      fontWeight: 500,
                      fontSize: "16px",
                      alignContent: "initial",
                      border: "1px solid rgb(203, 203, 203)",
                      borderRight: "none",
                      minHeight: "2.75rem",
                      padding: "0px 12px !important",
                      textTransform: "none",
                      fontFamily: "Inter",
                    }}
                    endIcon={
                      selectedDate[0].startDate && selectedDate[0].endDate ? (
                        <Cross
                          onClick={() => {
                            setSelectedDate([
                              {
                                startDate: null,
                                endDate: null,
                                key: "selection",
                              },
                            ]);
                            setPage(1);
                            setMenuAnchorEl(null);
                          }}
                        />
                      ) : (
                        <Filter />
                      )
                    }
                    // endIcon={
                    //   (selectedDate.startDate === null  && selectedDate.endDate === null) ?
                    //     <Filter />
                    //    :
                    //     <Cross
                    //       onClick={() => {
                    //         setSelectedDate([
                    //           {
                    //             startDate: null,
                    //             endDate: null,
                    //             key: "selection",
                    //           },
                    //         ]);
                    //         setPage(1);
                    //       }}
                    //     />

                    // }
                  >
                    {selectedDate[0].startDate && selectedDate[0].endDate
                      ? `${selectedDate[0].startDate.toLocaleDateString(
                          "en-GB",
                          { day: "2-digit", month: "short", year: "2-digit" }
                        )} - ${selectedDate[0].endDate.toLocaleDateString(
                          "en-GB",
                          { day: "2-digit", month: "short", year: "2-digit" }
                        )}`
                      : "Date"}
                  </Button>
                  {/* </MenuButton> */}
                </Grid>

                <Grid item sx={{ paddingLeft: "0px !important" }}>
                  <Dropdown>
                    <MenuButton
                      onClick={handleOpen}
                      size="lg"
                      endDecorator={
                        queryStatus === "" ? (
                          <Filter />
                        ) : (
                          <Cross
                            onClick={() => {
                              setQueryStatus("");
                              setPage(1);
                            }}
                          ></Cross>
                        )
                      }
                      sx={{
                        color: "black !important",
                        backgroundColor: "white",
                        borderRadius: "0px",
                        borderLeft: "0px",
                        borderRight: "0px",
                        width: "fit-content",
                        fontWeight: 500,
                        fontSize: "16px",
                        position: "relative",
                        padding: "0px 12px !important",
                        fontFamily: "Inter",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          left: "0px",
                          background: "#CBCBCB",
                          width: "0.5px",
                          height: "75%",
                        }}
                      ></div>
                      {queryStatus
                        ? queryStatus === "toBeShared"
                          ? "To Be Assigned"
                          : queryStatus === "pendingOnPricing"
                          ? "Pending On Pricing"
                          : queryStatus === "pendingOnCategory"
                          ? "Pending On Category"
                          : queryStatus === "alreadyShared"
                          ? "Already Shared"
                          : queryStatus === "cannotProcure"
                          ? "Cannot Procure"
                          : "Status"
                        : "Status"}
                    </MenuButton>
                    <Menu
                      sx={{
                        padding: "0px",
                        border: "0px",
                      }}
                    >
                      {user &&
                        (user.role === "superuser" ||
                          user.role === "pricing" ||
                          user.role === "sales") && (
                          <MenuItem
                            sx={{
                              color: "#ffffff",
                              backgroundColor: "#6c8aa3",
                              fontWeight: 500,
                              fontSize: "14px",
                            }}
                            onClick={() => {
                              setQueryStatus("toBeShared");
                              setPage(1);
                              handleClose();
                            }}
                          >
                            To Be Assigned
                          </MenuItem>
                        )}

                      <MenuItem
                        sx={{
                          // color: "#ffffff",
                          // backgroundColor: "#399ff5",
                          color: "#ffffff",
                          backgroundColor: "#6c8aa3",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                        onClick={() => {
                          setQueryStatus("pendingOnPricing");
                          setPage(1);
                          handleClose();
                        }}
                      >
                        Pending on Pricing
                      </MenuItem>

                      <MenuItem
                        sx={{
                          // color: "#ffffff",
                          // backgroundColor: "#399ff5",
                          color: "#ffffff",
                          backgroundColor: "#6c8aa3",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                        onClick={() => {
                          setQueryStatus("pendingOnCategory");
                          setPage(1);
                          handleClose();
                        }}
                      >
                        Pending On Category
                      </MenuItem>

                      <MenuItem
                        sx={{
                          // color: "#ffffff",
                          // backgroundColor: "#4c555c",
                          color: "#ffffff",
                          backgroundColor: "#6c8aa3",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                        onClick={() => {
                          setQueryStatus("alreadyShared");
                          setPage(1);
                          handleClose();
                        }}
                      >
                        Already Shared
                      </MenuItem>

                      <MenuItem
                        sx={{
                          // color: "#ffffff",
                          // backgroundColor: "#4c555c",
                          color: "#ffffff",
                          backgroundColor: "#6c8aa3",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                        onClick={() => {
                          setQueryStatus("cannotProcure");
                          setPage(1);
                          handleClose();
                        }}
                      >
                        Cannot Procure
                      </MenuItem>
                    </Menu>
                  </Dropdown>
                </Grid>

                <Grid item sx={{ paddingLeft: "0px !important" }}>
                  <Dropdown>
                    <MenuButton
                      onClick={handleOpen}
                      size="lg"
                      // endDecorator={<Filter />}
                      endDecorator={
                        fromGeneric === false &&
                        fromManual === false &&
                        source === "" ? (
                          <Filter />
                        ) : (
                          <Cross
                            onClick={() => {
                              setSource("");
                              setFromManual(false);
                              setFromGeneric(false);
                              setPage(1);
                            }}
                          />
                        )
                      }
                      sx={{
                        color: "black !important",
                        backgroundColor: "white",
                        borderRadius: "0px",
                        borderLeft: "0px",
                        borderRight: "0px",
                        position: "relative",
                        width: "fit-content",
                        fontWeight: 500,
                        fontSize: "16px",
                        padding: "0px 12px !important",
                        fontFamily: "Inter",
                      }}
                      className={
                        (user && user.role) === "sales" ? "sales-source" : ""
                      }
                    >
                      <div
                        style={{
                          position: "absolute",
                          left: "0px",
                          background: "#CBCBCB",
                          width: "0.5px",
                          height: "75%",
                        }}
                      ></div>
                      {source === "lsq"
                        ? "Raised by Sales (LSQ)"
                        : fromGeneric === true
                        ? "Generic Quotation"
                        : fromManual === true
                        ? "Manual Quotation"
                        : "Source"}
                    </MenuButton>
                    <Menu
                      sx={{
                        padding: "0px",
                        border: "0px",
                      }}
                    >
                      <MenuItem
                        sx={{
                          // color: "#ffffff",
                          // backgroundColor: "#6c8aa3",
                          color: "#ffffff",
                          backgroundColor: "#6c8aa3",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                        onClick={() => {
                          setSource("lsq");
                          setFromManual(false);
                          setFromGeneric(false);
                          setPage(1);
                          handleClose();
                        }}
                      >
                        Raised by Sales (LSQ)
                      </MenuItem>

                      <MenuItem
                        sx={{
                          // color: "#ffffff",
                          // backgroundColor: "#4c555c",
                          color: "#ffffff",
                          backgroundColor: "#6c8aa3",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                        onClick={() => {
                          setFromManual(true);
                          setSource("");
                          setFromGeneric(false);
                          setPage(1);
                          handleClose();
                        }}
                      >
                        Manual Quotation
                      </MenuItem>

                      {/* to be added Later  */}
                      {/* <MenuItem
                          sx={{
                            // color: "#ffffff",
                            // backgroundColor: "#6c8aa3",
                            color: "#ffffff",
                            backgroundColor: "#6c8aa3",
                            fontWeight: 500,
                            fontSize: "14px",
                          }}
                          onClick={() => {
                            setFromGeneric(true);
                            setFromManual(false);
                            setSource("");
                            setPage(1);
                            handleClose();
                          }}
                        >
                          Generic Quotation
                        </MenuItem> */}
                    </Menu>
                  </Dropdown>
                </Grid>

                {user && user.role !== "sales" && (
                  <Grid item sx={{ paddingLeft: "0px !important" }}>
                    <Dropdown>
                      <MenuButton
                        onClick={handleOpen}
                        size="lg"
                        // endDecorator={<Filter/>}
                        endDecorator={
                          createdBy === "" ? (
                            <Filter />
                          ) : (
                            <Cross
                              onClick={() => {
                                setPage(1);
                                setCreatedBy("");
                              }}
                            >
                              cross
                            </Cross>
                          )
                        }
                        sx={{
                          color: "black !important",
                          backgroundColor: "white",
                          borderTopLeftRadius: "0px",
                          borderBottomLeftRadius: "0px",
                          borderTopRightRadius: "7px",
                          borderBottomRightRadius: "7px",
                          borderLeft: "0px",
                          width: "fit-content",
                          fontWeight: 500,
                          fontSize: "16px",
                          position: "relative",
                          padding: "0px 12px !important",
                          fontFamily: "Inter",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            left: "0px",
                            background: "#CBCBCB",
                            width: "0.5px",
                            height: "75%",
                          }}
                        ></div>
                        {createdBy
                          ? createdBy === "superuser" || createdBy === "pricing"
                            ? "Pricing"
                            : createdBy === "category"
                            ? "Category"
                            : createdBy === "sales"
                            ? "Sales"
                            : "Created By"
                          : "Created By"}
                      </MenuButton>

                      <Menu
                        sx={{
                          padding: "0px",
                          border: "0px",
                        }}
                      >
                        <MenuItem
                          sx={{
                            // color: "#ffffff",
                            // backgroundColor: "#4c555c",
                            color: "#ffffff",
                            backgroundColor: "#6c8aa3",
                            fontWeight: 500,
                            fontSize: "14px",
                          }}
                          onClick={() => {
                            setCreatedBy("pricing");
                            // setFromManual(false);
                            setPage(1);
                            handleClose();
                          }}
                        >
                          Pricing
                        </MenuItem>

                        <MenuItem
                          sx={{
                            // color: "#ffffff",
                            // backgroundColor: "#399ff5",
                            color: "#ffffff",
                            backgroundColor: "#6c8aa3",
                            fontWeight: 500,
                            fontSize: "14px",
                          }}
                          onClick={() => {
                            setCreatedBy("category");
                            // setFromManual(false);
                            setPage(1);
                            handleClose();
                          }}
                        >
                          Category
                        </MenuItem>
                        <MenuItem
                          sx={{
                            // color: "#ffffff",
                            // backgroundColor: "#399ff5",
                            color: "#ffffff",
                            backgroundColor: "#6c8aa3",
                            fontWeight: 500,
                            fontSize: "14px",
                          }}
                          onClick={() => {
                            setCreatedBy("sales");
                            // setFromManual(false);
                            setPage(1);
                            handleClose();
                          }}
                        >
                          Sales
                        </MenuItem>
                      </Menu>
                    </Dropdown>
                  </Grid>
                )}
              </div>

              <TextField
                fullWidth
                className="lsq-textfield"
                // label="Search Products"
                id="fullWidth"
                placeholder="Search Query/Email/POC"
                value={searchText}
                onChange={handleSearchInputChange}
                onKeyDown={(e) =>
                  e.key === "Enter"
                    ? (() => {
                        setPage(1);
                        getQueries();
                      })()
                    : null
                }
                sx={{
                  flex: "1",
                  padding: "0px !important",
                  borderRadius: "7px !important",
                  marginTop: "41px",
                  marginLeft: "10px",
                  minWidth: "200px",
                  height: "fit-content !important",
                  padding: "0px 0px 0px 5px !important",
                }}
                InputProps={{
                  endAdornment: (
                    // <InputAdornment
                    //   position="end"
                    //   sx={{background:'red !important' , height:'10px !important'}}
                    // >
                    <Search
                      onClick={() => {
                        setPage(1);
                        getQueries();
                      }}
                      style={{
                        background: "#C9C9C9",
                        cursor: "pointer",
                        padding: "0px 10px",
                        height: "42px",
                        borderBottomRightRadius: "3px",
                        borderTopRightRadius: "3px",
                        border: "none",
                        borderRadius: "5px",
                        position: "absolute",
                        right: "0px",
                      }}
                    />
                    // </InputAdornment>
                  ),
                }}
              />

              <Button
                size="small"
                onClick={() => {
                  setPage(1);
                  setCreatedBy("");
                  setFromGeneric(false);
                  setFromManual(false);
                  setSource("");
                  setQueryStatus("");
                  setSelectedDate([
                    {
                      startDate: null,
                      endDate: null,
                      key: "selection",
                    },
                  ]);
                  setSearchText("");
                }}
                sx={{
                  height: "2.75rem",
                  marginTop: "39px",
                  marginLeft: "0%",
                  padding: "2px",
                  minWidth: "fit-content",
                  width: "30px",
                  background: "beige",
                }}
              >
                &#10006;
              </Button>

              <Grid
                item
                sx={{ width: "fit-content", paddingLeft: "0px !important" }}
              >
                <Button
                  variant="contained"
                  sx={{
                    color: "white !important",
                    backgroundColor: "#489a33",
                    borderRadius: "7px",
                    boxShadow: "0px 4px 4px #00000040",
                    width: "fit-content",
                    padding: "8px",
                  }}
                  onClick={handleCreateQuotation}
                  size="large"
                >
                  Create Quotation
                </Button>
              </Grid>
            </Grid>
          </Container>

          {/* <div style={{background:'red' , paddingTop:'20px' , position:'sticky' , top:'100px'}}> */}
          <TableContainer
            sx={{ maxHeight: "75vh" }}
            component={Paper}
            className="lsq-table"
          >
            <Table sx={{ overflowY: "scroll" }} aria-label="collapsible table">
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: "#5390c2",
                    position: "sticky",
                    top: "0px",
                    zIndex: "10",
                  }}
                >
                  <TableCell />
                  {user &&
                  (user.role === "superuser" ||
                    user.role === "pricing" ||
                    user.role === "sales") ? (
                    <>
                      {tableHeader.map((tableH) => (
                        <TableCell
                          key={tableH}
                          align={tableH === "Status" ? "center" : "center"}
                          sx={{
                            color: "#ffffff",
                            fontFamily: "Inter-Bold, Helvetica",
                            fontSize: "15px",
                            fontWeight: "700",
                            padding: "6px",
                            paddingLeft: "0px !important",
                          }}
                        >
                          <TableSortLabel
                            active={sortBy === tableHeaderkeys[tableH]}
                            direction={
                              sortBy === tableHeaderkeys[tableH]
                                ? sortOrder
                                : "asc"
                            }
                            onClick={() => handleSort(tableHeaderkeys[tableH])}
                          >
                            {tableH}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </>
                  ) : (
                    <>
                      {tableHeaderCategory.map((tableH) => (
                        <TableCell
                          key={tableH}
                          align={tableH === "Status" ? "center" : "center"}
                          sx={{
                            color: "#ffffff",
                            fontFamily: "Inter-Bold, Helvetica",
                            fontSize: "15px",
                            fontWeight: "700",
                            padding: "1px",
                          }}
                        >
                          <TableSortLabel
                            active={sortBy === tableHeaderkeys[tableH]}
                            direction={
                              sortBy === tableHeaderkeys[tableH]
                                ? sortOrder
                                : "asc"
                            }
                            onClick={() => handleSort(tableHeaderkeys[tableH])}
                          >
                            {tableH}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody className="lsq-data-cell">
                {sortedData &&
                  sortedData.map((row) => (
                    <Row
                      key={row._id}
                      row={row}
                      setOpenPopUp={setOpenPopUp}
                      setSelectedQuery={setSelectedQuery}
                      setSelectedStatus={setSelectedStatus}
                      showPreviousQuotationData={showPreviousQuotationData}
                      open={openRow === row._id}
                      onRowClick={() => handleRowClick(row._id)}
                      sx={{ background: "yellow" }}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* </div>   */}

          <div
            style={{
              backgroundColor: "#F5F5F5",
              borderRadius: "2px",
              marginTop: "1%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(totalQuery / queryPerPage)}
                boundaryCount={1}
                page={page}
                siblingCount={2}
                onChange={handleChange}
              />
            </Stack>
          </div>

          <div>
            {openPopUp && (
              <PopupDialog
                open={openPopUp}
                onClose={() => setOpenPopUp(false)}
                onSelect={handlePopupSelection}
                selectedQuery={selectedQuery}
                selectedStatus={selectedStatus}
              />
            )}
          </div>
          <div>
            {openPrevQuotationPopUp && (
              <PreviousQuotationPopUp
                open={openPrevQuotationPopUp}
                onClose={() => setOpenPrevQuotationPopUp(false)}
                prevQuotationData={prevQuotationData}
                popUpLoading={popUpLoading}
              />
            )}
          </div>
          <div>
            {openremarkPopUp && (
              <RemarkPopUp
                open={openremarkPopUp}
                onClose={() => setOpenRemarkPopUp(false)}
                onSelect={sendToCategoryWithRemarks}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default LsqQuery;

const RemarkPopUp = ({ open, onClose, onSelect }) => {
  const [remarks, setRemarks] = useState("");

  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(true);
  const handleSelectRemark = () => {
    onSelect(remarks);
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        component: "form",
        onSubmit: (event) => {
          event.preventDefault();
          handleSelectRemark();
        },

        sx: {
          width: "700px",
          maxWidth: "2000px !important",
        },
      }}
      sx={{
        position: "absolute",
        left: "25%",
        top: "10%",
        width: "900px",
        maxWidth: "2000px !important",
      }}
    >
      <DialogTitle>Client Requirement Remarks</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          required
          margin="dense"
          id="title"
          name="title"
          label="Remarks"
          type="text"
          fullWidth
          variant="standard"
          value={remarks}
          onChange={(e) => {
            setRemarks(e.target.value);
            setIsSendButtonDisabled(e.target.value.trim() === "");
          }}
        />
      </DialogContent>
      <DialogActions>
        <Cross
          style={{
            position: "absolute",
            top: "2%",
            right: "1%",
            cursor: "pointer",
          }}
          onClick={onClose}
        />
        <Button
          type="submit"
          sx={{
            backgroundColor: isSendButtonDisabled ? "#C9C9C9" : "#489A34",
            cursor: isSendButtonDisabled ? "not-allowed" : "pointer",
            "&:hover": {
              backgroundColor: isSendButtonDisabled ? "#C9C9C9" : "#489A34",
            },
            color: isSendButtonDisabled ? "#898989" : "white",
            fontFamily: "Inter , Helvetica",
          }}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const PrevDialog = ({ open, onClose, onSelect, quotation }) => {
  return (
    <React.Fragment>
      <Dialog fullScreen open={open} onClose={onClose}>
        <AppBar
          sx={{ position: "fixed" }}
          style={{ backgroundColor: "#438ea3" }}
        >
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClose}>
              <CloseIcon />
            </IconButton>

            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Preview
            </Typography>
          </Toolbar>
        </AppBar>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            marginTop: "65px",
            overflowY: "hidden",
          }}
        >
          <PDFViewer
            showToolbar={false}
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            <PDFView quotations={quotation} />
          </PDFViewer>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

const PopupDialog = ({
  open,
  onClose,
  onSelect,
  selectedQuery,
  selectedStatus,
}) => {
  const handleSelection = (selectedOption) => {
    onSelect(selectedOption);
  };
  return (
    selectedStatus === "toBeShared" && (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="1000px"
        sx={{ textAlign: "center", borderRadius: "8px" }}
      >
        <DialogTitle
          sx={{
            color: "black",
            fontFamily: "Inter , Helvetica",
            fontWeight: "600",
            fontSize: "20px",
            marginTop: "15px",
            marginBottom: "15px",
          }}
        >
          Does this query {selectedQuery} need to be sent
          <br /> to category team?
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            onClick={() => handleSelection("pendingOnCategory")}
            sx={{
              color: "#0081df",
              borderRadius: "7px",
              background: "white",
              border: "0.7px solid",
              ":hover": {
                background: "#0081df",
                color: "white",
              },
            }}
          >
            Assign to Category
          </Button>
          <Button
            onClick={() => handleSelection("pendingOnPricing")}
            sx={{
              color: "#0081df",
              borderRadius: "7px",
              borderColor: "#0081df",
              border: "0.7px solid",
              background: "white",
              ":hover": {
                background: "#0081df",
                color: "white",
              },
            }}
          >
            Pricing will Share
          </Button>
        </DialogContent>
        <DialogActions>
          <Cross
            style={{
              position: "absolute",
              top: "2%",
              right: "1%",
              cursor: "pointer",
            }}
            onClick={onClose}
          ></Cross>
        </DialogActions>
      </Dialog>
    )
  );
};

const PreviousQuotationPopUp = ({
  open,
  onClose,
  prevQuotationData,
  popUpLoading,
}) => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const showPreviousQuotationData = (
    itemDetails,
    selectedQueryId,
    selectedQuotationId
  ) => {
    localStorage.setItem("selectedItems", JSON.stringify(itemDetails));
    navigate(`/quotationCreation/${selectedQueryId}/${selectedQuotationId}`);
  };

  function convertUTCToIST(utcDateTimeString) {
    const utcDateTime = new Date(utcDateTimeString);
    const ISTDateTime = utcDateTime.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata",
    });
    return ISTDateTime;
  }
  const handleopenpreview = () => {
    setshowPrevDialog(true);
  };
  const [showPrevDialog, setshowPrevDialog] = useState(false);
  const [previewItem, setPreviewItem] = useState();

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Select Previous Quotation</DialogTitle>
      <DialogContent>
        {popUpLoading ? (
          <CircularProgress />
        ) : (
          <ul
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "15px",
              fontFamily: "Inter",
            }}
          >
            {prevQuotationData &&
              prevQuotationData.map((item) => (
                <li
                  key={item._id}
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  {item.quotationId}
                  <span>
                    {item.createdAt
                      ? convertUTCToIST(item.createdAt)
                      : "26/2/2024, 10:52:44 am"}
                  </span>
                  {/* <span>preview</span> */}
                  {item.quotationLink ? (
                    <button
                      onClick={() => {
                        setPreviewItem(item);
                        handleopenpreview();
                      }}
                      style={{
                        backgroundColor: "#6C8AA3",
                        border: "1px solid #6C8AA3",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        color: "white",
                        cursor: "pointer",
                        fontFamily: "Inter",
                      }}
                    >
                      Preview
                    </button>
                  ) : (
                    <span
                      style={{
                        backgroundColor: "#6C8AA3",
                        border: "1px solid #6C8AA3",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        color: "white",
                        cursor: "pointer",
                        fontFamily: "Inter",
                      }}
                    >
                      preview
                    </span>
                  )}
                  {user &&
                    (user.role === "superuser" ||
                      user.role === "pricing" ||
                      (user.role === "category" &&
                        item.fromManualQuotation === true)) && (
                      <button
                        onClick={() =>
                          showPreviousQuotationData(
                            item.itemDetails,
                            item.queryId,
                            item.quotationId
                          )
                        }
                        style={{
                          backgroundColor: "#6C8AA3",
                          border: "1px solid #6C8AA3",
                          padding: "5px 10px",
                          borderRadius: "5px",
                          color: "white",
                          cursor: "pointer",
                          fontFamily: "Inter",
                        }}
                      >
                        Edit
                      </button>
                    )}
                  {showPrevDialog && (
                    <PrevDialog
                      open={showPrevDialog}
                      onClose={() => setshowPrevDialog(false)}
                      quotation={[previewItem]}
                    />
                  )}
                </li>
              ))}
          </ul>
        )}
      </DialogContent>
    </Dialog>
  );
};

const Row = ({
  row,
  setOpenPopUp,
  showPreviousQuotationData,
  setSelectedQuery,
  setSelectedStatus,
  open,
  onRowClick,
}) => {
  const { user } = useContext(UserContext);

  function convertUTCToIST(utcDateTimeString) {
    const utcDateTime = new Date(utcDateTimeString);
    const ISTDateTime = utcDateTime.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata",
    });
    return ISTDateTime;
  }

  const formattedDate = (value) => {
    const utcDate = new Date(value);
    const istDate = utcDate.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata",
    });
    const [datePart] = istDate.split(",");
    return datePart.trim();
  };

  const formattedTime = (value) => {
    const utcDate = new Date(value);
    const istDate = utcDate.toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata",
    });
    const [, timePart] = istDate.split(",");
    return timePart.trim();
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        style={{
          backgroundColor:
            row.status === "toBeShared"
              ? "transparent"
              : row.status === "pendingOnPricing" &&
                user &&
                (user.role === "superuser" || user.role === "pricing")
              ? "transparent"
              : row.status === "pendingOnCategory" &&
                user &&
                user.role === "category"
              ? "transparent"
              : "#EAEAEA",
        }}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={onRowClick}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="left"
          style={{ fontWeight: 500, padding: "1px" }}
        >
          {(row.status === "pendingOnPricing" &&
            user &&
            (user.role === "superuser" || user.role === "pricing")) ||
          (row.status === "pendingOnCategory" &&
            user &&
            user.role === "category") ? (
            <Link
              to={`/quotationCreation/${row.queryId}`}
              style={{
                color: "#0671bf",
                textDecoration: "none",
              }}
              onMouseEnter={(e) =>
                (e.target.style.textDecoration = "underline")
              }
              onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
            >
              {row.queryId}
            </Link>
          ) : (
            // Render plain text if status is not "pending"
            <span>{row.queryId}</span>
          )}
        </TableCell>
        <TableCell
          align="left"
          style={{ fontWeight: 500, padding: "1px" }}
          onClick={onRowClick}
        >
          {/* {new Date(`${row.createdAt}`)
            .toISOString()
            .replace(/T/, " ")
            .replace(/\..+/, "")} */}
          {/* {row.createdAt
            ? convertUTCToIST(row.createdAt)
            : "26/2/2024, 10:52:44 am"} */}
          {row.createdAt ? `${formattedDate(row.createdAt)}` : "26/2/2024"}
          <br />
          {row.createdAt ? `${formattedTime(row.createdAt)}` : "10:52:44 am"}
        </TableCell>
        <TableCell
          align="left"
          style={{
            maxWidth: 75,
            overflowWrap: "break-word",
            whiteSpace: "pre-wrap",
            fontWeight: 500,
            padding: "1px",
          }}
          onClick={onRowClick}
        >
          {row.email}
        </TableCell>
        <TableCell
          align="left"
          style={{
            maxWidth: 400,
            whiteSpace: "pre-wrap",
            padding: "10px",
          }}
          onClick={onRowClick}
        >
          {row.productName}
        </TableCell>
        <TableCell align="left" style={{ padding: "1px" }} onClick={onRowClick}>
          {row.establishmentname}
        </TableCell>
        <TableCell align="left" style={{ padding: "1px" }} onClick={onRowClick}>
          {row.pocName}
        </TableCell>
        <TableCell align="left" style={{ padding: "1px" }} onClick={onRowClick}>
          {row.urgentQuery}
        </TableCell>
        <TableCell align="left" style={{ padding: "1px" }} onClick={onRowClick}>
          {row.city}
        </TableCell>
        <TableCell align="left" style={{ padding: "1px" }} onClick={onRowClick}>
          {row.warmth}
        </TableCell>
        <TableCell
          align="left"
          style={{ fontWeight: 500, padding: "1px" }}
          onClick={onRowClick}
        >
          {/* {new Date(`${row.createdAt}`)
            .toISOString()
            .replace(/T/, " ")
            .replace(/\..+/, "")} */}
          {/* {row.updatedAt
          ? convertUTCToIST(row.updatedAt)
          : "26/2/2024, 10:52:44 am"} */}
          {row.updatedAt ? `${formattedDate(row.updatedAt)}` : "26/2/2024"}
          <br />
          {row.updatedAt ? `${formattedTime(row.updatedAt)}` : "10:52:44 am"}
        </TableCell>
        {user && user.role === "sales" && (
          <TableCell align="left" style={{ padding: "1px" }}>
            {row.status === "toBeShared" &&
              row.isThisReopen &&
              "To Be Assigned (R)"}
            {row.status === "toBeShared" &&
              !row.isThisReopen &&
              "To Be Assigned"}
            {row.status === "pendingOnPricing" && "Pending on Pricing"}
            {row.status === "pendingOnCategory" && "Pending on Category"}
            {((row.status === "alreadyShared" && "Already Shared") ||
              (row.status === "cannotProcure" && "Closed Query")) && (
              <Button
                variant="contained"
                size="medium"
                style={{
                  fontSize: "10px",
                  background: "#6C8AA3",
                  width: "90%",
                }}
                onClick={() => {
                  setSelectedQuery(row.queryId);
                  setSelectedStatus(row.status);
                  showPreviousQuotationData(row.queryId);
                }}
              >
                {row.status}
              </Button>
            )}
          </TableCell>
        )}
        {user && user.role !== "sales" && (
          <TableCell align="left" style={{ padding: "6px" }}>
            {row.status === "toBeShared" ||
            row.status === "alreadyShared" ||
            row.status === "cannotProcure" ? (
              <Button
                variant="contained"
                size="medium"
                style={{
                  fontSize: "10px",
                  width: "70%",
                  background:
                    row.status === "toBeShared"
                      ? "#399FF5"
                      : row.status === "pendingOnPricing" ||
                        row.status === "pendingOnCategory"
                      ? "#4C555C"
                      : row.status === "alreadyShared"
                      ? "#6C8AA3"
                      : "defaultColor",
                }}
                onClick={
                  row.status === "toBeShared"
                    ? () => {
                        setOpenPopUp(true);
                        setSelectedQuery(row.queryId);
                        setSelectedStatus(row.status);
                      }
                    : row.status === "alreadyShared" ||
                      row.status === "cannotProcure"
                    ? () => {
                        setSelectedQuery(row.queryId);
                        setSelectedStatus(row.status);
                        showPreviousQuotationData(row.queryId);
                      }
                    : undefined
                }
              >
                {/* {row.status === "toBeShared"
                  ? row.isThisReopen
                    ? "To Be Assigned (R)"
                    : "To Be Assigned"
                  : "Already Shared"} */}
                {row.status === "toBeShared" &&
                  (row.isThisReopen ? "To Be Assigned (R)" : "To Be Assigned")}
                {row.status === "alreadyShared" && "Already Shared"}
                {row.status === "cannotProcure" && "Closed Query"}
                {/* {row.status === "pendingOnPricing" && "Pending on Pricing"}
            {row.status === "pendingOnCategory" && "Pending on Category"} */}
                {/* {row.status === "alreadyShared" && "Already Shared"} */}
              </Button>
            ) : (
              <>
                {row.status === "pendingOnPricing" && "Pending on Pricing"}
                {row.status === "pendingOnCategory" && "Pending on Category"}
              </>
            )}
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <TableRow className="custom-table-row">
                    <div
                      style={{
                        gridColumn: "1 / span 1",
                        gridRow: "1 / span 1",
                        display: "flex",
                        flexDirection: "column",
                        width: "fit-content",
                      }}
                    >
                      <TableCell
                        align="left"
                        className="lsq-collapsible-heading"
                        // style={{
                        //   borderBottom: "none",
                        //   color: "#00447D",
                        //   fontFamily: "Inter",
                        //   fontSize: "16px",
                        //   fontWeight: "700",
                        // }}
                      >
                        Brand
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          borderBottom: "none",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#343434",
                        }}
                        component="th"
                        scope="row"
                      >
                        {row.brand}
                      </TableCell>
                    </div>

                    <div
                      style={{
                        gridColumn: "1 / span 1",
                        gridRow: "2 / span 1",
                        display: "flex",
                        flexDirection: "column",
                        width: "fit-content",
                      }}
                    >
                      <TableCell
                        align="left"
                        className="lsq-collapsible-heading"
                      >
                        Specification
                      </TableCell>
                      <TableCell
                        style={{
                          borderBottom: "none",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#343434",
                          maxWidth: "500px",
                        }}
                        align="left"
                      >
                        {row.specification}
                      </TableCell>
                    </div>

                    <div
                      style={{
                        gridColumn: "1 / span 1",
                        gridRow: "3 / span 1",
                        display: "flex",
                        flexDirection: "column",
                        width: "fit-content",
                      }}
                    >
                      <TableCell
                        align="left"
                        className="lsq-collapsible-heading"
                      >
                        Establishment Type
                      </TableCell>
                      <TableCell
                        style={{
                          borderBottom: "none",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#343434",
                        }}
                        align="left"
                      >
                        {row.establishmentType}
                      </TableCell>
                    </div>

                    <div
                      style={{
                        gridColumn: "1 / span 1",
                        gridRow: "4 / span 1",
                        display: "flex",
                        flexDirection: "column ",
                        width: "fit-content",
                      }}
                    >
                      <TableCell
                        align="left"
                        className="lsq-collapsible-heading"
                      >
                        POC Phone
                      </TableCell>
                      <TableCell
                        style={{
                          borderBottom: "none",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#343434",
                        }}
                        align="left"
                      >
                        {row.phonePoc}
                      </TableCell>
                    </div>

                    <div
                      style={{
                        gridColumn: "1 / span 1",
                        gridRow: "5 / span 1",
                        display: "flex",
                        flexDirection: "column",
                        width: "fit-content",
                      }}
                    >
                      <TableCell
                        align="left"
                        className="lsq-collapsible-heading"
                      >
                        No of Beds
                      </TableCell>
                      <TableCell
                        style={{
                          borderBottom: "none",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#343434",
                        }}
                        align="left"
                      >
                        {row.bedNo}
                      </TableCell>
                    </div>

                    <div
                      style={{
                        gridColumn: "2 / span 1",
                        gridRow: "1/ span 2",
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <TableCell
                        align="left"
                        className="lsq-collapsible-heading"
                      >
                        Image
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          display: "list-item",
                          alignItems: "center",
                          listStyle: "none",
                          borderBottom: "none",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#343434",
                        }}
                      >
                        {row.image1 && (
                          <span
                            onClick={() => window.open(row.image1, "_blank")}
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                              color: "blue",
                            }}
                          >
                            image1{" "}
                          </span>
                        )}
                        {row.image2 && (
                          <span
                            onClick={() => window.open(row.image2, "_blank")}
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                              color: "blue",
                            }}
                          >
                            image2{" "}
                          </span>
                        )}
                        {row.image3 && (
                          <span
                            onClick={() => window.open(row.image3, "_blank")}
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                              color: "blue",
                            }}
                          >
                            image3{" "}
                          </span>
                        )}
                        {row.image4 && (
                          <span
                            onClick={() => window.open(row.image4, "_blank")}
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                              color: "blue",
                            }}
                          >
                            image4{" "}
                          </span>
                        )}
                      </TableCell>
                    </div>

                    <div
                      style={{
                        gridColumn: "2/ span 1",
                        gridRow: "3 / span 1",
                        display: "flex",
                        flexDirection: "column",
                        width: "fit-content",
                      }}
                    >
                      <TableCell
                        align="left"
                        className="lsq-collapsible-heading"
                      >
                        Why is it Urgent
                      </TableCell>
                      <TableCell
                        style={{
                          borderBottom: "none",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#343434",
                        }}
                        align="left"
                      >
                        {row.reasonUrgent}
                      </TableCell>
                    </div>

                    <div
                      style={{
                        gridColumn: "2 / span 1",
                        gridRow: "4/ span 1",
                        borderBottom: "none",
                        display: "flex",
                        flexDirection: "column",
                        width: "fit-content",
                      }}
                    >
                      <TableCell
                        align="left"
                        className="lsq-collapsible-heading"
                      >
                        Valid Proof of Target Price
                      </TableCell>
                      <TableCell
                        style={{
                          borderBottom: "none",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#343434",
                        }}
                        align="left"
                      >
                        {row.validproofoftargetprice && (
                          <span
                            onClick={() =>
                              window.open(row.validproofoftargetprice, "_blank")
                            }
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                              color: "blue",
                              borderBottom: "none",
                            }}
                          >
                            Link{" "}
                          </span>
                        )}
                      </TableCell>
                    </div>
                    <div
                      style={{
                        gridColumn: "2 / span 1",
                        gridRow: "5 / span 1",
                        display: "flex",
                        flexDirection: "column",
                        width: "fit-content",
                      }}
                    >
                      <TableCell
                        align="left"
                        className="lsq-collapsible-heading"
                      >
                        Lead Source
                      </TableCell>
                      <TableCell
                        style={{
                          borderBottom: "none",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#343434",
                        }}
                        align="left"
                      >
                        {row.leadSource}
                      </TableCell>
                    </div>

                    <div
                      style={{
                        gridColumn: "3 / span 1",
                        gridRow: "1 / span 6",
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        maxWidth: "700px",
                        background: "#F7F7F7",
                        borderRadius: "5px",
                        fontFamily: "Inter",
                        textAlign: "left",
                      }}
                    >
                      <div>
                        <div
                          style={{
                            padding: "10px",
                            paddingBottom: "0px",
                          }}
                        >
                          <p
                            style={{
                              margin: "0px",
                              fontWeight: "500",
                              fontSize: "16px",
                              color: "#00447D",
                            }}
                          >
                            Additional Remarks
                          </p>
                          <div
                            style={{
                              maxHeight: "60px",
                              overflowY: "scroll",
                              scrollbarWidth: "thin",
                            }}
                          >
                            <p>{row.remarks ? row.remarks : ""}</p>
                          </div>
                        </div>

                        <div
                          style={{
                            padding: "10px",
                            paddingBottom: "0px",
                          }}
                        >
                          <p
                            style={{
                              margin: "0px",
                              fontWeight: "500",
                              fontSize: "16px",
                              color: "#00447D",
                            }}
                          >
                            Quantity:{" "}
                            <span
                              style={{ fontSize: "14px", color: "#202020" }}
                            >
                              {row.quantity ? row.quantity : ""}
                            </span>
                          </p>
                        </div>

                        {row.isThisReopen && (
                          <div
                            style={{
                              padding: "10px",
                              paddingBottom: "0px",
                            }}
                          >
                            <p
                              style={{
                                margin: "0px",
                                fontWeight: "500",
                                fontSize: "16px",
                                color: "#00447D",
                              }}
                            >
                              ReOpen Remarks By Sales
                            </p>
                            <div
                              style={{
                                maxHeight: "40px",
                                overflowY: "scroll",
                                scrollbarWidth: "thin",
                              }}
                            >
                              <p
                                style={{
                                  margin: "0px",
                                  fontSize: "14px",
                                }}
                              >
                                {row.remarksForReopen}
                              </p>
                            </div>
                          </div>
                        )}

                        {row.remarksForCategory && (
                          <div
                            style={{
                              padding: "10px",
                              paddingBottom: "0px",
                            }}
                          >
                            <p
                              style={{
                                margin: "0px",
                                fontWeight: "500",
                                fontSize: "16px",
                                color: "#00447D",
                              }}
                            >
                              Remarks For Category
                            </p>
                            <div
                              style={{
                                maxHeight: "40px",
                                overflowY: "scroll",
                                scrollbarWidth: "thin",
                              }}
                            >
                              <p
                                style={{
                                  margin: "0px",
                                  fontSize: "14px",
                                }}
                              >
                                {row.remarksForCategory}
                              </p>
                            </div>
                          </div>
                        )}

                        {row.closedRemarks && (
                          <div
                            style={{
                              padding: "10px",
                              paddingBottom: "0px",
                            }}
                          >
                            <p
                              style={{
                                margin: "0px",
                                fontWeight: "500",
                                fontSize: "16px",
                                color: "#00447D",
                              }}
                            >
                              Query Closed Remarks
                            </p>
                            <div
                              style={{
                                maxHeight: "40px",
                                overflowY: "scroll",
                                scrollbarWidth: "thin",
                              }}
                            >
                              <p
                                style={{
                                  margin: "0px",
                                  fontSize: "14px",
                                }}
                              >
                                {row.closedRemarks}
                              </p>
                            </div>
                          </div>
                        )}

                        {row.remarksForPricing && (
                          <div
                            style={{
                              padding: "10px",
                              paddingBottom: "0px",
                            }}
                          >
                            <p
                              style={{
                                margin: "0px",
                                fontWeight: "500",
                                fontSize: "16px",
                                color: "#00447D",
                              }}
                            >
                              Remarks For Pricing
                            </p>
                            <div
                              style={{
                                maxHeight: "40px",
                                overflowY: "scroll",
                                scrollbarWidth: "thin",
                              }}
                            >
                              <p
                                style={{
                                  margin: "0px",
                                  fontSize: "14px",
                                }}
                              >
                                {row.remarksForPricing}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
