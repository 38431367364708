import React, { useState } from "react";
import "./Popup1.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PopupForm2 = ({ onClose }) => {
  const [formData, setFormData] = useState({
    Name: "",
    HSN: "",
    ProductCategory: "",
    MedicalField1: "",
    MedicalField2: "",
    MedicalField3: "",
    BatchnoTracking: "",
    SerialNumberTracking: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //console.log('Form data:', formData);
    onClose();
  };

  const SaveProduct = async (e) => {
    e.preventDefault();
    try {
      const {
        Name,
        HSN,
        ProductCategory,
        MedicalField1,
        MedicalField2,
        MedicalField3,
        BatchnoTracking,
        SerialNumberTracking,
      } = formData;
      if (formData) {
        if (
          !Name ||
          !HSN ||
          !ProductCategory ||
          !MedicalField1 ||
          !BatchnoTracking ||
          !SerialNumberTracking
        ) {
          {
            toast.error("Fill all the Data", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } else if (!/^\d{8}$/.test(HSN)) {
          {
            toast.error("HSN should be 8 digit", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } else if (
          BatchnoTracking === "TRUE" &&
          SerialNumberTracking === "TRUE"
        ) {
          {
            toast.error(
              "Both Batch Tracking and Serial Number Tracking Cannot be TRUE",
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
          }
        } else {
          const response = await fetch("/addproduct", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              Name,
              HSN,
              ProductCategory,
              MedicalField1,
              MedicalField2,
              MedicalField3,
              BatchnoTracking,
              SerialNumberTracking,
            }),
          });

          const result = await response.json();

          if (result.exist) {
            toast.error("Product Already Added!", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.success("Product  Added!", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          }
        }
        //console.log(result)
      }
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <div className="addproductform">
      <div className="modalContainer">
        <h2 className="title">Add Product</h2>
        <form onSubmit={handleSubmit}>
          <div className="body">
            <label htmlFor="Name">Product Name:</label>
            <input
              type="text"
              id="productName"
              name="Name"
              value={formData.Name}
              onChange={handleChange}
            />
          </div>
          <div className="body">
            <label htmlFor="HSN">HSN:</label>
            <input
              type="text"
              id="HSN"
              name="HSN"
              value={formData.HSN}
              onChange={handleChange}
            />
          </div>
          <div className="body">
            <label htmlFor="ProductCategory">Product Category:</label>
            <select
              type="text"
              id="ProductCategories"
              name="ProductCategory"
              value={formData.ProductCategory}
              onChange={handleChange}
            >
              <option value="" disabled selected>
                Product Category
              </option>
              <option value="Consumables (Low-value)">
                Consumables (Low-value)
              </option>
              <option value="Consumables (High-value)">
                Consumables (High-value)
              </option>
              <option value="Medical Devices">Medical Devices</option>
              <option value="Medical Devices (Pre-Owned)">
                Medical Devices (Pre-Owned)
              </option>
              <option value="Instruments">Instruments</option>
              <option value="Hospital Furniture">Hospital Furniture</option>
              <option value="OTC">OTC</option>
              <option value="Medical Gases">Medical Gases</option>
              <option value="Medicine">Medicine</option>
              <option value="Implant">Implant</option>
              <option value="Technical Devices (Non-Medical)">
                Technical Devices (Non-Medical)
              </option>
              <option value="OT and ICU Setup">OT and ICU Setup</option>
              {/* <option value="Disposable">Disposable</option> */}
            </select>
          </div>
          <div className="body">
            <label htmlFor="MedicalField1">Medical Field1:</label>
            <select
              type="text"
              id="MedicalField1"
              name="MedicalField1"
              value={formData.MedicalField1}
              onChange={handleChange}
            >
              <option value="" disabled selected>
                Select{" "}
              </option>
              <option value="Multi-use">Multi-use</option>
              <option value="General Surgery">General Surgery</option>
              <option value="Urology">Urology</option>
              <option value="Proctology">Proctology</option>
              <option value="Laparoscopy">Laparoscopy</option>
              <option value="Orthopaedics">Orthopaedics</option>
              <option value="Opthalmology">Opthalmology</option>
              <option value="Nephrology">Nephrology</option>
              <option value="Diagnostics">Diagnostics</option>
              <option value="Radiology">Radiology</option>
              <option value="Pediatrics">Pediatrics</option>
              <option value="Vascular">Vascular</option>
              <option value="Gynaecology">Gynaecology</option>
              <option value="Bariatric">Bariatric</option>
              <option value="Gastroenterology">Gastroenterology</option>
              <option value="Cardiology">Cardiology</option>
              <option value="Anaesthesia">Anaesthesia</option>
              <option value="Dental">Dental</option>
              <option value="Infection Control">Infection Control</option>
              <option value="Neurosurgery">Neurosurgery</option>
              <option value="ENT">ENT</option>
              <option value="Physiotherapy">Physiotherapy</option>
              <option value="Oncology">Oncology</option>
              <option value="Dermatology">Dermatology</option>
            </select>
          </div>
          <div className="body">
            <label htmlFor="MedicalField2">Medical Field2:</label>
            <select
              type=""
              id="MedicalField2"
              name="MedicalField2"
              value={formData.MedicalField2}
              onChange={handleChange}
            >
              <option value="" disabled selected>
                Select
              </option>
              <option value="Multi-use">Multi-use</option>
              <option value="General Surgery">General Surgery</option>
              <option value="Urology">Urology</option>
              <option value="Proctology">Proctology</option>
              <option value="Laparoscopy">Laparoscopy</option>
              <option value="Orthopaedics">Orthopaedics</option>
              <option value="Opthalmology">Opthalmology</option>
              <option value="Nephrology">Nephrology</option>
              <option value="Diagnostics">Diagnostics</option>
              <option value="Radiology">Radiology</option>
              <option value="Pediatrics">Pediatrics</option>
              <option value="Vascular">Vascular</option>
              <option value="Gynaecology">Gynaecology</option>
              <option value="Bariatric">Bariatric</option>
              <option value="Gastroenterology">Gastroenterology</option>
              <option value="Cardiology">Cardiology</option>
              <option value="Anaesthesia">Anaesthesia</option>
              <option value="Dental">Dental</option>
              <option value="Infection Control">Infection Control</option>
              <option value="Neurosurgery">Neurosurgery</option>
              <option value="ENT">ENT</option>
              <option value="Physiotherapy">Physiotherapy</option>
              <option value="Oncology">Oncology</option>
              <option value="Dermatology">Dermatology</option>
            </select>
          </div>
          <div className="body">
            <label htmlFor="MedicalField3">Medical Field3:</label>
            <select
              type="text"
              id="MedicalField3"
              name="MedicalField3"
              value={formData.MedicalField3}
              onChange={handleChange}
            >
              <option value="" disabled selected>
                Select{" "}
              </option>
              <option value="Multi-use">Multi-use</option>
              <option value="General Surgery">General Surgery</option>
              <option value="Urology">Urology</option>
              <option value="Proctology">Proctology</option>
              <option value="Laparoscopy">Laparoscopy</option>
              <option value="Orthopaedics">Orthopaedics</option>
              <option value="Opthalmology">Opthalmology</option>
              <option value="Nephrology">Nephrology</option>
              <option value="Diagnostics">Diagnostics</option>
              <option value="Radiology">Radiology</option>
              <option value="Pediatrics">Pediatrics</option>
              <option value="Vascular">Vascular</option>
              <option value="Gynaecology">Gynaecology</option>
              <option value="Bariatric">Bariatric</option>
              <option value="Gastroenterology">Gastroenterology</option>
              <option value="Cardiology">Cardiology</option>
              <option value="Anaesthesia">Anaesthesia</option>
              <option value="Dental">Dental</option>
              <option value="Infection Control">Infection Control</option>
              <option value="Neurosurgery">Neurosurgery</option>
              <option value="ENT">ENT</option>
              <option value="Physiotherapy">Physiotherapy</option>
              <option value="Oncology">Oncology</option>
              <option value="Dermatology">Dermatology</option>
            </select>
          </div>
          <div className="body2">
            <label>Batch Tracking:</label>

            <select
              id="true"
              name="BatchnoTracking"
              value={formData.BatchnoTracking}
              onChange={handleChange}
            >
              <option value="" disabled selected>
                Select{" "}
              </option>
              <option value="TRUE">TRUE</option>
              <option value="FALSE">FALSE</option>
            </select>
          </div>
          <div className="body2">
            <label>Serial Tracking:</label>
            <div>
              <select
                id="true"
                name="SerialNumberTracking"
                value={formData.SerialNumberTracking}
                onChange={handleChange}
              >
                <option value="" disabled selected>
                  Select{" "}
                </option>
                <option value="TRUE">TRUE</option>
                <option value="FALSE">FALSE</option>
              </select>
            </div>
          </div>
          <div className="productfooter">
            <button className="close" onClick={onClose}>
              Close
            </button>
            <button type="submit" onClick={SaveProduct}>
              Submit
            </button>
            <ToastContainer autoClose={1000} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default PopupForm2;
