import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { ToastContainer, toast } from "react-toastify";
import { Button, TextField } from "@mui/material";
import ConfirmationPopUp from "./ConfirmationPopUp.js";

const PreviousQuotationImport = ({
  open,
  onClose,
  storedItemsChecklist,
  setStoredItemsChecklist,
}) => {
  const [searchID, setSearchId] = useState("");
  const [itemData, setItemData] = useState([]);
  const [result, setResult] = useState(false);
  const [importConfirmation, setImportConfirmation] = useState(false);
  const [itemDetails, setItemDetails] = useState();
  const [importQuotationId, setImportQuotationId] = useState("");

  const handleOpenImportConfirmation = (items, id) => {
    setItemDetails(items);
    setImportQuotationId(id);
    setImportConfirmation(true);
  };
  const handleCloseImportConfirmation = () => {
    setImportConfirmation(false);
  };

  const getPrevQuotationData = async () => {
    try {
      const response = await fetch(
        `/quotegen/importQuotation?queryId=${searchID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const { success, quotationDoc } = await response.json();

      if (success) {
        setItemData(quotationDoc);
        setResult(true);
      } else {
        toast.error("No such query Id exist", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
    } catch (error) {
      toast.error("Error loading query Id", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const importItemDetails = (items) => {
    const cachedQuery = window.location.href.split("/")[4];
    if (cachedQuery) {
      const updateCacheData = JSON.parse(
        localStorage.getItem(`queryDetailsData_${cachedQuery}`)
      );

      updateCacheData.itemDetails = items;
      localStorage.setItem(
        `queryDetailsData_${cachedQuery}`,
        JSON.stringify(updateCacheData)
      );
    }
    localStorage.setItem(`selectedItems`, JSON.stringify(items));
    setStoredItemsChecklist(items);
    onClose();
    // window.location.reload();
  };
  return (
    <div>
      <>
        <Dialog
          open={open}
          onClose={onClose}
          PaperProps={{
            component: "form",
            onSubmit: (event) => {
              event.preventDefault();
              getPrevQuotationData();
              // !result ? getPrevQuotationData() : importItemDetails();
            },
            sx: {
              width: "500px",
              maxWidth: "2000px !important",
            },
          }}
        >
          <DialogTitle>Quotation Version(s)</DialogTitle>
          <DialogContent>
            {!result ? (
              <TextField
                autoFocus
                required
                margin="dense"
                id="title"
                name="title"
                label="Enter Query ID"
                type="text"
                fullWidth
                variant="standard"
                value={searchID}
                onChange={(e) => setSearchId(e.target.value)}
              />
            ) : (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {itemData ? (
                  itemData.map((item, index) => (
                    <div
                      key={item.quotationId}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                        justifyContent: "space-between",
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontStyle: "normal",
                      }}
                    >
                      <div>{item.quotationId}</div>
                      <button
                        style={{
                          backgroundColor: "#489A34",
                          color: "white",
                          border: "none",
                          padding: "10px 20px",
                          borderRadius: "3px",
                          cursor: "pointer",
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontStyle: "normal",
                        }}
                        onClick={() =>
                          handleOpenImportConfirmation(
                            item.itemDetails,
                            item.quotationId
                          )
                        }
                      >
                        Import
                      </button>
                    </div>
                  ))
                ) : (
                  <div>No items found</div>
                )}
              </div>
            )}
          </DialogContent>
          {!result && (
            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <Button type="submit">Submit</Button>
            </DialogActions>
          )}
        </Dialog>

        <div>
          {importConfirmation && (
            <ConfirmationPopUp
              show={importConfirmation}
              onClose={handleCloseImportConfirmation}
              onConfirm={() => importItemDetails(itemDetails)}
              close={handleCloseImportConfirmation}
              confirm={() => importItemDetails(itemDetails)}
              title={`Do you like import item details from ${importQuotationId}?`}
              message={"Your current item details will be lost"}
            />
          )}
        </div>
        <ToastContainer autoClose={1000} />
      </>
    </div>
  );
};

export default PreviousQuotationImport;
