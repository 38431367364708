import React, { useState, useEffect } from "react";
import "./BlogCreate.css";
import BlogUploadPopup from "./blogimageupload";

const BlogCreate = () => {
  const [isBlogDataValid, setIsBlogDataValid] = useState(false);
  const [showBlogUploadPopup, setShowBlogUploadPopup] = useState(false);
  const [imageFiles, setImageFiles] = useState({});

  const handleUpdateResult = (titleBlog, selectedFile) => {
    setImageFiles((prevImageFiles) => ({
      ...prevImageFiles,
      [titleBlog]: selectedFile,
    }));
  };

  const [blogData, setBlogData] = useState({
    titleBlog: "",
    bannerImageSource: "",
    publishDate: "",
    readTime: "",
    categoryBlog: [""],
    blogIntro: "",
    blogConclusion: "",
    image: [""],
    subsections: [
      {
        subsectionHeading: "",
        subsectionContent: "",
        subsectionImage: "",
      },
    ],
  });

  const handleBlogDataChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setBlogData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubsectionChange = (index, name, value) => {
    const updatedSubsections = [...blogData.subsections];
    updatedSubsections[index][name] = value;
    setBlogData({
      ...blogData,
      subsections: updatedSubsections,
    });
  };

  const addSubsection = (e,l) => {
    e.preventDefault();
    setBlogData((prevData) => ({
      ...prevData,
      subsections: [
        ...prevData.subsections,
        { subsectionHeading: "", subsectionContent: "", subsectionImage:"" },
      ],
    }));
  };

  const removeSubsection = (index) => {
    setBlogData((prevData) => {
      const updatedSubsections = prevData.subsections.filter(
        (_, i) => i !== index
      );
      return {
        ...prevData,
        subsections: updatedSubsections,
      };
    });
  };

  const handleBlogCategoryChange = (index, value) => {
    const updatedCategories = [...blogData.categoryBlog];
    updatedCategories[index] = value;
    setBlogData({
      ...blogData,
      categoryBlog: updatedCategories,
    });
  };

  const addCat = (e) => {
    e.preventDefault();
    setBlogData((prevData) => ({
      ...prevData,
      categoryBlog: [
        ...prevData.categoryBlog,""
      ],
    }));
  };

  const removeCat = (index) => {
    setBlogData((prevData) => {
      const updatedCategories = [...prevData.categoryBlog];
      updatedCategories.splice(index, 1);
      return {
        ...prevData,
        categoryBlog: updatedCategories,
      };
    });
  };

  const validateBlogData = () => {
    const { titleBlog } = blogData;
    const requiredFields = [titleBlog];

    return requiredFields.every((field) => field.trim() !== "");
  };
  useEffect(() => {
    setIsBlogDataValid(validateBlogData());
  }, [blogData]);

  const handleBlogSubmit = (e) => {
    e.preventDefault();

    if (validateBlogData) {
      // console.log(blogData);
    }
  };

  const postblogdata = async () => {
    const {
      titleBlog,
      blogIntro,
      bannerImageSource,
      publishDate,
      readTime,
      categoryBlog,
      subsections,
      blogConclusion,
      image,
    } = blogData;

    if (!titleBlog || !blogIntro) {
      window.alert("Fill in all required fields.");
    } else {
      try {
        const formData = new FormData();
        formData.append("titleBlog", titleBlog);
        formData.append("blogIntro", blogIntro);
        formData.append("bannerImageSource", bannerImageSource);
        formData.append("publishDate", publishDate);
        formData.append("readTime", readTime);
        formData.append("categoryBlog", categoryBlog);
        formData.append("blogConclusion", blogConclusion);
        formData.append("image", image);
        formData.append("subsections", JSON.stringify(subsections));

        const response = await fetch("/blogcreate", {
          method: "POST",
          body: formData,
        });

        const result = await response.json();
      
        window.alert("blog Created");
      } catch (error) {
        console.error("Error posting blog data:", error);
        window.alert(
          "An error occurred while posting blog data. Please try again."
        );
      }
    }
  };

  return (
    <div className="add-blog-page">
      <h2>Create New Blog</h2>
      <form >
        <p>
          Enter Title of Blog<span style={{ color: "#A80000" }}>*</span>
        </p>
        <input
          type="text"
          name="titleBlog"
          value={blogData.titleBlog}
          onChange={handleBlogDataChange}
          //required
        />
        <p>
          Upload Banner Image<span style={{ color: "#A80000" }}>*</span>
        </p>
        <button onClick={() => setShowBlogUploadPopup(true)}>Upload</button>
        <p>
          Enter Image Source<span style={{ color: "#A80000" }}>*</span>
        </p>
        <input
          type="text"
          name="bannerImageSource"
          style={{ width: "50%" }}
          value={blogData.bannerImageSource}
          onChange={handleBlogDataChange}
        />
        <p>
          Enter Publishing Date (format: 19 January 2023)
          <span style={{ color: "#A80000" }}>*</span>
        </p>
        <input
          type="text"
          name="publishDate"
          style={{ width: "50%" }}
          value={blogData.publishDate}
          onChange={handleBlogDataChange}
          //required
        />
        <p>
          Enter Read Time(in min)<span style={{ color: "#A80000" }}>*</span>
        </p>
        <input
          type="number"
          name="readTime"
          style={{ width: "10%" }}
          value={blogData.readTime}
          onChange={handleBlogDataChange}
        />
        <p>
          Enter Category Name<span style={{ color: "#A80000" }}>*</span>
        </p>
        <div className="add-cat-div">
          {blogData.categoryBlog.map((cat, index) => (
            <div key={index}>
              <input
                type="text"
                name={`categoryName-${index}`}
                style={{ width: "50%" }}
                placeholder="eg: X-Ray Machine "
                value={cat}
                onChange={(e) =>
                  handleBlogCategoryChange(
                    index,
                    e.target.value
                  )
                }
                //required
              />
              {index === blogData.categoryBlog.length - 1 && (
                <button
                  className="add-cat-btn"
                  onClick={(e) => addCat(e, index)}
                >
                  +
                </button>
              )}
              {index !== 0 && (
                <button
                  className="add-cat-btn"
                  style={{
                    backgroundColor: "#A80000",
                    alignItems: "flex-start",
                  }}
                  onClick={() => removeCat(index)}
                >
                  -
                </button>
              )}
            </div>
          ))}
        </div>
        <p>
          Enter Blog Introduction<span style={{ color: "#A80000" }}>*</span>
        </p>
        <textarea
          type="text"
          name="blogIntro"
          rows="5"
          value={blogData.blogIntro}
          onChange={handleBlogDataChange}
        />
        <p style={{ fontSize: "1.6vw" }}>
          Blog Content<span style={{ color: "#A80000" }}>*</span>
        </p>
        {blogData.subsections.map((subsection, index) => (
          <div
            key={index}
            className="blog-content"
            style={{ marginBottom: index === 0 ? "1%" : "2%" }}
          >
            <p style={{ color: "#098BC3", fontSize: "1.5vw" }}>
              Subsection-{index + 1}
            </p>
            <p>Add Subsection Heading</p>
            <input
              type="text"
              name={`subsectionHeading-${index}`}
              value={blogData.subsections[index].subsectionHeading}
              onChange={(e) =>
                handleSubsectionChange(
                  index,
                  "subsectionHeading",
                  e.target.value
                )
              }
            />
            <p>Add Subsection Content</p>
            <textarea
              type="text"
              name={`subsectionContent-${index}`}
              value={blogData.subsections[index].subsectionContent}
              rows="5"
              onChange={(e) =>
                handleSubsectionChange(
                  index,
                  "subsectionContent",
                  e.target.value
                )
              }
            />
            <button
              onClick={() => setShowBlogUploadPopup(true)}
              style={{ backgroundColor: "#489A34" }}
            >
              Upload Image
            </button>
            {index !== 0 && (
              <button
                className="remove-subsec"
                onClick={() => removeSubsection(index)}
              >
                Remove Subsection
              </button>
            )}
          </div>
        ))}
        <button className="add-subsec" onClick={(e) => addSubsection(e, blogData.subsections.length)}>
          Add Subsection
        </button>
        <p>
          Enter Blog Conclusion<span style={{ color: "#A80000" }}>*</span>
        </p>
        <textarea
          type="text"
          name="blogConclusion"
          value={blogData.blogConclusion}
          onChange={handleBlogDataChange}
          rows="5"
        />
        <button
          disabled={!isBlogDataValid}
          style={{ backgroundColor: isBlogDataValid ? "#489A34" : "#C9C9C9" }}
          onClick={() => postblogdata()}
          className="post-blog-btn"
        >
          Post Blog
        </button>
      </form>
      {showBlogUploadPopup && (
          <BlogUploadPopup
            onClose={() => setShowBlogUploadPopup(false)}
            titleBlog={blogData.titleBlog}
            // result={imageFiles}
            onUpdateResult={handleUpdateResult}
          />
        )}
    </div>
  );
};

export default BlogCreate;
