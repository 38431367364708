// import React from "react";
import "./sidebar.css";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../App";
import "./loginAdmin.css";
import axios from "axios";
import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ReactComponent as Arrow } from "../icons/arrow.svg";
import { ReactComponent as Db } from "../icons/Db.svg";
import { ReactComponent as Prod } from "../icons/Prod.svg";
import { ReactComponent as Btn } from "../icons/button-arrow.svg";
import { ReactComponent as Btnclose } from "../icons/btn-arrow-close.svg";
import { ReactComponent as Quote } from "../icons/Quote.svg";
import { ReactComponent as Vendors } from "../icons/Vendors.svg";
import { ReactComponent as Users } from "../icons/Users.svg";
import { ReactComponent as Profile } from "../icons/Profile.svg";

import { ReactComponent as Divide } from "../icons/divider.svg";
import ConfirmationPopUp from "../quoteGeneration/ConfirmationPopUp";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

// import Products from '@mui/icons-material/ShoppingCart';
import Products from "@mui/icons-material/AddShoppingCart";
const Sidebar = () => {
  const { user, setUser } = useContext(UserContext);
  const [showConfirmLogout, setShowConfirmLogout] = useState(false);

  // const google = () => {
  //   window.open("http://localhost:4000/auth/google", "_self");
  // };

  const navigate = useNavigate();

  const handleOpenConfirmLogout = () => {
    setShowConfirmLogout(true);
  };
  const handleCloseConfirmLogout = () => {
    setShowConfirmLogout(false);
  };

  const handleLogout = async () => {
    try {
      localStorage.clear();
      const removeQueryDetailsData = () => {
        for (let key in localStorage) {
          if (key.startsWith("queryDetailsData_")) {
            localStorage.removeItem(key);
          }
        }
      };

      // Call the function to remove keys
      removeQueryDetailsData();
      await axios.get("/logout");
      // console.log(user);
      setUser(null);
      navigate("/");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const openuserpage = () => {
    navigate("/userdetails");
  };

  const openproductpage = () => {
    navigate("/adminproduct");
  };
  const openpicelistpage = () => {
    navigate("/priceList");
  };

  const openquotationpage = () => {
    navigate("/queryDetailsLsq");
  };

  const openvendorpage = () => {
    navigate("/vendorsDetails");
  };

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ left: open });
  };

  return (
    <div className="admin-sidebar">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div className="sidebar-login-details">
          <p className="sidebar-login-name">
            {user
              ? user.username.length > 15
                ? user.username.slice(0, 15)
                : user.username
              : "LOGIN"}
          </p>
          <p className="sidebar-login-id">
            {/* {user && user.email.length > 25
              ? user.email.slice(0, 25)
              : user.email} */}
            {user && user.email}
          </p>
        </div>
        <Profile
          style={{
            marginLeft: "10px",
            marginBottom: "20px",
            marginRight: "10px",
          }}
        />
      </div>

      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <p
          className="sidebar-logout-text"
          onClick={handleOpenConfirmLogout}
          style={{ cursor: "pointer" }}
        >
          Logout
        </p>
        <button className="logoutbtn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleLogout}
            className="logouticon"
            viewBox="0 0 32 39"
            fill="none"
          >
            <path
              d="M3.56982 39H24.4698C25.3981 39 26.2883 38.6312 26.9447 37.9749C27.6011 37.3185 27.9698 36.4283 27.9698 35.5V31.5C27.9698 31.1022 27.8118 30.7206 27.5305 30.4393C27.2492 30.158 26.8676 30 26.4698 30C26.072 30 25.6905 30.158 25.4092 30.4393C25.1279 30.7206 24.9698 31.1022 24.9698 31.5V35.5C24.9698 35.6326 24.9171 35.7598 24.8234 35.8536C24.7296 35.9473 24.6024 36 24.4698 36H3.56982C3.43722 36 3.31004 35.9473 3.21627 35.8536C3.1225 35.7598 3.06982 35.6326 3.06982 35.5V3.5C3.06982 3.36739 3.1225 3.24021 3.21627 3.14645C3.31004 3.05268 3.43722 3 3.56982 3H24.4698C24.6024 3 24.7296 3.05268 24.8234 3.14645C24.9171 3.24021 24.9698 3.36739 24.9698 3.5V7.5C24.9698 7.89782 25.1279 8.27936 25.4092 8.56066C25.6905 8.84196 26.072 9 26.4698 9C26.8676 9 27.2492 8.84196 27.5305 8.56066C27.8118 8.27936 27.9698 7.89782 27.9698 7.5V3.5C27.9698 2.57174 27.6011 1.6815 26.9447 1.02513C26.2883 0.368749 25.3981 0 24.4698 0H3.56982C2.64157 0 1.75133 0.368749 1.09495 1.02513C0.438573 1.6815 0.0698242 2.57174 0.0698242 3.5V35.5C0.0698242 36.4283 0.438573 37.3185 1.09495 37.9749C1.75133 38.6312 2.64157 39 3.56982 39Z"
              fill="#A80000"
            />
            <path
              d="M23.3748 10.3799C23.0864 10.1415 22.7193 10.0196 22.3455 10.0379C21.9718 10.0563 21.6184 10.2137 21.3548 10.4792C21.0911 10.7448 20.9363 11.0993 20.9205 11.4731C20.9048 11.847 21.0294 12.2132 21.2698 12.4999L26.8298 17.9999H10.7198C10.5228 17.9999 10.3278 18.0387 10.1458 18.1141C9.9638 18.1895 9.79844 18.3 9.65916 18.4393C9.51987 18.5786 9.40938 18.7439 9.334 18.9259C9.25862 19.1079 9.21982 19.3029 9.21982 19.4999C9.21982 19.6969 9.25862 19.892 9.334 20.074C9.40938 20.2559 9.51987 20.4213 9.65916 20.5606C9.79844 20.6999 9.9638 20.8104 10.1458 20.8857C10.3278 20.9611 10.5228 20.9999 10.7198 20.9999H26.8298L21.2698 26.4999C21.0025 26.7823 20.8553 27.1575 20.8593 27.5463C20.8633 27.9352 21.0181 28.3073 21.2911 28.5842C21.5641 28.8611 21.934 29.0211 22.3227 29.0306C22.7114 29.0401 23.0887 28.8982 23.3748 28.6349L31.5298 20.5699C31.6712 20.4304 31.7834 20.2642 31.86 20.0809C31.9366 19.8977 31.976 19.701 31.976 19.5024C31.976 19.3038 31.9366 19.1072 31.86 18.9239C31.7834 18.7407 31.6712 18.5745 31.5298 18.4349L23.3748 10.3799Z"
              fill="#A80000"
            />
          </svg>
        </button>
      </div>

      <div style={{ background: "white", width: "100%", height: "1px" }} />

      {user && user.role === "category" && (
        <div className="sidebar-products" onClick={openproductpage}>
          <p>Products</p>
          <Products
            style={{
              cursor: "pointer",
              height: "27px",
              width: "27px",
              margin: "0px",
              marginRight: "10px",
            }}
          />
        </div>
      )}
      <div className="sidebar-quotation" onClick={openquotationpage}>
        <p>Quotation</p>
        <Quote style={{ cursor: "pointer", marginRight: "12px" }} />
      </div>
      {user && user.role === "category" && (
        <div className="sidebar-vendors" onClick={openvendorpage}>
          <p>Vendors</p>
          <Vendors style={{ cursor: "pointer", marginRight: "10px" }} />
        </div>
      )}
      {user && user.role === "superuser" && (
        <div className="sidebar-users" onClick={openuserpage}>
          <p>Users</p>
          <Users style={{ cursor: "pointer", marginRight: "10px" }} />
        </div>
      )}
      {user && user.role === "category" && (
        <div className="sidebar-products" onClick={openpicelistpage}>
          <p>Price List</p>
          <CurrencyRupeeIcon
            style={{
              cursor: "pointer",
              height: "27px",
              width: "27px",
              margin: "0px",
              marginRight: "10px",
            }}
          />
        </div>
      )}

      <div>
        {showConfirmLogout && (
          <ConfirmationPopUp
            show={showConfirmLogout}
            onClose={handleCloseConfirmLogout}
            onConfirm={handleLogout}
            close={handleCloseConfirmLogout}
            confirm={handleLogout}
            title={"Logout"}
            message={"Are you sure you want to log out?"}
          />
        )}
      </div>
    </div>
  );
};

export default Sidebar;
