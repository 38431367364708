import {
  Box,
  IconButton,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  TextField,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  LinearProgress,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import PDFView from "./PDFView.js";

import Toolbar from "@mui/material/Toolbar";

import Typography from "@mui/material/Typography";

import CloseIcon from "@mui/icons-material/Close";

import Slide from "@mui/material/Slide";
import { PDFViewer } from "@react-pdf/renderer";

import React, { useState, useEffect, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { UserContext } from "../App";
import "./quotationCreation.css";
import { ToastContainer, toast } from "react-toastify";
import { ReactComponent as Delete } from "../icons/dustbin.svg";
import RowProduct from "./AddedProductRow.js";
import PaymentTermsTable from "./PaymentTermsTable.js";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import PreviousQuotationImport from "./PreviousQuotationImport";
import ConfirmationPopUp from "./ConfirmationPopUp.js";
import QuoteGeneratedPopup from "./QuoteGeneratedPopup.js";
import cities from "./cities.json";
import CreatableSelect from "react-select/creatable";
import { Helmet } from "react-helmet";

const ManualQuotationDetails = ({
  setProductCount,
  fromGenericData,
  setFromGenericData,
}) => {
  const [loading, setIsloading] = useState(false);
  const { user } = useContext(UserContext);
  const [uploadPdfLoader, setUploadPdfLoader] = useState(false);
  const [openremarkPopUp, setOpenRemarkPopUp] = useState(false);
  const [vendorsList, setVendorsList] = useState([]);
  const [quoteConfirmation, setQuoteConfirmation] = useState(false);

  const storedItemsString = localStorage.getItem("selectedItems");
  const storedItems = storedItemsString ? JSON.parse(storedItemsString) : [];

  const [storedItemsChecklist, setStoredItemsChecklist] = useState(
    storedItems || []
  );
  const [quoteGeneratedPopup, setQuoteGeneratedPopup] = useState(false);
  const [quoteLink, setQuoteLink] = useState("");
  const [leadId, setLeadId] = useState(
    localStorage.getItem("selectedLead")
      ? JSON.parse(localStorage.getItem("selectedLead")).ProspectID
      : ""
  );
  const [deliveryCharges, setDeliveryCharges] = useState(''); // Initialize deliveryCharges state
  const [isDelivery, setisDelivery] = useState(false);

  const handleCheckboxChange = (event) => {
    setisDelivery(event.target.checked);
  };

  const handleInputChange = (event) => {
    setDeliveryCharges(event.target.value);
  };
  useEffect(() => {
    if (!isDelivery) {
      setDeliveryCharges('0');
    }
  }, [isDelivery]);

  const [opportunityId, setOpportunityId] = useState(
    localStorage.getItem("selectedLead")
      ? JSON.parse(localStorage.getItem("selectedLead")).opportunityId
      : ""
  );
  const selectedLeadData = localStorage.getItem("selectedLead")
    ? JSON.parse(localStorage.getItem("selectedLead"))
    : {};
  const [selectedLead, setSelectedLead] = useState(selectedLeadData);
  const [formData, setFormData] = useState({
    pocName: selectedLeadData.FirstName ? `${selectedLeadData.FirstName}` : "",
    phoneNo: selectedLeadData.Phone ? `${selectedLeadData.Phone}` : "",
    pocEmail: selectedLeadData.EmailAddress
      ? `${selectedLeadData.EmailAddress}`
      : "",
    establishMentName: selectedLeadData.mx_Establishment_Name
      ? `${selectedLeadData.mx_Establishment_Name}`
      : "",
    city: selectedLeadData.mx_New_City ? `${selectedLeadData.mx_New_City}` : "",
  });
  const [reopenToCategoryConfirmation, setReopenToCategoryConfirmation] =
    useState(false);
  const [sendToPricingConfirmation, setSendToPricingConfirmation] =
    useState(false);
  const [remark, setRemark] = useState("");

  const [openRow, setOpenRow] = useState(null);
  const handleRowClick = (rowSku) => {
    setOpenRow((prevRowSku) => (prevRowSku === rowSku ? null : rowSku));
  };

  const [amount, setAmount] = useState(
    storedItemsChecklist.reduce((total, row) => {
      const amount = row.Amount !== "" ? parseFloat(row.Amount) : 0;
      // Add the valid amount to the total
      return total + amount;
    }, 0)
  );

  // const [cities, setCities] = useState(citiesOptions.map((city) => city.name));
  // const [cities, setCities] = useState(citiesOptions);
  // const cities = citiesOptions.map((city) => ({
  //   id: city.id,
  //   name: city.name,
  //   state: city.state,
  // }));

  // const [searchTerm, setSearchTerm] = useState("");
  // const [cities, setCities] = useState(
  //   citiesOptions.map((city) => ({
  //     ...city,
  //     key: city.id, // Assuming `id` is the unique identifier for each city
  //   }))
  // );

  // useEffect(() => {
  //   fetchCities();
  // }, []);

  // const fetchCities = () => {
  //   fetch("cities.json")
  //     .then((response) => response.json())
  //     .then((data) => setCities(data))
  //     .catch((error) => console.error("Error fetching cities:", error));
  // };

  // const handleSearchChange = (event) => {
  //   setSearchTerm(event.target.value);
  // };

  // const filteredCities = cities.filter((city) =>
  //   city.name.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  // const [cities, setCities] = useState([]);

  // useEffect(() => {
  //   // Load data from data.json
  //   fetch("./cities.json")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // Extract cities from data
  //       const cities = data.map((item) => item.name);
  //       setCities(cities);
  //     })
  //     .catch((error) => console.error("Error loading data:", error));
  // }, []);

  // console.log(window.location.href.split("/")[4], window.location.href);

  const [totalCostPrice, setTotalCostPrice] = useState(
    storedItemsChecklist.reduce(
      (total, row) => total + parseFloat(row.quantity * row.cpegst),
      0
    )
  );
  const [totalspegstPrice, setTotalspegstPrice] = useState(
    storedItemsChecklist.reduce(
      (total, row) => total + parseFloat(row.quantity * row.spegst),
      0
    )
  );
  const [totalspigstPrice, setTotalspigstPrice] = useState(
    storedItemsChecklist.reduce(
      (total, row) => total + parseFloat(row.quantity * row.spigst),
      0
    )
  );
  const [totalquantity, setTotalquantity] = useState(
    storedItemsChecklist.reduce(
      (total, row) => total + parseFloat(row.quantity),
      0
    )
  );

  const [totalMarginByValue, setTotalMarginByValue] = useState(
    storedItemsChecklist.reduce(
      (total, row) =>
        total + parseFloat(row.quantity * (row.spegst - row.cpegst)),
      0
    )
  );

  const [selectedDiscountType, setSelectedDiscountType] = useState("None");
  const [discountInPercent, setDiscountInPercent] = useState("0");
  const [discountInAmount, setDiscountInAmount] = useState("0");
  const [discountedTotalAmount, setDiscountedTotalAmount] = useState(
    `${((100 - discountInPercent) * amount) / 100}`
  );

  const [attachmentTitle, setAttachmentTitle] = useState("");
  const [attachmentFile, setAttachmentFile] = useState("");
  const storedAttachments = localStorage.getItem("manualQuotationAttachments");
  const storedUploadedAttachments = storedAttachments
    ? JSON.parse(storedAttachments)
    : [];
  const [uploadedAttachments, setUploadedAttachments] = useState(
    storedUploadedAttachments || []
  );
  const [paymentTermsCondition, setPaymentTermsCondition] = useState(true);
  const [phoneerr, setPhoneErr] = useState(false);
  const [emailerr, setEmailErr] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [warrantyDuration, setWarrantyDuration] = useState([]);

  useEffect(() => {
    getVendors();
    getWarranties();
  }, []);

  const getWarranties = async () => {
    const response = await fetch("/quotegen/getWarranties");
    const data = await response.json();
    setWarrantyDuration(data.warranties);
  };

  // const handleChangeCity = (event) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     ["city"]: !event?"":event.value,
  //   }));
  //   selectedLeadData["mx_New_City"]=!event?"":event.value;
  //   localStorage.setItem("selectedLead", JSON.stringify(selectedLeadData));
  // }
  const handleChange = async (event) => {
    const { id, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    if (id === "pocEmail" && value && !value.includes("@")) {
      setEmailErr(true);
    } else if (id === "pocEmail" && value && value.includes("@")) {
      setEmailErr(false);
    }
    if (id === "phoneNo" && value && value.length < 10) {
      setPhoneErr(true);
    } else if (id === "phoneNo" && value && value.length >= 10) {
      setPhoneErr(false);
    }
    const id_map = {
      pocName: "FirstName",
      phoneNo: "Phone",
      establishMentName: "mx_Establishment_Name",
      pocEmail: "EmailAddress",
      city: "mx_New_City",
    };

    selectedLeadData[id_map[id]] = value;
    localStorage.setItem("selectedLead", JSON.stringify(selectedLeadData));
  };

  const navigate = useNavigate();

  const priceListHeader = [
    "Product Details",
    "Partner Name",
    "Warranty",
    "Cost Per Unit(Excl GST)",
    "GST%",
    "Market Selling Price(Excl GST)",
    "Minimum Selling Price(Excl GST)",
    "MRP",
    "Margin(in %)",
    "Selling Price(Excl GST)",
    "Selling Price(Incl GST)",
    "Quantity",
    "Amount",
    "Delete from Cart",
  ];

  const citiesOptions = cities.map((city) => ({
    id: city.id,
    value: city.name,
    label: city.name,
  }));

  const filteredHeaders = priceListHeader.filter((header) => {
    return user && (user.role === "superuser" || user.role === "pricing")
      ? header !== "Partner Name"
      : user && user.role === "sales"
      ? header !== "Cost Per Unit(Excl GST)" &&
        header !== "Margin(in %)" &&
        header !== "Partner Name"
      : header;
  });

  const warrantyDurationOptions = warrantyDuration.map((duration) => ({
    value: duration,
    label: duration,
  }));

  const [paymentrows, setPaymentRows] = useState([
    {
      paymentTermstype: "Advance",
      duration: "",
      paymentTermsAmount: amount,
      paymentTermsPercentage: 100,
    },
    {
      paymentTermstype: "On Delivery",
      duration: "",
      paymentTermsAmount: 0,
      paymentTermsPercentage: 0,
    },
    {
      paymentTermstype: "On Installation",
      duration: "",
      paymentTermsAmount: 0,
      paymentTermsPercentage: 0,
    },
    {
      paymentTermstype: "Credit(In Days)",
      duration: "",
      paymentTermsAmount: 0,
      paymentTermsPercentage: 0,
    },
    {
      paymentTermstype: "EMI(In Months)",
      duration: "",
      paymentTermsAmount: 0,
      paymentTermsPercentage: 0,
    },
  ]);

  const handleOpenQuotePopup = () => {
    setQuoteGeneratedPopup(true);
  };

  const handleCloseQuotePopup = () => {
    navigate("/queryDetailsLsq");
    setQuoteGeneratedPopup(false);
  };

  const createQuotation = async () => {
    if (
      !formData.pocName ||
      !formData.phoneNo ||
      formData.phoneNo.length < 10 ||
      !formData.establishMentName ||
      !formData.city
    ) {
      toast.error(`Fill All Fields`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (
      storedItemsChecklist.some(
        (item) => item.cpegst === "" || Number(item.cpegst) < 0
      )
    ) {
      toast.error("Enter cost price of all the products", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (storedItemsChecklist.some((item) => item.warranty_duration === "")) {
      toast.error("Select Warranty in all the products", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    // if (storedItemsChecklist.some((item) => item.partner_name === "")) {
    //   toast.error("Select Partner in all the products", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   return;
    // }

    if (
      storedItemsChecklist.some(
        (item) =>
          item.quantity === "" ||
          Number(item.quantity) < 1 ||
          !Number.isInteger(Number(item.quantity))
      )
    ) {
      toast.error("Enter Valid quantity", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    let updatedPaymentTerms;
    if (user && (user.role === "category" || user.role === "sales")) {
      updatedPaymentTerms = paymentrows.map((prevRow) =>
        prevRow.paymentTermsPercentage !== 0
          ? {
              ...prevRow,
              paymentTermsAmount:
                (amount * prevRow.paymentTermsPercentage) / 100,
              paymentTermsPercentage: prevRow.paymentTermsPercentage,
            }
          : prevRow
      );
    } else {
      updatedPaymentTerms = paymentrows.map((prevRow) =>
        prevRow.paymentTermsPercentage !== 0
          ? {
              ...prevRow,
              paymentTermsAmount:
                (discountedTotalAmount * prevRow.paymentTermsPercentage) / 100,
              paymentTermsPercentage: prevRow.paymentTermsPercentage,
            }
          : prevRow
      );
    }

    const payload = {
      pocName: formData.pocName,
      phonePoc: formData.phoneNo,
      establishmentname: formData.establishMentName,
      pocEmail: formData.pocEmail,
      city: formData.city,
      email: user.email,
      leadId: leadId,
      opportunityId: opportunityId,
    };
    let retryCount = 0;
    const maxRetries = 2;
    let quotationResponse;

    let fromCategory;
    if (user && user.role === "category") {
      fromCategory = true;
    } else {
      fromCategory = false;
    }

    try {
      setIsloading(true);

      const response2 = await fetch(`/quotegen`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          queryDetails: payload,
          updatedStatus: "alreadyShared",
          storedItemsChecklist: storedItemsChecklist,
          fromGenericData: fromGenericData,
          fromCategory: fromCategory,
          paymentTerms: updatedPaymentTerms,
          totalAmount: amount,
          discountInRupees: discountInAmount,
          discountInPercent: discountInPercent,
          discountedAmount: discountedTotalAmount,
          attachmentDetails: uploadedAttachments,
        }),
      });
      const { success, queryId } = await response2.json();

      const response = await fetch(`/quotegen/createManualQuotation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          queryId: queryId,
          queryDetailsData: payload,
          storedItemsChecklist: storedItemsChecklist,
          fromGenericData: fromGenericData,
          fromCategory: false,
          paymentTerms: paymentrows,
          totalAmount: amount,
          discountInRupees: discountInAmount,
          discountInPercent: discountInPercent,
          discountedAmount: discountedTotalAmount,
          attachmentDetails: uploadedAttachments,
          isChecked: isChecked,
          deliveryCharges: deliveryCharges,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const { success: successQuotation, encryptedText } =
        await response.json();

      if (successQuotation && success) {
        localStorage.removeItem("selectedItems");
        localStorage.removeItem("manualQuotationAttachments");
        localStorage.removeItem("selectedLead");
        setFromGenericData(false);

        const quotationLink = window.location.href.includes("admin.zoplar.com")
          ? `quote.zoplar.com`
          : `testquote.zoplar.com`;

        handleOpenQuotePopup();
        setQuoteLink(`https://${quotationLink}/quotation/${encryptedText}`);

        // window.open(
        //   `http://quote.zoplar.com/quotation/${encryptedText}`,
        //   "_blank"
        // );
        setStoredItemsChecklist([]);
        setProductCount(0);
        // navigate("/queryDetailsLsq");
      } else {
        console.log("Try Again");
      }
    } catch (error) {
      toast.error("Error Uploading Attachments", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setUploadPdfLoader(false);
      setIsloading(false);
      setQuoteConfirmation(false);
    }
  };

  const handleProductDelete = (row) => {
    const updatedItems = storedItemsChecklist.filter(
      (item) => item.sku !== row.sku
    );
    const totalAmountToUpdate = updatedItems.reduce(
      (total, row) => total + parseFloat(row.Amount),
      0
    );
    setAmount(totalAmountToUpdate);
    setTotalCostPrice(
      updatedItems.reduce(
        (total, row) => total + parseFloat(row.quantity * row.cpegst),
        0
      )
    );
    setTotalspegstPrice(
      updatedItems.reduce(
        (total, row) => total + parseFloat(row.quantity * row.spegst),
        0
      )
    );
    setTotalspigstPrice(
      updatedItems.reduce(
        (total, row) => total + parseFloat(row.quantity * row.spigst),
        0
      )
    );
    setTotalquantity(
      updatedItems.reduce((total, row) => total + parseFloat(row.quantity), 0)
    );
    setTotalMarginByValue(
      updatedItems.reduce(
        (total, row) =>
          total + parseFloat(row.quantity * (row.spegst - row.cpegst)),
        0
      )
    );

    setDiscountInAmount(
      `${
        (discountInPercent *
          updatedItems.reduce(
            (total, row) => total + parseFloat(row.Amount),
            0
          )) /
        100
      }`
    );
    const updatedDiscountAmount = `${
      ((100 - discountInPercent) *
        updatedItems.reduce(
          (total, row) => total + parseFloat(row.Amount),
          0
        )) /
      100
    }`;
    setDiscountedTotalAmount(updatedDiscountAmount);

    const updatedPaymentTerms = paymentrows.map((prevRow) =>
      prevRow.paymentTermsPercentage !== 0
        ? {
            ...prevRow,
            paymentTermsAmount:
              (updatedDiscountAmount * prevRow.paymentTermsPercentage) / 100,
            paymentTermsPercentage: prevRow.paymentTermsPercentage,
          }
        : prevRow
    );

    setPaymentRows(updatedPaymentTerms);

    setStoredItemsChecklist(updatedItems);
    setProductCount(updatedItems.length);
    localStorage.setItem("selectedItems", JSON.stringify(updatedItems));
  };
  const [showPrevDialog, setshowPrevDialog] = useState(false);
  const [quotations, setQuotations] = useState([]);
  const handleopenpreview = () => {
    updateQuotations();

    setshowPrevDialog(true);
  };
  const updateQuotations = () => {
    const queryDetailsData = {
      pocName: formData.pocName,
      phonePoc: formData.phoneNo,
      establishmentname: formData.establishMentName,
      pocEmail: formData.pocEmail,
      city: formData.city,
      email: user.email,
    };

    setQuotations([
      {
        queryDetails: queryDetailsData,
        itemDetails: storedItemsChecklist,

        paymentTerms: paymentrows,

        totalAmount: amount,

        discountInRupees: discountInAmount,

        discountInPercent: discountInPercent,

        discountedAmount: discountedTotalAmount,

        attachmentDetails: uploadedAttachments,
        isPaymentTable: isChecked,
        deliveryCharges: deliveryCharges,
      },
    ]);
  };
  const handleValueEdited = (row, updatedKey, updatedValue) => {
    const updatedItems = storedItemsChecklist.map((item) => {
      if (item.sku === row.sku) {
        // Update the specific key
        if (updatedKey === "spegst") {
          item.margin = `${(1 - item.cpegst / updatedValue) * 100}%`;
          item.spegst = updatedValue;
        } else {
          item[updatedKey] = updatedValue;
          item.spegst = item.cpegst / (1 - parseFloat(item.margin) / 100);
        }

        if (updatedKey === "description") {
          toast.success(`Description Edited for SKU ${row.sku}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        item.Amount =
          (item.cpegst * item.quantity * (1 + parseFloat(item.gst) / 100)) /
          (1 - parseFloat(item.margin) / 100);

        item.spigst =
          (item.cpegst * (1 + parseFloat(item.gst) / 100)) /
          (1 - parseFloat(item.margin) / 100);
      }
      return item;
    });

    localStorage.setItem("selectedItems", JSON.stringify(updatedItems));
    setAmount(
      updatedItems.reduce((total, row) => total + parseFloat(row.Amount), 0)
    );
    setTotalCostPrice(
      updatedItems.reduce(
        (total, row) => total + parseFloat(row.quantity * row.cpegst),
        0
      )
    );
    setTotalspegstPrice(
      updatedItems.reduce(
        (total, row) => total + parseFloat(row.quantity * row.spegst),
        0
      )
    );
    setTotalspigstPrice(
      updatedItems.reduce(
        (total, row) => total + parseFloat(row.quantity * row.spigst),
        0
      )
    );
    setTotalquantity(
      updatedItems.reduce((total, row) => total + parseFloat(row.quantity), 0)
    );
    setTotalMarginByValue(
      updatedItems.reduce(
        (total, row) =>
          total + parseFloat(row.quantity * (row.spegst - row.cpegst)),
        0
      )
    );

    setDiscountInAmount(
      `${
        (discountInPercent *
          updatedItems.reduce(
            (total, row) => total + parseFloat(row.Amount),
            0
          )) /
        100
      }`
    );

    const updatedDiscountedAmount = `${
      ((100 - discountInPercent) *
        updatedItems.reduce(
          (total, row) => total + parseFloat(row.Amount),
          0
        )) /
      100
    }`;
    setDiscountedTotalAmount(updatedDiscountedAmount);
    const updatedPaymentTerms = paymentrows.map((prevRow) =>
      prevRow.paymentTermsPercentage !== 0
        ? {
            ...prevRow,
            paymentTermsAmount:
              (updatedDiscountedAmount * prevRow.paymentTermsPercentage) / 100,
            paymentTermsPercentage: prevRow.paymentTermsPercentage,
          }
        : prevRow
    );

    setPaymentRows(updatedPaymentTerms);
  };

  const handleSendToPricing = async () => {
    if (
      !formData.pocName ||
      !formData.phoneNo ||
      formData.phoneNo.length < 10 ||
      !formData.establishMentName ||
      !formData.city
    ) {
      toast.error(`Fill All Fields`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (storedItemsChecklist.some((item) => item.cpegst === "")) {
      toast.error("Enter cost price of all the products", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (storedItemsChecklist.some((item) => item.warranty_duration === "")) {
      toast.error("Select Warranty in all the products", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (storedItemsChecklist.some((item) => item.partner_name === "")) {
      toast.error("Select Partner in all the products", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (
      storedItemsChecklist.some(
        (item) =>
          item.quantity === "" ||
          Number(item.quantity) < 1 ||
          !Number.isInteger(Number(item.quantity))
      )
    ) {
      toast.error("Enter Valid quantity", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const payload = {
      pocName: formData.pocName,
      phonePoc: formData.phoneNo,
      establishmentname: formData.establishMentName,
      pocEmail: formData.pocEmail,
      city: formData.city,
      email: user.email,
    };
    const newrow = paymentrows.map((prevRow) =>
      prevRow.paymentTermsPercentage !== 0
        ? {
            ...prevRow,
            paymentTermsAmount: (amount * prevRow.paymentTermsPercentage) / 100,
            paymentTermsPercentage: prevRow.paymentTermsPercentage,
          }
        : prevRow
    );
    try {
      setIsloading(true);
      const response = await fetch(`/quotegen`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          queryDetails: payload,
          updatedStatus: "pendingOnPricing",
          storedItemsChecklist: storedItemsChecklist,
          fromGenericData: fromGenericData,
          fromCategory: true,
          paymentTerms: newrow,
          totalAmount: amount,
          discountInRupees: discountInAmount,
          discountInPercent: discountInPercent,
          discountedAmount: discountedTotalAmount,
          attachmentDetails: uploadedAttachments,
          sendToPricing: true,
        }),
      });
      const { success } = await response.json();

      if (success) {
        localStorage.removeItem("selectedItems");
        localStorage.removeItem("manualQuotationAttachments");
        localStorage.removeItem("selectedLead");
        setFromGenericData(false);
        setStoredItemsChecklist([]);
        setProductCount(0);
        navigate("/queryDetailsLsq");
      } else {
        console.log("Try Again");
      }
    } catch (error) {
      toast.error("Error Uploading PDF", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setUploadPdfLoader(false);
      setIsloading(false);
      setSendToPricingConfirmation(false);
    }
  };

  const handleOpenRemarkPopUp = () => {
    setOpenRemarkPopUp(true);
  };

  const handleSendToCategory = async (remarks) => {
    if (
      !formData.pocName ||
      !formData.phoneNo ||
      formData.phoneNo.length < 10 ||
      !formData.establishMentName ||
      !formData.city
    ) {
      toast.error(`Fill All Fields`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const payload = {
      pocName: formData.pocName,
      phonePoc: formData.phoneNo,
      establishmentname: formData.establishMentName,
      pocEmail: formData.pocEmail,
      city: formData.city,
      email: user.email,
    };
    const newrow = paymentrows.map((prevRow) =>
      prevRow.paymentTermsPercentage !== 0
        ? {
            ...prevRow,
            paymentTermsAmount:
              (discountedTotalAmount * prevRow.paymentTermsPercentage) / 100,
            paymentTermsPercentage: prevRow.paymentTermsPercentage,
          }
        : prevRow
    );

    try {
      setIsloading(true);

      const response = await fetch(`/quotegen`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          queryDetails: payload,
          updatedStatus: "pendingOnCategory",
          storedItemsChecklist: storedItemsChecklist,
          fromGenericData: fromGenericData,
          fromCategory: true,
          paymentTerms: newrow,
          totalAmount: amount,
          discountInRupees: discountInAmount,
          discountInPercent: discountInPercent,
          discountedAmount: discountedTotalAmount,
          attachmentDetails: uploadedAttachments,
          remarksForCategory: remarks,
          reopenToCategory: true,
        }),
      });
      const { success } = await response.json();

      if (success) {
        localStorage.removeItem("selectedItems");
        localStorage.removeItem("manualQuotationAttachments");
        localStorage.removeItem("selectedLead");
        setFromGenericData(false);
        setStoredItemsChecklist([]);
        setProductCount(0);
        navigate("/queryDetailsLsq");
      } else {
        console.log("Try Again");
      }
    } catch (error) {
      toast.error("Error Uploading PDF", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setUploadPdfLoader(false);
      setIsloading(false);
    }
  };

  const getVendors = async () => {
    const response = await fetch(`/quotegen/vendors`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const { success, data: vendorData } = await response.json();

    if (success) {
      const vendorNamesArray = vendorData.map((vendor) => ({
        value: vendor.name,
        label: vendor.name,
        prospect_id: vendor.prospect_id,
      }));
      setVendorsList(vendorNamesArray);
    }
  };

  const handleChangeDiscountAmount = (discountType) => {
    if (discountType === "amount") {
      if (discountInAmount > amount) {
        toast.error("discounted amount greater than amount", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setDiscountedTotalAmount(`${amount}`);
        setDiscountInPercent(`0`);
        setDiscountInAmount(`0`);
        return;
      }
      setDiscountedTotalAmount(`${amount - discountInAmount}`);
      setDiscountInPercent(`${(discountInAmount / amount) * 100}`);
    }
    if (discountType === "percent") {
      if (discountInPercent > 100) {
        toast.error("discounted percentage greater than 100", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setDiscountInPercent(`0`);
        setDiscountedTotalAmount(`${amount}`);
        setDiscountInAmount(`0`);
        return;
      }
      setDiscountedTotalAmount(`${((100 - discountInPercent) * amount) / 100}`);
      setDiscountInAmount(`${(discountInPercent * amount) / 100}`);
    }
  };

  const handleSubmitAttachment = async (e) => {
    if (!attachmentTitle) {
      alert("Please provide a title.");
      return;
    }

    if (!attachmentFile) {
      alert("Please select a file.");
      return;
    }

    if (attachmentFile.size > 10 * 1024 * 1024) {
      alert("File size exceeds 10MB limit.");
      return;
    }

    let retryCount = 0;
    const maxRetries = 2;
    let quotationResponse;
    setUploadPdfLoader(true);
    while (retryCount <= maxRetries) {
      try {
        const formData = new FormData();
        formData.append("file", attachmentFile);
        formData.append("title", attachmentTitle);
        const responseAttachment = await fetch("/quotegen/uploadAttachments", {
          method: "POST",
          body: formData,
        });

        quotationResponse = await responseAttachment.json();
        if (quotationResponse.success) {
          setUploadPdfLoader(false);
          setUploadedAttachments((prevAttachments) => [
            ...prevAttachments,
            quotationResponse.attachmentDetails,
          ]);

          const getDetails =
            JSON.parse(localStorage.getItem(`manualQuotationAttachments`)) ||
            [];
          getDetails.push(quotationResponse.attachmentDetails);
          localStorage.setItem(
            `manualQuotationAttachments`,
            JSON.stringify(getDetails)
          );

          toast.success("Attachment Uploaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
          const chooseFileAttachment = document.getElementById(
            "choose-file-attachment"
          );
          chooseFileAttachment.value = null;
          break;
        }
        retryCount++;
      } catch (error) {
        console.error("Error uploading attachments:", error);
        if (retryCount <= maxRetries) {
          retryCount++;
          continue;
        } else {
          toast.error("Error Uploading Attachments", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } finally {
        setUploadPdfLoader(false);
      }
    }

    setAttachmentTitle("");
    setAttachmentFile("");
  };

  const handleDeleteAttachment = (selectedLink) => {
    const getDetails = JSON.parse(
      localStorage.getItem(`manualQuotationAttachments`)
    );

    const updatedDetails = getDetails.filter(
      (deletedAttachment) => deletedAttachment.attachmentLink != selectedLink
    );
    localStorage.setItem(
      `manualQuotationAttachments`,
      JSON.stringify(updatedDetails)
    );

    setUploadedAttachments(
      uploadedAttachments.filter(
        (uploadedAttachment) =>
          uploadedAttachment.attachmentLink !== selectedLink
      )
    );
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(storedItemsChecklist);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setStoredItemsChecklist(items);

    let newArray = [];
    items.map((item) => {
      newArray.push(item);
    });

    localStorage.setItem("selectedItems", JSON.stringify(newArray));
  };

  let widthMap = {
    "Product Details": "180px",
    "Partner Name": "90px",
    Warranty: "90px",
    "Cost Per Unit(Excl GST)": "110px",
    "GST%": "45px",
    "Market Selling Price(Excl GST)": "80px",
    "Minimum Selling Price(Excl GST)": "80px",
    MRP: "80px",
    "Margin(in %)": "70px",
    "Selling Price(Excl GST)": "110px",
    "Selling Price(Incl GST)": "80px",
    Quantity: "80px",
    Amount: "80px",
    "Delete from Quote": "50px",
    "Delete from Cart": "50px",
  };

  if (user && user.role === "sales") {
    widthMap = {
      "Product Details": "180px",
      "Partner Name": "90px",
      Warranty: "90px",
      "Cost Per Unit(Excl GST)": "110px",
      "GST%": "45px",
      "Market Selling Price(Excl GST)": "80px",
      "Minimum Selling Price(Excl GST)": "80px",
      MRP: "80px",
      "Margin(in %)": "70px",
      "Selling Price(Excl GST)": "110px",
      "Selling Price(Incl GST)": "80px",
      Quantity: "80px",
      Amount: "80px",
      "Delete from Quote": "128px",
      "Delete from Cart": "128px",
    };
  }

  const [importPopUp, setImportPopUp] = useState(false);
  const handleOpenImportQuotationPopUp = () => {
    setImportPopUp(true);
  };

  const handleQuoteConfirmationOpen = () => {
    setQuoteConfirmation(true);
  };

  const handleQuoteConfirmationClose = () => {
    setQuoteConfirmation(false);
  };

  const handleSendToPricingOpen = () => {
    setSendToPricingConfirmation(true);
  };

  const handleSendToPricingClose = () => {
    setSendToPricingConfirmation(false);
  };

  const handleReopenToCategoryOpen = (remark) => {
    setReopenToCategoryConfirmation(true);
    setRemark(remark);
  };

  const handleReopenToCategoryClose = () => {
    setReopenToCategoryConfirmation(false);
  };

  const handleDivClick = () => {
    setIsChecked(!isChecked);
  };
  useEffect(() => {
    handleChangeDiscountAmount("percent");
  }, [discountInPercent]);
  useEffect(() => {
    handleChangeDiscountAmount("amount");
  }, [discountInAmount]);

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Self Quotation</title>
          </Helmet>
          <Button
            size="large"
            variant="contained"
            sx={{
              marginLeft: "1%",
              flexBasis: "15%",
              height: "50px",
              background: "#489A34",
              "&:hover": {
                background: "#327B28",
              },
              position: "absolute",
              right: "10%",
              top: "0px",
            }}
            onClick={() => handleOpenImportQuotationPopUp()}
          >
            Import
          </Button>
          <Box
            sx={{
              display: "block",
              width: "100%",
              alignItems: "flex-start",
              "& > *:not(:last-child)": {
                marginBottom: "15px",
                marginRight: "15px",
              },
              marginBottom: "50px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="POC Name*"
                  id="pocName"
                  type="text"
                  onChange={handleChange}
                  value={formData.pocName}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Phone Number*"
                  id="phoneNo"
                  type="text"
                  onChange={handleChange}
                  value={formData.phoneNo}
                  disabled={user && user.role === "sales"}
                  error={phoneerr}
                  helperText={"Phone No Should be of 10 digits"}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Email"
                  id="pocEmail"
                  type="text"
                  onChange={handleChange}
                  value={formData.pocEmail}
                  // error={emailerr}
                  // helperText={"Email should contain @"}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Establishment Name*"
                  id="establishMentName"
                  type="text"
                  onChange={handleChange}
                  value={formData.establishMentName}
                />
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  textAlign: "left",
                }}
              >
                <TextField
                  fullWidth
                  //bla bla b
                  label="City*"
                  id="city"
                  type="text"
                  onChange={handleChange}
                  value={formData.city}
                />
                {/* <CreatableSelect
                  isClearable
                  options={citiesOptions}
                  placeholder="Select/Enter City*"
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      paddingTop: "6px",
                      paddingLeft: "5px",
                      paddingRight: "9px",
                      paddingBottom: "6px",
                    }),
                  }}
                  onChange={handleChangeCity}
                /> */}
              </Grid>
            </Grid>
          </Box>

          <TableContainer component={Paper} className="custom-scrollbar">
            <Table>
              <TableHead>
                {/* 5390c2 */}
                <TableRow sx={{ backgroundColor: "#5390c2" }}>
                  <TableCell sx={{ width: "35px", minWidth: "35px" }} />
                  {filteredHeaders.map((priceL) => (
                    <TableCell
                      key={priceL}
                      align="center"
                      sx={{
                        color: "#ffffff",
                        fontFamily: "Inter-Bold, Helvetica",
                        fontSize: "15px",
                        fontWeight: "700",
                        width: widthMap[priceL],
                        minWidth: widthMap[priceL],
                      }}
                    >
                      {priceL}
                    </TableCell>
                  ))}
                  <TableCell
                    style={{
                      padding: "1px",
                    }}
                  />
                </TableRow>
              </TableHead>
            </Table>

            <Table aria-label="collapsible table">
              <TableBody>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {storedItemsChecklist &&
                          storedItemsChecklist.map((row, index) => (
                            <RowProduct
                              key={row._id}
                              open={openRow === row.sku}
                              onRowClick={() => handleRowClick(row.sku)}
                              row={row}
                              handleProductDelete={() =>
                                handleProductDelete(row)
                              }
                              handleValueEdited={(key, value) =>
                                handleValueEdited(row, key, value)
                              }
                              // handleChangeVendor={() => handleChangeVendor(row.sku)}
                              vendorsList={vendorsList}
                              warrantyDurationOptions={warrantyDurationOptions}
                              index={index}
                              setStoredItemsChecklist={setStoredItemsChecklist}
                              widthMap={widthMap}
                            />
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </TableBody>
            </Table>

            <Table>
              <TableBody>
                <TableRow style={{ background: "#EAEAEA" }}>
                  <TableCell
                    sx={{ width: "35px", minWidth: "35px" }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      width: widthMap["Product Details"],
                      minWidth: widthMap["Product Details"],
                    }}
                  >
                    <div
                      style={{
                        marginRight: "10px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <strong>Total :</strong>
                    </div>
                  </TableCell>
                  {user && user.role === "category" && (
                    <TableCell
                      sx={{
                        width: widthMap["Partner Name"],
                        minWidth: widthMap["Partner Name"],
                      }}
                    ></TableCell>
                  )}

                  <TableCell
                    sx={{
                      width: widthMap["Warranty"],
                      minWidth: widthMap["Warranty"],
                    }}
                  ></TableCell>

                  {user && user.role !== "sales" && (
                    <TableCell
                      sx={{
                        width: widthMap["Cost Per Unit(Excl GST)"],
                        minWidth: widthMap["Cost Per Unit(Excl GST)"],
                      }}
                    >
                      <div
                        style={{
                          marginRight: "20px",
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        {totalCostPrice > 100
                          ? parseFloat(totalCostPrice).toFixed(0)
                          : totalCostPrice < 100 && totalCostPrice >= 10
                          ? parseFloat(totalCostPrice).toFixed(1)
                          : parseFloat(totalCostPrice).toFixed(2)}
                      </div>
                    </TableCell>
                  )}

                  <TableCell
                    sx={{ width: widthMap["GST%"], minWidth: widthMap["GST%"] }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      width: widthMap["Market Selling Price(Excl GST)"],
                      minWidth: widthMap["Market Selling Price(Excl GST)"],
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      width: widthMap["Minimum Selling Price(Excl GST)"],
                      minWidth: widthMap["Minimum Selling Price(Excl GST)"],
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{ width: widthMap["MRP"], minWidth: widthMap["MRP"] }}
                  ></TableCell>

                  {user && user.role !== "sales" && (
                    <TableCell
                      sx={{
                        width: widthMap["Margin(in %)"],
                        minWidth: widthMap["Margin(in %)"],
                      }}
                    >
                      <div
                        style={{
                          marginRight: "20px",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        {totalspegstPrice &&
                          totalspegstPrice !== "" &&
                          (
                            ((totalspegstPrice - totalCostPrice) * 100) /
                            totalspegstPrice
                          ).toFixed(2)}
                        %
                      </div>
                    </TableCell>
                  )}

                  <TableCell
                    sx={{
                      width: widthMap["Selling Price(Excl GST)"],
                      minWidth: widthMap["Selling Price(Excl GST)"],
                    }}
                  >
                    <div
                      style={{
                        marginRight: "20px",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      {totalspegstPrice > 100
                        ? parseFloat(totalspegstPrice).toFixed(0)
                        : totalspegstPrice < 100 && totalspegstPrice >= 10
                        ? parseFloat(totalspegstPrice).toFixed(1)
                        : parseFloat(totalspegstPrice).toFixed(2)}
                    </div>
                  </TableCell>

                  <TableCell
                    sx={{
                      width: widthMap["Selling Price(Incl GST)"],
                      minWidth: widthMap["Selling Price(Incl GST)"],
                    }}
                  >
                    <div
                      style={{
                        marginRight: "20px",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      {totalspigstPrice > 100
                        ? parseFloat(totalspigstPrice).toFixed(0)
                        : totalspigstPrice < 100 && totalspigstPrice >= 10
                        ? parseFloat(totalspigstPrice).toFixed(1)
                        : parseFloat(totalspigstPrice).toFixed(2)}
                    </div>
                  </TableCell>

                  <TableCell
                    sx={{
                      width: widthMap["Quantity"],
                      minWidth: widthMap["Quantity"],
                    }}
                  >
                    <div
                      style={{
                        marginRight: "20px",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      {totalquantity}
                    </div>
                  </TableCell>

                  <TableCell
                    sx={{
                      width: widthMap["Amount"],
                      minWidth: widthMap["Amount"],
                    }}
                  >
                    <div
                      style={{
                        marginRight: "20px",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      {amount > 100
                        ? parseFloat(amount).toFixed(0)
                        : amount < 100 && amount >= 10
                        ? parseFloat(amount).toFixed(1)
                        : parseFloat(amount).toFixed(2)}
                    </div>
                  </TableCell>
                  <TableCell
                    sx={{
                      width: widthMap["Delete from Quote"],
                      minWidth: widthMap["Delete from Quote"],
                    }}
                  ></TableCell>
                  <TableCell style={{ padding: "1px" }}></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <div className="discount-payment-container">
            {user && user.role !== "sales" && (
              <div>
                <>
                  <div
                    style={{ fontSize: "14px" }}
                    onClick={() => handleDivClick()}
                  >
                    Include Payment Terms
                    <Checkbox defaultChecked={isChecked} />
                  </div>
                  <PaymentTermsTable
                    totalamount={Math.round(discountedTotalAmount)}
                    rows={paymentrows}
                    setRows={setPaymentRows}
                    paymentTermsCondition={paymentTermsCondition}
                    setPaymentTermsCondition={setPaymentTermsCondition}
                  />
                </>
                

              </div>
            )}

            {user && (user.role === "superuser" || user.role === "pricing") && (
              <>
              <Table sx={{ width: "fit-content", height: "15vh" }}>
                <TableHead sx={{ background: "#5B7B99" }}>
                  <TableRow
                    sx={{
                      textAlign: "center",
                      height: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TableCell
                      sx={{
                        fontSize: "17px",
                        fontFamily: "Inter",
                        fontWeight: "700",
                        color: "white",
                        borderRadius: "7px",
                      }}
                    >
                      Discount
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody
                  sx={{
                    paddingTop: "10px",
                    background: "#EBEBEB",
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gridTemplateRows: "1fr 1fr",
                    borderBottomLeftRadius: "7px",
                    borderBottomRightRadius: "7px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "auto",
                      alignItems: "flex-start",
                      gap: "8px",
                      paddingLeft: "10% ",
                      paddingTop: "2%",
                    }}
                  >
                    <label
                      style={{
                        fontFamily: "Inter",
                        fontWeight: "400",
                        fontSize: "15px",
                      }}
                    >
                      {" "}
                      Discount (in %)
                    </label>
                    <TextField
                      fullWidth
                      variant="standard"
                      value={Math.round(discountInPercent * 100) / 100}
                      onClick={() => setSelectedDiscountType("percent")}
                      onChange={(e) => {
                        const input = e.target.value;
                        // Remove any non-numeric and non-decimal characters
                        const sanitizedInput = input.replace(/[^0-9.]/g, "");
                        // Update state only if the input is numeric or empty
                        if (!isNaN(sanitizedInput)) {
                          setDiscountInPercent(sanitizedInput);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === "Tab") {
                          handleChangeDiscountAmount("percent");
                          setSelectedDiscountType("None");
                        }
                      }}
                      onBlur={(e) => {
                        if (selectedDiscountType === "percent") {
                          handleChangeDiscountAmount("percent");
                          setSelectedDiscountType("None");
                        }
                      }}
                      sx={{
                        flex: "1",
                        background: "white",
                        border: "0.5px solid #CFCFCF",
                        borderRadius: "3px",
                        width: "90%",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "auto",
                      alignItems: "flex-start",
                      gap: "8px",
                      paddingLeft: "5% ",
                      paddingTop: "2%",
                    }}
                  >
                    <label
                      style={{
                        fontFamily: "Inter",
                        fontWeight: "400",
                        fontSize: "15px",
                      }}
                    >
                      Discount in Amount(INR)
                    </label>
                    <TextField
                      fullWidth
                      variant="standard"
                      value={Math.round(discountInAmount)}
                      onClick={() => setSelectedDiscountType("amount")}
                      onChange={(e) => {
                        const input = e.target.value;
                        // Remove any non-numeric and non-decimal characters
                        const sanitizedInput = input.replace(/[^0-9.]/g, "");
                        // Update state only if the input is numeric or empty
                        if (!isNaN(sanitizedInput)) {
                          // Check if input is a valid number
                          setDiscountInAmount(sanitizedInput);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === "Tab") {
                          handleChangeDiscountAmount("amount");
                          setSelectedDiscountType("None");
                        }
                      }}
                      onBlur={() => {
                        if (selectedDiscountType === "amount") {
                          handleChangeDiscountAmount("amount");
                          setSelectedDiscountType("None");
                        }
                      }}
                      sx={{
                        flex: "1",
                        height: "100%",
                        background: "white",
                        border: "0.5px solid #CFCFCF",
                        borderRadius: "3px",
                        width: "80%",
                      }}
                    />
                  </div>

                  <div
                    style={{
                      width: "200%",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Inter",
                        fontWeight: "500",
                        fontSize: "15px",
                      }}
                    >
                      Final Amount Inc. GST:
                    </p>
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "white",
                        border: "0.5px solid #CFCFCF",
                        borderRadius: "3px",
                        width: "38%",
                        height: "33px",
                        textAlign: "center",
                      }}
                    >
                      {Math.round(discountedTotalAmount)}
                    </p>
                  </div>
                </TableBody>
                <div>
                  <br />
      <TableContainer component={Paper}>
        <Table>
        <TableHead style={{ background: "#5B7B99" }}>
          <TableRow>
              <TableCell style={{color: "white", fontWeight: "700", fontSize:"16px"}}>Enable Delivery Charges </TableCell>
              <TableCell>
                <Checkbox style={{color: "white"}}
                  checked={isDelivery}
                  onChange={handleCheckboxChange}
                  inputProps={{ 'aria-label': 'Enable delivery charges' }}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow style={{background:"#EBEBEB"}}>
              <TableCell>
              Enter delivery charges if applicable
              </TableCell>
              <TableCell>
              <TextField
                  type="number"
                  label="Delivery Charges"
                  value={deliveryCharges}
                  onChange={handleInputChange}
                  disabled={!isDelivery}
                /></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
              </Table>
          </>    
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginTop: "15px",
              width: "fit-content",
            }}
          >
            <h4 className="upload-file-heading">Upload File</h4>
            <div
              style={{
                borderRadius: "7px",
                width: "fit-content",
                minWidth: "30vw",
                padding: "10px 5px",
                border: "1px dashed #464C5C",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div className="attachfilecontainer">
                <input
                  type="text"
                  value={attachmentTitle}
                  className="input-file"
                  placeholder="Title*"
                  required
                  onChange={(e) => setAttachmentTitle(e.target.value)}
                />

                <div
                  className="attachfilecontainerBtns"
                  style={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <input
                    type="file"
                    // value={attachmentFile}
                    className="choose-file"
                    id="choose-file-attachment"
                    accept="application/pdf"
                    required
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file.size <= 10 * 1024 * 1024) {
                        setAttachmentFile(file);
                      } else {
                        alert("File size exceeds 10MB limit.");
                        e.target.value = null;
                      }
                    }}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    style={{
                      width: "80%",
                    }}
                  />

                  <button
                    className="add-file-btn"
                    onClick={handleSubmitAttachment}
                    disabled={attachmentTitle === "" || attachmentFile === ""}
                    style={{
                      alignItems: "right",
                      justifyContent: "right",
                      width: "20%",
                      background:
                        attachmentTitle === "" || attachmentFile === ""
                          ? "gray"
                          : "#489A34",
                      cursor:
                        attachmentTitle === "" || attachmentFile === ""
                          ? "not-allowed"
                          : "pointer",
                      color: "white",
                      marginRight: "6px",
                    }}
                  >
                    Add
                  </button>
                  {/* <button
                    className="add-file-btn"
                    disabled={attachmentTitle === "" && attachmentFile === ""}
                    style={{
                      alignItems: "right",
                      justifyContent: "right",
                      width: "20%",
                      background:
                        attachmentTitle === "" && attachmentFile === ""
                          ? "gray"
                          : "#A80000",
                      cursor:
                        attachmentTitle === "" && attachmentFile === ""
                          ? "not-allowed"
                          : "pointer",
                      color: "white",
                      marginRight: "6px",
                    }}
                    onClick={() => {
                      setAttachmentFile("");
                      setAttachmentTitle("");
                      const chooseFileAttachment = document.getElementById(
                        "choose-file-attachment"
                      );
                      chooseFileAttachment.value = null;
                    }}
                  >
                    Clear
                  </button> */}
                </div>
              </div>

              <Box sx={{ width: "100%" }}>
                {uploadPdfLoader && <LinearProgress />}
              </Box>
              <p
                style={{
                  display: uploadedAttachments ? "inline" : "none",
                  fontSize: "14px",
                  fontWeight: "400",
                  fontFamily: "Inter",
                  color: "black",
                }}
              >
                Uploaded Files
              </p>
              {uploadedAttachments.length === 0 ? (
                <div></div>
              ) : (
                uploadedAttachments.map((uploadedAttachment, index) => (
                  <div key={index} className="uploaded-files">
                    <p style={{ textAlign: "left", color: "#0081DF" }}>
                      {" "}
                      <strong>Title:</strong>{" "}
                      <a
                        href={uploadedAttachment.attachmentLink}
                        style={{ color: "#0081DF", textDecoration: "none" }}
                      >
                        {uploadedAttachment.attachmentTitle}
                      </a>
                    </p>
                    {/* <p style={{ textAlign: "left", color: "#0081DF" }}>
                      {" "}
                      <strong>File:</strong>{" "}
                      <a
                        href={uploadedAttachment.attachmentLink}
                        style={{ color: "#0081DF", textDecoration: "none" }}
                      >
                        Link {index + 1}
                      </a>
                    </p> */}
                    <Delete
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        right: "3%",
                      }}
                      onClick={() =>
                        handleDeleteAttachment(
                          uploadedAttachment.attachmentLink
                        )
                      }
                    />
                  </div>
                ))
              )}
            </div>
          </div>

          {user && (user.role === "superuser" || user.role === "pricing") && (
            <div>
              {/* <div
                style={{ marginTop: "100px", width: "97%", fontSize: "14px" }}
              >
                <Checkbox defaultChecked />
                Also Sent to Client
              </div> */}
              <div style={{ width: "98%" }}>
                <Button
                  variant="contained"
                  sx={{ marginRight: "12px" }}
                  onClick={handleOpenRemarkPopUp}
                  disabled={
                    (isChecked && !paymentTermsCondition) ||
                    storedItemsChecklist.length === 0
                  }
                >
                  ReOpen To Category
                </Button>
                <Button
                  variant="contained"
                  sx={{ marginRight: "12px", backgroundColor: "#489A34" }}
                  endIcon={<VisibilityIcon />}
                  onClick={() => {
                    handleopenpreview();
                  }}
                  disabled={
                    (isChecked && !paymentTermsCondition) ||
                    storedItemsChecklist.length === 0
                  }
                >
                  Preview Quote
                </Button>
                {/* <Button
                  variant="contained"
                  style={{ marginRight: "12px", backgroundColor: "#489A34" }}
                  onClick={handleQuoteConfirmationOpen}
                  disabled={
                    !paymentTermsCondition || storedItemsChecklist.length === 0
                  }
                >
                  Generate Quote
                </Button> */}
              </div>

              <div>
                {reopenToCategoryConfirmation && (
                  <ConfirmationPopUp
                    show={reopenToCategoryConfirmation}
                    onClose={handleReopenToCategoryClose}
                    onConfirm={() => handleSendToCategory(remark)}
                    close={handleReopenToCategoryClose}
                    confirm={() => handleSendToCategory(remark)}
                    title={"Do you like to Re-open the Quotation to Category?"}
                    message={"The Quotation will be sent to Category Team"}
                  />
                )}
              </div>
            </div>
          )}
          {user && user.role === "category" && (
            <div>
              <div style={{ width: "98%" }}>
                {/* <Button
                  variant="contained"
                  sx={{ marginRight: "12px" }}
                  onClick={handleSendToPricingOpen}
                  disabled={storedItemsChecklist.length === 0}
                >
                  Send to Pricing
                </Button> */}
                <Button
                  variant="contained"
                  sx={{ marginRight: "12px", backgroundColor: "#489A34" }}
                  endIcon={<VisibilityIcon />}
                  onClick={() => {
                    handleopenpreview();
                  }}
                  disabled={
                    (isChecked && !paymentTermsCondition) ||
                    storedItemsChecklist.length === 0
                  }
                >
                  Preview Quote
                </Button>
                {/* <Button
                  variant="contained"
                  style={{ marginRight: "12px", backgroundColor: "#489A34" }}
                  onClick={handleQuoteConfirmationOpen}
                  disabled={
                    !paymentTermsCondition || storedItemsChecklist.length === 0
                  }
                >
                  Generate Quote
                </Button> */}
              </div>

              <div>
                {sendToPricingConfirmation && (
                  <ConfirmationPopUp
                    show={sendToPricingConfirmation}
                    onClose={handleSendToPricingClose}
                    onConfirm={handleSendToPricing}
                    close={handleSendToPricingClose}
                    confirm={handleSendToPricing}
                    title={"Do you like to send the quotation for Pricing?"}
                    message={"The Quotation will be sent to Pricing Team"}
                  />
                )}
              </div>
            </div>
          )}
          {user && user.role === "sales" && (
            <div>
              {/* <div
                style={{ marginTop: "100px", width: "97%", fontSize: "14px" }}
                onClick={() => handleDivClick()}
              >
                <Checkbox defaultChecked={isChecked} />
                Include Payment Terms
              </div> */}
              <div style={{ width: "98%" }}>
                <Button
                  variant="contained"
                  sx={{ marginRight: "12px", backgroundColor: "#489A34" }}
                  endIcon={<VisibilityIcon />}
                  onClick={() => {
                    handleopenpreview();
                  }}
                  disabled={storedItemsChecklist.length === 0}
                >
                  Preview Quote
                </Button>
                {/* <Button
                  variant="contained"
                  style={{ marginRight: "12px", backgroundColor: "#489A34" }}
                  onClick={handleQuoteConfirmationOpen}
                  disabled={
                    !paymentTermsCondition || storedItemsChecklist.length === 0
                  }
                >
                  Generate Quote
                </Button> */}
              </div>
            </div>
          )}
          <div>
            {openremarkPopUp && (
              <RemarkPopUp
                open={openremarkPopUp}
                onClose={() => setOpenRemarkPopUp(false)}
                onSelect={handleReopenToCategoryOpen}
              />
            )}
          </div>

          <div>
            {quoteConfirmation && (
              <ConfirmationPopUp
                show={quoteConfirmation}
                onClose={handleQuoteConfirmationClose}
                onConfirm={createQuotation}
                close={handleQuoteConfirmationClose}
                confirm={createQuotation}
                title={"Do you like to proceed with the Quotation?"}
                message={
                  "The quotation will be generated and send to the salesperson on mail."
                }
              />
            )}
          </div>
          <div>
            {importPopUp && (
              <PreviousQuotationImport
                open={importPopUp}
                onClose={() => setImportPopUp(false)}
                storedItemsChecklist={storedItemsChecklist}
                setStoredItemsChecklist={setStoredItemsChecklist}
              />
            )}
          </div>
          <ToastContainer autoClose={1000} />
        </div>
      )}
      {showPrevDialog && (
        <PrevDialog
          open={showPrevDialog}
          onClose={() => setshowPrevDialog(false)}
          quotation={quotations}
          handleQuoteConfirmationOpen={handleQuoteConfirmationOpen}
        />
      )}
      <div>
        {quoteGeneratedPopup && (
          <QuoteGeneratedPopup
            show={quoteGeneratedPopup}
            close={handleCloseQuotePopup}
            link={quoteLink}
            poc={formData.pocName}
          />
        )}
      </div>
    </div>
  );
};

const RemarkPopUp = ({ open, onClose, onSelect }) => {
  const [remarks, setRemarks] = useState("");

  const handleSelectRemark = () => {
    onSelect(remarks);
    onClose();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        component: "form",
        onSubmit: (event) => {
          event.preventDefault();
          handleSelectRemark();
        },
        sx: {
          width: "700px",
          maxWidth: "2000px !important",
        },
      }}
      sx={{
        position: "absolute",
        left: "25%",
        width: "900px",
        maxWidth: "2000px !important",
      }}
    >
      <DialogTitle>Client Requirement Remarks</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          required
          margin="dense"
          id="title"
          name="title"
          label="Remarks"
          type="text"
          fullWidth
          variant="standard"
          value={remarks}
          onChange={(e) => setRemarks(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit">Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

const PrevDialog = ({
  open,
  onClose,
  onSelect,
  quotation,
  handleQuoteConfirmationOpen,
}) => {
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setDisabled(false);
    }, 8000);
  }, []);
  return (
    <React.Fragment>
      <Dialog fullScreen open={open} onClose={onClose}>
        <AppBar
          sx={{ position: "fixed" }}
          style={{ backgroundColor: "#438ea3" }}
        >
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClose}>
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Preview
              <Typography>
                Please go through it carefully before generating the Quotation
              </Typography>
            </Typography>
          </Toolbar>
        </AppBar>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            marginTop: "65px",
            overflowY: "hidden",
          }}
        >
          <PDFViewer
            showToolbar={false}
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            <PDFView quotations={quotation} />
          </PDFViewer>
        </div>

        <Button
          autoFocus
          color="inherit"
          onClick={() => handleQuoteConfirmationOpen()}
          style={{
            position: "absolute",
            bottom: "25px",
            right: "69px",
            fontWeight: "bold",
            width: "14%",
            backgroundColor: disabled ? "gray" : "#489A34",
            marginLeft: "43%",
            color: "white",
            cursor: "pointer",
          }}
          disabled={disabled}
        >
          Generate Quote
        </Button>
      </Dialog>
    </React.Fragment>
  );
};
export default ManualQuotationDetails;
