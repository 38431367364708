import React, { useState } from 'react';
import './style.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PopupForm = ({ onClose }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    Name: '',
    topBrand: '' 
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onClose(); 
  };

  const SaveBrand = async (e) => {
    e.preventDefault();
    try {
      const { Name, topBrand } = formData;
      if (!Name || !topBrand) {
        toast.error('Fill all the Data', {
          position: toast.POSITION.TOP_RIGHT
        })
      } else {
        const response = await fetch('/addbrand', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ Name, topBrand }),
        });
        const result = await response.json();
  
        if (result.exist) {
          toast.error('Brand Already Added!', {
            position: toast.POSITION.TOP_RIGHT
          });
        } else {
          toast.success('Brand Added!', {
            position: toast.POSITION.TOP_RIGHT
          });
          // Call the getBrands function from the SKU component to update the options state
          window.opener.getBrands();
          onClose();
        }
      }
    } catch (error) {
      // console.log(error);
    }
    setTimeout(() => {
      window.location.reload();
  }, 5000);
  }
  

  return (
    // <div className="modalBackground">
      <div className="brandmodalContainer">
        <h2 className='title'>Add Brand</h2>
        <form onSubmit={handleSubmit}>
          <div className="body">
            <label htmlFor="Name"> Brand Name:</label>
            <input
              type="text"
              id="brandName"
              name="Name"
              value={formData.Name}
              onChange={handleChange}
            />
          </div>
          <div className="body2">
            <label>Top Brand</label>
            <div>
        
            <select 
                id="yes"
                name="topBrand"
                value={formData.topBrand}
                onChange={handleChange}
              >
              <option value="" disabled selected>Select </option>
                <option value="YES">YES</option>
                <option value="NO">NO</option>
              </select>
          </div>
          </div>
          {isModalOpen && <div className="overlay" />}
          <div className="brandfooter">
              <button className="close" onClick={onClose}>Close</button>
              <button type="submit" onClick={SaveBrand}>Submit</button>
              <ToastContainer autoClose={1000} />
          </div>
        </form>
      </div>
    // </div>
  );
};

export default PopupForm;
