import React from "react";
import "./header.css";
import { useNavigate, useLocation } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const movetohome = () => {
    navigate("/");
  };

  const heading = {
    quotegen: "Quotation Request",
    quotationCreation: "Quotation Creation",
    adminproduct: "Product Listing",
    manualQuotation: "Self Quotation",
    priceList: "Price List",
  };

  return (
    <div className="admin-header">
      <img
        src={
          "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/whitelogo.png"
        }
        alt="zoplar-logo"
        onClick={movetohome}
      ></img>
      <h1>{location && heading[location.pathname.split("/")[1]]}</h1>
    </div>
  );
};

export default Header;
