import Adminproduct from "./adminproduct";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/esm/Button";
import Select from "react-select";
import "./adminproductlist.css";
import Switch from "@mui/material/Switch";
import { ToastContainer, toast } from "react-toastify";
import {Helmet} from "react-helmet";


const AdminProductList = () => {
  const [confirmationSku, setConfirmationSku] = useState(null);
  const [result, setResult] = useState([]);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isCheckedList, setIsCheckedList] = useState([]);
  const [options2, setOptions2] = useState([]);
  const [options, setOptions] = useState([]);
  const [options3, setOptions3] = useState([]); // product category use state
  const [options4, setOptions4] = useState([]); // medical fields use state
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedField, setSelectedField] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [showconfirm, setshowconfirm] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(30);
  const [rowToggleState, setRowToggleState] = useState([]);

  const navigate = useNavigate();

  const categoryArray = [
    "Product Category",
    "Consumables (Low-value)",
    "Consumables (High-value)",
    "Medical Devices",
    "Medical Devices (Pre-Owned)",
    "Instruments",
    "Hospital Furniture",
    "OTC",
    "Medical Gases",
    "Medicine",
    "Implant",
    "Technical Devices (Non-Medical)",
    "Disposable",
  ];

  const fieldsArray = [
    "Multi-use",
    "General Surgery",
    "Urology",
    "Proctology",
    "Laparoscopy",
    "Orthopaedics",
    "Opthalmology",
    "Nephrology",
    "Diagnostics",
    "Radiology",
    "Pediatrics",
    "Vascular",
    "Gynaecology",
    "Bariatric",
    "Gastroenterology",
    "Cardiology",
    "Anaesthesia",
    "Dental",
    "Infection Control",
    "Neurosurgery",
    "ENT",
    "Physiotherapy",
    "Oncology",
  ];

  const updateCurrentItems = () => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const newCurrentItems = filteredData.slice(
      indexOfFirstItem,
      indexOfLastItem
    );
    setCurrentItems(newCurrentItems);
  };

  useEffect(() => {
    updateCurrentItems();
  }, [filteredData, currentPage]);

  const handleYesClick = async (sku, isvisible, index) => {
    try {
      const response = await fetch(`/visible/${sku}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ visibility: isvisible }),
      });

      if (!response.ok) {
        throw new Error("Failed to update visibility");
      }

      rowToggleState[index] = isvisible;
      setRowToggleState(rowToggleState);

      setshowconfirm(false);
    } catch (error) {
      console.error("Error updating visibility:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/getAllData");
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        // const { fieldData:data, catalogingData:catalogingResponse, visibilityData:visibilityResponse } = await response.json();
        const { fieldData: data } = await response.json();
        setResult(data);

        const newIsCheckedList = data.map((item) =>
          item.image &&
          item.image.length > 0 &&
          item.description &&
          typeof item.description === "string" &&
          item.description.trim() !== ""
            ? true
            : false
        );
        setIsCheckedList(newIsCheckedList);

        const newIsToggled = data.map((item) =>
          item.visibility &&
          item.image &&
          item.image.length > 0 &&
          item.description &&
          typeof item.description === "string" &&
          item.description.trim() !== ""
            ? item.visibility
            : false
        );

        setRowToggleState(newIsToggled);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const getBrands = async (e) => {
    const response = await fetch("/Skubrands", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();
    const brandOptions = result.map((item) => ({
      value: item,
      label: item,
    }));
    setOptions(brandOptions);
  };

  const getProducts = async (e) => {
    const response = await fetch("/Skuproducts", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();

    const productOptions = result.map((item) => ({
      value: item,
      label: item,
    }));

    setOptions2(productOptions);
  };

  const getBrandsofSelectedProduct = async (e) => {
    const response = await fetch(`/selectedProduct/${selectedProduct.value}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();
    const brandOptions = result.map((item) => ({
      value: item,
      label: item,
    }));
    setOptions(brandOptions);
  };

  const getProductsofSelectedBrand = async (e) => {
    const response = await fetch(`/selectedBrand/${selectedBrand.value}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();

    const productOptions = result.map((item) => ({
      value: item,
      label: item,
    }));

    setOptions2(productOptions);
  };

  const getProductCategory = () => {
    const categoryOptions = categoryArray.map((item) => ({
      value: item,
      label: item,
    }));

    setOptions3(categoryOptions);
  };

  const getMedicalFields = () => {
    const fieldsOptions = fieldsArray.map((item) => ({
      value: item,
      label: item,
    }));

    setOptions4(fieldsOptions);
  };

  useEffect(() => {
    if (selectedBrand) {
      getProductsofSelectedBrand();
    } else {
      getProducts();
    }

    if (selectedProduct) {
      getBrandsofSelectedProduct();
    } else {
      getBrands();
    }

    if (selectedCategory) {
      console.log(selectedCategory);
    } else {
      getProductCategory();
    }

    if (selectedField) {
      console.log(selectedField);
    } else {
      getMedicalFields();
    }

    const filteredResult = result.filter(
      (pro) =>
        (pro.name?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
          pro.Brand?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
          pro.Sku?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
          pro.prodcat.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
          pro.category.toLowerCase()?.includes(searchQuery.toLowerCase())) &&
        (!selectedBrand || pro.Brand === selectedBrand.value) &&
        (!selectedProduct || pro.name === selectedProduct.value) &&
        (!selectedCategory || pro.prodcat === selectedCategory.value) &&
        (!selectedField ||
          pro.category === selectedField.value ||
          pro.Medical_Field2 === selectedField.value ||
          pro.Medical_Field3 === selectedField.value)
    );

    setFilteredData(filteredResult);
  }, [
    result,
    searchQuery,
    selectedBrand,
    selectedProduct,
    selectedCategory,
    selectedField,
  ]);

  const opensku = () => {
    navigate("/adminsku", { state: { showdetails: false } });
  };

  const handletogglechange = (sku, index) => {
    //setRowToggleState(rowToggleState);
    if (isCheckedList[index]) {
      setConfirmationSku(sku);
      setshowconfirm(true);
    } else {
      toast.error("Catalogue data before turning Visibility On", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    // console.log(sku);
    // console.log(index);
  };

  const adminopendetail = (pro) => {
    navigate("/adminopendetail", { state: { pro } });
    setIsEditFormOpen(false);
  };

  const handleCataloguingHeaderClick = () => {
    const sortedData = [...filteredData].sort((a, b) => {
      // Define a function to determine the cataloguing status based on conditions
      const getCataloguingStatus = (item) => {
        if (
          item.image &&
          item.image.length > 0 &&
          item.description &&
          typeof item.description === "string" &&
          item.description.trim() !== ""
        ) {
          return true; // Cataloguing is true if conditions hold
        } else {
          return false; // Otherwise, cataloguing is false
        }
      };

      const cataloguingStatusA = getCataloguingStatus(a);
      const cataloguingStatusB = getCataloguingStatus(b);

      // Sort logic
      if (cataloguingStatusA === cataloguingStatusB) {
        return 0;
      } else if (cataloguingStatusA && !cataloguingStatusB) {
        return 1; // True values appear after false values
      } else {
        return -1; // False values appear before true values
      }
    });

    setFilteredData(sortedData);
    setCurrentPage(1);
  };

  return (
    <div className="AdminProductList">
      <Helmet>
          <meta charSet="utf-8" />
          <title>Products</title>
      </Helmet>
      <Adminproduct />
      {/* <h2 className="adminheading">Product Listing</h2> */}

      <div className="adminflexbut">
        <input
          type="text"
          className="adminsearchbar"
          placeholder="Search by name or brand"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyDown={(e) => (e.key === "Enter" ? setCurrentPage(1) : "")}
        />

        <Button className="adminskubut" onClick={opensku}>
          <span className="adminskubuttontext">CreateSKU</span>
        </Button>
      </div>

      <div className="adminfiltersort">
        <h5 className="adminfilter">Filter:</h5>
        <Select
          id="product"
          options={options2}
          value={selectedProduct}
          name="productName"
          placeholder="Select Product"
          onChange={(selectedOption) => setSelectedProduct(selectedOption)}
          isClearable={true}
        />
        <Select
          id="brand"
          options={options}
          value={selectedBrand}
          onChange={(selectedOption) => setSelectedBrand(selectedOption)}
          name="brandName"
          placeholder="Select Brand"
          isClearable={true}
        />
        <Select
          id="dummy-select"
          options={options3}
          value={selectedCategory}
          name="productName"
          placeholder="Select Category "
          onChange={(selectedOption) => setSelectedCategory(selectedOption)}
          isClearable={true}
        />
        <Select
          id="dummy-select2"
          options={options4}
          value={selectedField}
          name="productName"
          placeholder="Select Field "
          onChange={(selectedOption) => setSelectedField(selectedOption)}
          isClearable={true}
        />

        <Button
          className="clearfilterButton"
          onClick={() => {
            setSelectedProduct(null);
            setSelectedBrand(null);
            setSelectedCategory(null);
            setSelectedField(null);
            setCurrentPage(1);
            setSearchQuery("");
          }}
        >
          &#10006;
        </Button>
      </div>
      <div>
        <table className="admintable">
          <thead>
            <tr className="admintablerow">
              <th>SKUCode</th>
              <th>Name</th>
              <th>Brand</th>
              <th>Specification</th>
              <th>Visibility</th>
              <th
                className="cataloguingHeader"
                onClick={handleCataloguingHeaderClick}
              >
                Cataloguing
              </th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((pro, id) => (
              <tr
                className={`admintablerow2 ${
                  isEditFormOpen ? "" : "disabled-row"
                }`}
                key={id}
              >
                <td
                  className="admindetailheader"
                  onClick={() => adminopendetail(pro)}
                >
                  {pro.Sku}
                </td>
                <td
                  className="admindetailheader"
                  onClick={() => adminopendetail(pro)}
                >
                  {pro.name}
                </td>
                <td
                  className="admindetailheader"
                  onClick={() => adminopendetail(pro)}
                >
                  {pro.Brand}
                </td>
                <td
                  className="admindetailheader"
                  onClick={() => adminopendetail(pro)}
                >
                  {pro.Size}
                </td>

                <td className="adminvisible">
                  <label
                    className="react-switch-label"
                    htmlFor={`react-switch-${pro.Sku}`}
                  >
                    {
                      <Switch
                        checked={
                          rowToggleState[
                            result.findIndex((item) => item.Sku === pro.Sku)
                          ]
                        }
                        //value={rowToggleState[id]}
                        id={`react-switch-${pro.Sku}`}
                        //type="checkbox"

                        onChange={(e) =>
                          handletogglechange(
                            pro.Sku,
                            result.findIndex((item) => item.Sku === pro.Sku)
                          )
                        }
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                  </label>
                </td>

                <td>
                  <input
                    className="react-switch-checkbox"
                    id={`react-switch-${pro.Sku}`}
                    type="checkbox"
                    checked={
                      isCheckedList[
                        result.findIndex((item) => item.Sku === pro.Sku)
                      ]
                    }
                    // onChange={(e) =>
                    //   !isCheckedList[
                    //     result.findIndex((item) => item.Sku === pro.Sku)
                    //   ] && handleSwitchCheckbox(pro.Sku, e.target.checked)
                    // }
                    readOnly={
                      isCheckedList[
                        result.findIndex((item) => item.Sku === pro.Sku)
                      ]
                    }
                    //disabled={isCheckedList[id]}
                    //readOnly
                  />
                </td>
                {showconfirm && confirmationSku === pro.Sku && (
                  <div className="productlistpopup">
                    <p>
                      <span>
                        {" "}
                        {rowToggleState[
                          result.findIndex((item) => item.Sku === pro.Sku)
                        ]
                          ? "Do you want to hide this product from the website?"
                          : "Do you want to list this product on the website?"}
                      </span>
                    </p>
                    <div className="productlistpopupbuttons">
                      <button
                        className="no"
                        onClick={(e) => setshowconfirm(false)}
                      >
                        No
                      </button>
                      <button
                        className="yes"
                        onClick={(e) =>
                          handleYesClick(
                            pro.Sku,
                            !rowToggleState[
                              result.findIndex((item) => item.Sku === pro.Sku)
                            ],
                            result.findIndex((item) => item.Sku === pro.Sku)
                          )
                        }
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        <ToastContainer autoClose={1000} />
        <div className="pagination">
          <Button
            className="paginationbutton"
            onClick={() =>
              setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
            }
            disabled={currentPage === 1}
            style={{ display: currentPage === 1 ? "none" : "inline-block" }}
          >
            Prev
          </Button>
          <span> {currentPage} </span>
          <Button
            className="paginationbutton"
            onClick={() =>
              setCurrentPage((prevPage) =>
                Math.min(
                  prevPage + 1,
                  Math.ceil(filteredData.length / itemsPerPage)
                )
              )
            }
            disabled={
              currentPage === Math.ceil(filteredData.length / itemsPerPage)
            }
            style={{
              display:
                currentPage === Math.ceil(filteredData.length / itemsPerPage)
                  ? "none"
                  : "inline-block",
            }}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AdminProductList;