import React, { useState } from "react";
import "./Imageuploadpopup.css";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ImageUploadPopup = ({
  onClose,
  onImageUpload,
  Sku,
  result,
  onUpdateResult,
}) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [res, setRes] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState(
    new Array(res.length).fill(null)
  );
  const [fileSelected, setFileSelected] = useState(
    new Array(res.length).fill(false)
  );

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (reader.readyState === 2) {
          setImagePreview(e.target.result);
        }
      };
      reader.readAsDataURL(file);
      setSelectedFiles([file]);
      setFileSelected((prevFileSelected) => {
        const newFileSelected = [...prevFileSelected];
        newFileSelected[Sku] = true;
        return newFileSelected;
      });
    }
  };

  const handleFileUpload = async () => {
    setIsUploading(true);

    try {
      const selectedFile = selectedFiles[0];

      if (!selectedFile) {
        toast.error("Please select a file to upload.");
        setIsUploading(false);
        return;
      }

      const formData = new FormData();
      formData.append("image", selectedFile);

      const maxRetries = 3; // Maximum number of retries
      let retries = 0;

      while (retries < maxRetries) {
        try {
          const response = await fetch(`/pic/${Sku}`, {
            method: "POST",
            body: formData,
          });

          if (response.ok) {
            const responseData = await response.json();

            if (responseData.success) {
              toast.success("File uploaded successfully.");
              onUpdateResult([...result, responseData.data]);
              setImagePreview(
                `${imagePreview}?timestamp=${new Date().getTime()}`
              );
              setSelectedFiles([]);
              setFileSelected((prevFileSelected) => {
                const newFileSelected = [...prevFileSelected];
                newFileSelected[Sku] = false;
                return newFileSelected;
              });
              onClose();
              break; // Exit the loop if successful
            } else {
              toast.error("File upload failed: " + responseData.message);
              break; // Exit the loop on failure
            }
          } else {
            throw new Error("File upload failed. Retrying...");
          }
        } catch (error) {
          console.error("File upload error:", error);

          if (retries === maxRetries - 1) {
            toast.error("File upload failed.");
            break; // Exit the loop after maximum retries
          }

          retries++;
          console.log(
            `Retrying upload, attempt ${retries + 1} of ${maxRetries}`
          );
          await new Promise((resolve) => setTimeout(resolve, 5000)); // Wait for 3 seconds before retrying
        }
      }
    } catch (error) {
      console.error("File upload error:", error);
      toast.error("File upload failed. Please try again later.");
      onClose();
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="imageuploadpopup">
      <h5>Upload Picture</h5>
      <div className="adminuploadcontent">
        <div className="imagelarge">
          <img className="adminimageuploadpic" src={imagePreview} alt="" />
          {isUploading ? (
            <TailSpin color="#00BFFF" height={30} width={30} />
          ) : (
            <>
              {fileSelected[Sku] ? null : (
                <label className="uploadlabel" htmlFor={`fileInput-${Sku}`}>
                  Select File
                </label>
              )}
              <input
                type="file"
                id={`fileInput-${Sku}`}
                className="file-input"
                accept="image/*"
                onChange={(e) => handleFileChange(e)}
              />
            </>
          )}
        </div>
        <div className="adminextraimage">
          {result.map((image, index) => (
            <div key={index} className="imagesmall">
              <img
                src={image}
                alt={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <path
                      d="M13.6797 0C12.8629 0 12.2008 0.662133 12.2008 1.47888V12.2008H1.47888C0.662133 12.2008 0 12.8629 0 13.6797C0 14.4965 0.662133 15.1586 1.47888 15.1586H12.2008V25.8804C12.2008 26.6972 12.8629 27.3593 13.6797 27.3593C14.4965 27.3593 15.1585 26.6972 15.1585 25.8804V15.1586H25.8804C26.6972 15.1586 27.3593 14.4965 27.3593 13.6797C27.3593 12.8629 26.6972 12.2008 25.8804 12.2008H15.1585V1.47888C15.1585 0.662133 14.4965 0 13.6797 0Z"
                      fill="#017EDA"
                    />
                  </svg>
                }
              />
            </div>
          ))}
        </div>
      </div>
      <div className="adminpopupfooter">
        <button className="adminbuttonsubmit" onClick={onClose}>
          Close
        </button>
        <button className="adminbuttonsubmit" onClick={handleFileUpload}>
          Upload
        </button>
      </div>
    </div>
  );
};

export default ImageUploadPopup;
