import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import PopupForm from "./PopupForm";
import PopupForm2 from "./PopupForm2";
import Select from "react-select";
import "./abc.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import { ReactComponent as NoSKU } from "./noskufound.svg";

// function SKU({}) {
const SKU = () => {
  const GSTP = ["0%", "5%", "12%", "18%", "28%"];

  const gstOptions = GSTP.map((percentage) => ({
    value: percentage,
    label: `${percentage}`,
  }));

  const installationOptions = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  const navigate = useNavigate();
  const location = useLocation();
  const { showdetails } = location.state || { showdetails: true };
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState();
  const [showbut, setshowbut] = useState(true);
  const [showbutton, setshowbutton] = useState(false);
  const [showbuton, setshowbuton] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedOptions2, setSelectedOptions2] = useState();
  const [Brand, setBrand] = useState("");
  const [name, setname] = useState("");
  const [selectedOptions3, setSelectedOptions3] = useState();
  const [selectedOptions4, setSelectedOptions4] = useState();
  const [selectedOptions5, setSelectedOptions5] = useState(null);
  const [selectedOptions6, setSelectedOptions6] = useState("goods");

  const [additionalFieldsVisible, setAdditionalFieldsVisible] = useState(false);
  const [options2, setOptions2] = useState([{}]);
  const [errors, seterrors] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [skuvisible, setskuvisible] = useState(false);
  const [data, setData] = useState({});
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupOpen2, setIsPopupOpen2] = useState(false);
  const [generatedSKUCode, setgeneratedSKUCode] = useState("");
  const [showsku, setshowsku] = useState(false);
  const [productButtonClicked, setProductButtonClicked] = useState(false);
  const [brandButtonClicked, setbrandButtonClicked] = useState(false);
  const [addproductbuttonvisible, setaddproductbuttonvisible] = useState(true);
  const [addbrandbuttonvisible, setaddbrandbuttonvisible] = useState(true);
  const [isDisable, setSelectDisable] = useState(false);

  const isFormFilled = !!data.Size && !!selectedOptions3;

  const goback = () => {
    navigate("/adminsku");
    window.location.reload();
  };

  const openPopup = () => {
    setIsPopupOpen(true);
    setbrandButtonClicked(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setbrandButtonClicked(false);
  };

  const openPopup2 = () => {
    setIsPopupOpen2(true);
    // setshowbrandbut(false)
    setProductButtonClicked(true);
  };

  const closePopup2 = () => {
    setIsPopupOpen2(false);
    setProductButtonClicked(false);
  };

  const setField = (field, value) => {
    setData({ ...data, [field]: value });
    if (!!errors[field]) seterrors({ ...errors, [field]: null });
  };

  const handleChange = (e) => {
    e.preventDefault();
    setAdditionalFieldsVisible(true);
    setshowbuton(true);
    setshowbutton(false);
    setVisible(false);
    setshowbut(false);
    setSelectDisable(true);
  };

  const handlecancel = () => {
    // navigate("/adminsku");
    setshowbutton(false);
    setVisible(false);
    setaddbrandbuttonvisible(true);
    setaddproductbuttonvisible(true);
  };

  const handleclose = () => {
    // navigate("/adminsku");
    setshowbuton(false);
    setAdditionalFieldsVisible(false);
    setshowbut(true);
    setaddbrandbuttonvisible(true);
    setaddproductbuttonvisible(true);
    setSelectDisable(false);
  };

  const getBrands = async (e) => {
    const response = await fetch("/brands", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();
    const brandOptions = result.map((item) => ({
      value: item.Name,
      label: item.Name,
    }));

    setOptions(brandOptions);
  };
  useEffect(() => {
    getBrands();
  }, []);

  const getProducts = async (e) => {
    const response = await fetch("/products", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();

    const productOptions = result.map((item) => ({
      value: item.Name,
      label: item.Name,
    }));

    setOptions2(productOptions);
  };
  useEffect(() => {
    setVisible(false);
    getProducts();
  }, []);

  const fetchFilteredProducts = async (e) => {
    const brands = data.Brand;
    const products = data.name;
    const encodedBrands = encodeURIComponent(brands);
    const encodedProducts = encodeURIComponent(products);
    try {
      const queryParams = `?name=${encodedProducts}&Brand=${encodedBrands}`;

      const response = await fetch(`/getsku/${queryParams}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      // console.log(response);

      if (!response.ok) {
        throw new Error("No SKU Found");
      }
      const result = await response.json();
      setFilteredData(result);
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   fetchFilteredProducts();
  // }, [Brand, name]);

  const opencatalog = () => {
    navigate("/admincatalog", { state: { showdetails: false } });
  };

  const openproductlist = () => {
    navigate("/admin", { state: { showdetails: false } });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const PostDATA = async (e) => {
    e.preventDefault();
    const {
      Brand,
      name,
      GST,
      HSN,
      Size,
      Device,
      Installation,
      Type = "goods",
    } = data;

    if (!Brand || !name || !Size || !GST || !Installation || !Type) {
      {
        toast.error("Fill all the Data", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else if (Size.length + name.length + Brand.length > 192) {
      {
        toast.error(
          `Please reduce your Size/Specification length by minimum of ${
            Size.length + name.length + Brand.length - 192
          } characters`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }
    } else {
      const response = await fetch("/createsku", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Brand,
          name,
          GST,
          HSN,
          Size,
          Device,
          Installation,
          Type: Type ? Type : "goods",
        }),
      });
      const result = await response.json();

      if (result.specificationExist) {
        toast.error(`${result.message}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      // console.log(result)
      else if (result.exist) {
        setgeneratedSKUCode(result.Sku);
        toast.error(`SKU Already Added! ${result.Sku}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        setgeneratedSKUCode(result.Sku);
        toast.success(`SKU  Added! ${result.Sku}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setshowsku(true);
        setAdditionalFieldsVisible(false);
      }
    }
  };

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    // console.log(selectedOptions)
    setField("Brand", selectedOptions.value);
  };

  const handleSelectChange2 = (selectedOptions2) => {
    setSelectedOptions2(selectedOptions2);
    // console.log(selectedOptions2)
    setField("name", selectedOptions2.value);
  };

  const handleSelectChange3 = (selectedOptions3) => {
    setSelectedOptions3(selectedOptions3);
    // console.log(selectedOptions3)
    setField("GST", selectedOptions3.value);
  };

  const handleSelectChange4 = (selectedOptions4) => {
    setSelectedOptions4(selectedOptions4);
    // console.log(selectedOptions3)
    setField("Installation", selectedOptions4.value);
  };
  const handleSelectChange5 = (e) => {
    setSelectedOptions5(e.target.checked);
    // console.log(selectedOptions3)
    setField("Installation Required", selectedOptions5);
  };

  const handleSelectChange6 = (e) => {
    setSelectedOptions6(e.target.value);
    setField("Type", e.target.value);
    console.log(e.target.value);
  };

  return (
    <div className="sku-creation-page">
      <h2 className="adminskuheader">Create SKU</h2>
      {/* <Button className="adminpcbutt" onClick={opencatalog}>
                <span className="adminpcbutttext">Cataloguing</span>
            </Button>

            <Button className="adminplbutt" onClick={openproductlist}>
                <span className="adminplbutttext">Product List</span>
            </Button> */}

      {/* <div class="row">
                <div className="col s12">
                    <div className="row">
                    <div className="input-field col s12">
                    <SearchBar setresults = {setresults}/>
                    <SearchRes results = {results}/>
                    </div>
                    </div>
                </div>
            </div>         */}
      <Form onSubmit={handleSubmit} className="sku-form">
        <div className="flex">
          <div>
            {brandButtonClicked ? null : (
              <Select
                id="adminproduct"
                options={options2}
                value={selectedOptions2}
                name="name"
                placeholder="Select Product"
                isDisabled={isDisable}
                required={true}
                onChange={handleSelectChange2}
              />
            )}
            {brandButtonClicked ? null : (
              <div className="btn-control">
                {addproductbuttonvisible && (
                  <>
                    <Button
                      id="productbutton"
                      type="submit"
                      onClick={openPopup2}
                    >
                      <span className="adminplus">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          viewBox="0 0 25 25"
                          fill="none"
                        >
                          <path
                            d="M12.5 0C11.7536 0 11.1486 0.605034 11.1486 1.35135V11.1487H1.35135C0.605034 11.1487 0 11.7537 0 12.5C0 13.2464 0.605034 13.8514 1.35135 13.8514H11.1486V23.6486C11.1486 24.395 11.7536 25 12.5 25C13.2464 25 13.8514 24.395 13.8514 23.6486V13.8514H23.6486C24.395 13.8514 25 13.2464 25 12.5C25 11.7537 24.395 11.1487 23.6486 11.1487H13.8514V1.35135C13.8514 0.605034 13.2464 0 12.5 0Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                    </Button>
                    <span className="adminaddproducttext">Add Product</span>
                    {isPopupOpen2 && <PopupForm2 onClose={closePopup2} />}
                  </>
                )}
              </div>
            )}
          </div>

          <div>
            <div>
              {productButtonClicked
                ? null
                : options && (
                    <Select
                      id="adminbrand"
                      // key={options._id}
                      options={options}
                      isDisabled={isDisable}
                      value={selectedOptions}
                      name="Brand"
                      placeholder="Select Brand"
                      required={true}
                      onChange={handleSelectChange}
                    />
                  )}
              {productButtonClicked ? null : (
                <div className="btn-control">
                  {addbrandbuttonvisible && (
                    <>
                      <Button
                        id="brandbutton"
                        type="submit"
                        onClick={openPopup}
                      >
                        <span className="adminplus">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                          >
                            <path
                              d="M12.5 0C11.7536 0 11.1486 0.605034 11.1486 1.35135V11.1487H1.35135C0.605034 11.1487 0 11.7537 0 12.5C0 13.2464 0.605034 13.8514 1.35135 13.8514H11.1486V23.6486C11.1486 24.395 11.7536 25 12.5 25C13.2464 25 13.8514 24.395 13.8514 23.6486V13.8514H23.6486C24.395 13.8514 25 13.2464 25 12.5C25 11.7537 24.395 11.1487 23.6486 11.1487H13.8514V1.35135C13.8514 0.605034 13.2464 0 12.5 0Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                      </Button>
                      <span className="adminaddbrandtext">Add Brand</span>
                      {isPopupOpen && <PopupForm onClose={closePopup} />}
                    </>
                  )}
                </div>
              )}
            </div>

            {/* <Select
                            id="brand"
                            options={options}
                            value={selectedOptions}
                            name='brandName'
                            placeholder="Select Brand"
                            // isDisabled={isSelectDisabled2}
                            required={true}
                            // isClearable={true} 
                            onChange={handleSelectChange}
                        />
                    
                    
                    <Button id="brandbutton" type="submit" onClick={openPopup}>+</Button>
                    {isPopupOpen && <PopupForm onClose={closePopup} />} */}
          </div>
        </div>
        {showbut && (
          <Button
            type="submit"
            id="adminnextbutton"
            onClick={() => {
              if (!(selectedOptions2 && selectedOptions)) {
              } else {
                fetchFilteredProducts();
                setskuvisible(true);
                setVisible(true);
                setaddproductbuttonvisible(false);
                setshowbutton(true);
                setaddbrandbuttonvisible(false);
              }
            }}
          >
            <span className="adminnextbuttontext">Search</span>
          </Button>
        )}
        {skuvisible && (
          <div>
            {visible ? (
              <div>
                {filteredData.length > 0 ? (
                  <table className="adminskulistable">
                    <thead>
                      <tr className="adminskutablerow">
                        <th>SKUCode</th>
                        <th>Name</th>
                        <th>Brand</th>
                        <th>Specification</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData.map((Item) => (
                        <tr key={Item._id}>
                          <td className="adminskutd">{Item.Sku}</td>
                          <td className="adminskutd">{Item.name}</td>
                          <td className="adminskutd">{Item.Brand}</td>
                          <td className="adminskutd">{Item.Size}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  // { shownoskufound && (
                  <div className="adminnoskufound">
                    <NoSKU />
                    <span className="adminaddnewsku">
                      No SKU found. Add SKU
                    </span>
                    <div className="cancel-addsku">
                      <Button
                        className="adminskuclosebutton"
                        onClick={handlecancel}
                      >
                        <span className="adminskuclosebuttontext">Cancel</span>
                      </Button>
                      <Button
                        type="submit"
                        id="adminsubmitbutton"
                        className="submit-button"
                        onClick={handleChange}
                      >
                        <span className="adminsubmitbuttontext">Add SKU</span>
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            ) : null}
          </div>
        )}

        <>
          {additionalFieldsVisible && (
            <div className="adminshowadditionalfield">
              <Form.Group className="size" controlId="Size">
                <Form.Label className="sizelabel">Specification</Form.Label>
                <Form.Control
                  className="sizeinput"
                  type="text"
                  value={Form.Size}
                  placeholder="Specification"
                  onChange={(e) => {
                    setField("Size", e.target.value);
                  }}
                ></Form.Control>
              </Form.Group>
              <div className="gst">
                <label className="gstlabel">GST</label>
                <Select
                  className="GSTinput"
                  id="gst"
                  options={gstOptions}
                  value={selectedOptions3}
                  name="GST"
                  placeholder="Select"
                  required={true}
                  onChange={handleSelectChange3}
                />
              </div>
              <div className="installation">
                <label className="install-label">Installation Required?</label>
                <Select
                  className="install-input"
                  id="installation"
                  options={installationOptions}
                  value={selectedOptions4}
                  name="Installation"
                  placeholder="Select"
                  required={true}
                  onChange={handleSelectChange4}
                />
              </div>
              {/* <div className="install_required">
                <label>
                  <input
                    style={{ marginRight: "10px" }}
                    type="checkbox"
                    onChange={handleSelectChange5}
                    name="Install Required"
                    value={selectedOptions5}
                  />
                  Is Installation required ?
                </label>
              </div> */}
              <div className="goods_service">
                <p>Type:</p>
                <input
                  type="radio"
                  id="html"
                  name="goods_service_option"
                  value="goods"
                  checked={selectedOptions6 === "goods"}
                  onChange={handleSelectChange6}
                />
                <label type="radio" for="html">
                  Goods
                </label>

                <input
                  type="radio"
                  id="css"
                  name="goods_service_option"
                  value="service"
                  checked={selectedOptions6 === "service"}
                  onChange={handleSelectChange6}
                />
                <label for="css">Service</label>
              </div>
              {showbuton && (
                <div className="adminset">
                  <Button className="admincancelbutton" onClick={handleclose}>
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    className="admingenerate"
                    onClick={PostDATA}
                  >
                    <span className="admingeneratebuttontext">Submit</span>
                  </Button>
                </div>
              )}

              <ToastContainer autoClose={1000} />
            </div>
          )}
        </>

        {showbutton && (
          <div
            className="cancel-addsku"
            style={{ display: !(filteredData.length > 0) ? "none" : "" }}
          >
            <Button className="adminskuclosebutton" onClick={handlecancel}>
              <span className="adminskuclosebuttontext">Cancel</span>
            </Button>
            <Button
              type="submit"
              id="adminsubmitbutton"
              className="submit-button"
              onClick={handleChange}
            >
              <span className="adminsubmitbuttontext">Add SKU</span>
            </Button>
          </div>
        )}

        {showsku && (
          <div className="adminskucreated">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="58"
              height="58"
              viewBox="0 0 58 58"
              fill="none"
            >
              <path
                d="M29 0C23.2643 0 17.6575 1.70082 12.8885 4.88738C8.11945 8.07394 4.40245 12.6031 2.20751 17.9022C0.0125659 23.2012 -0.561732 29.0322 0.55724 34.6576C1.67621 40.2831 4.43819 45.4504 8.49391 49.5061C12.5496 53.5618 17.7169 56.3238 23.3424 57.4428C28.9678 58.5617 34.7988 57.9874 40.0978 55.7925C45.3969 53.5976 49.9261 49.8806 53.1126 45.1115C56.2992 40.3425 58 34.7357 58 29C58 25.1917 57.2499 21.4206 55.7925 17.9022C54.3351 14.3837 52.199 11.1868 49.5061 8.4939C46.8132 5.801 43.6163 3.66488 40.0978 2.20749C36.5794 0.750108 32.8083 0 29 0ZM44.8874 21.6334L25.7832 39.3297C25.412 39.6747 24.9231 39.865 24.4163 39.8617C23.9073 39.8638 23.4163 39.6738 23.0412 39.3297L13.1126 30.1541C12.7378 29.7875 12.5208 29.2891 12.5077 28.7649C12.4946 28.2407 12.6865 27.7321 13.0426 27.3472C13.3987 26.9624 13.8909 26.7316 14.4145 26.7039C14.9381 26.6763 15.4518 26.854 15.8465 27.1993L24.4081 35.1061L42.1454 18.6703C42.5383 18.3067 43.0596 18.1141 43.5945 18.1348C44.1295 18.1555 44.6343 18.3879 44.9979 18.7808C45.3615 19.1738 45.5541 19.695 45.5334 20.23C45.5127 20.7649 45.2803 21.2697 44.8874 21.6334Z"
                fill="#259F07"
              />
            </svg>
            <br />
            <span className="adminskucreatedtext">Sku has been created</span>
            <br />
            <span className="adminskutext">{generatedSKUCode}</span>
            <br />
            <button className="adminbackbutton" onClick={goback}>
              Back
            </button>
          </div>
        )}
      </Form>
    </div>
  );
};

export default SKU;
