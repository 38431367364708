import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import AddIcon from "@mui/icons-material/Add";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer, toast } from "react-toastify";
import "./quotationCreation.css";
import InputAdornment from "@mui/material/InputAdornment";

import { ReactComponent as Search } from "../icons/search.svg";
import Pagination from "@mui/material/Pagination";
import "./searchPriceList.css";
import { UserContext } from "../App";

const SalesLeadsData = ({ setValue }) => {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [result, setResult] = useState([]);
  const [page, setPage] = useState(1);
  const [queryPerPage, setQueryPerPage] = useState(1);
  const [totalQuery, setTotalQuery] = useState(1);
  const { user } = useContext(UserContext);
  const selectedLeadData = localStorage.getItem("selectedLead")
    ? JSON.parse(localStorage.getItem("selectedLead"))
    : {};
  const [selectedLead, setSelectedLead] = useState(selectedLeadData);
  const handleSearchResult = async () => {
    try {
      setLoading(true);

      const response = await fetch(
        `/quotegen/searchSalesLeadsData?searchText=${searchText}&page=${page}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const { data, limit, totalRows } = await response.json();
      setQueryPerPage(limit);
      setTotalQuery(totalRows);
      setResult(data);

      setLoading(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleSearchResult();
  }, [page]);

  const leadDataHeader = [
    "Name",
    "Phone",
    "Email",
    "Establishment Name",
    "City",
    "Select",
  ];

  const handleSearchInputChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleCheckboxChange = (row) => {
    const selectedRow = {
      ...row,
      Phone: row.Phone ? row.Phone.slice(-10) : "",
    };
    localStorage.setItem("selectedLead", JSON.stringify(selectedRow));
    setSelectedLead(row);
    setValue(0);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleClearSearch = () => {
    setSearchText("");
    handleSearchResult();
    setPage(1);
    setResult([]);
  };
  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginBottom: "1%",
            }}
          >
            <TextField
              width="50%"
              id="fullWidth"
              className="sales-lead-textfield"
              placeholder="Search Name/Phone/Establishment/City"
              value={searchText}
              onChange={handleSearchInputChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearchResult();
                }
                if (e.key === "Escape") {
                  handleClearSearch();
                }
              }}
              sx={{
                flex: "1",
                padding: "0px !important",
                borderRadius: "5px",
                height: "fit-content",
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ position: "absolute", right: "0px" }}
                  >
                    <Search
                      onClick={handleSearchResult}
                      style={{
                        background: "#C9C9C9",
                        cursor: "pointer",
                        padding: "0px 10px",
                        height: "44px",
                        borderBottomRightRadius: "3px",
                        borderTopRightRadius: "3px",
                        border: "none",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />

            <button
              style={{
                width: "100px",
                height: "44px",
                borderRadius: "5px",
                background: "#C9C9C9",
                cursor: "pointer",
                padding: "0px 10px",
                border: "1px solid #9A9A9A",
                color: "white",
                fontSize: "18px",
              }}
              onClick={handleClearSearch}
            >
              Clear
            </button>
          </Box>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow sx={{ backgroundColor: "#5390c2" }}>
                  {leadDataHeader.map((leadH) => (
                    <TableCell
                      key={leadH}
                      align="left"
                      sx={{
                        color: "#ffffff",
                        fontFamily: "Inter-Bold, Helvetica",
                        fontSize: "15px",
                        fontWeight: "700",
                      }}
                    >
                      {leadH}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {result &&
                  result.map((row) => (
                    <LeadList
                      key={row.ProspectID}
                      row={row}
                      handleCheckboxChange={handleCheckboxChange}
                      isChecked={selectedLead.ProspectID === row.ProspectID}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{
              backgroundColor: "#F5F5F5",
              borderRadius: "2px",
              marginTop: "5%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(totalQuery / queryPerPage)}
                boundaryCount={1}
                page={page}
                siblingCount={2}
                onChange={handlePageChange}
              />
            </Stack>
          </div>
          <ToastContainer autoClose={1000} />
        </div>
      )}
    </div>
  );
};

const LeadList = ({ row, handleCheckboxChange, isChecked }) => {
  return (
    <React.Fragment>
      <TableRow>
        <TableCell
          component="th"
          scope="row"
          align="left"
          style={{
            fontWeight: 500,
            color: "#0671bf",
            maxWidth: 200,
            whiteSpace: "pre-wrap",
          }}
        >
          {row.FirstName}
        </TableCell>
        <TableCell align="left" style={{ fontWeight: 500 }}>
          {row.Phone}
        </TableCell>
        <TableCell align="left" style={{ fontWeight: 500 }}>
          {row.EmailAddress}
        </TableCell>

        <TableCell
          align="left"
          style={{
            fontWeight: 500,
          }}
        >
          {row.mx_Establishment_Name}
        </TableCell>
        <TableCell align="left">{row.mx_New_City}</TableCell>
        <TableCell align="left">
          {" "}
          <Checkbox
            icon={<AddIcon />}
            onChange={() => handleCheckboxChange(row)}
            checked={isChecked}
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default SalesLeadsData;
