import React from "react";
import AdminImg from "./adminLoginImg.png";
import "./loginAdmin.css";
import { ReactComponent as GoogleIcon } from "./googleicon.svg";

const LoginAdmin = () => {
  const google = () => {
    const currentHost = window.location.href;

    const targetURL =
      currentHost === "http://localhost:3000/"
        ? "http://localhost:4000/auth/google"
        : "/auth/google"; // Change 'example.com' to your other URL

    window.open(targetURL, "_self");
  };

  return (
    <div className="admin-loginpage">
      <h1>Welcome to Admin Panel</h1>
      <h4>Log in</h4>
      <img src={AdminImg} alt="admin" className="admin-img" />
      <button onClick={google}>
        <GoogleIcon />
        Zoplar Gmail Login
      </button>
    </div>
  );
};

export default LoginAdmin;
