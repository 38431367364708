import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import ManualQuotationDetails from "./ManualQuotationDetails";
import SearchPriceList from "./searchPriceList";
import Badge from "@mui/material/Badge";
import "./quotationCreation.css";
import SalesLeadsData from "./salesLeadsData";
import { UserContext } from "../App";

function CustomTabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ManualQuotation = () => {
  const { user } = useContext(UserContext);
  const divStyle = {
    display: "flex",
    position: "absolute",
    top: "15%",
    width: "97%",
    // left: "15%",
    marginLeft: "3%", // Added marginLeft property
    textAlign: "right",
    flexDirection: "column",
    paddingBottom: "5%",
  };

  const [value, setValue] = useState(user && user.role === "sales" ? 2 : 1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const storedItemsString = localStorage.getItem("selectedItems");
  const storedItemsValue =
    storedItemsString && storedItemsString ? JSON.parse(storedItemsString) : [];

  const [productCount, setProductCount] = useState(storedItemsValue.length);
  const [fromGenericData, setFromGenericData] = useState(false);

  return (
    <div style={divStyle}>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            borderColor: "divider",
            flex: "1",
            width: "70%",
            marginLeft: "10%",
            gap: "10px",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="fullWidth"
            sx={{
              "& .MuiTabs-root": {
                fontSize: "24px",
                fontWeight: "600",
                fontFamily: "'Inter-SemiBold', 'Helvetica', sans-serif",
              },
            }}
          >
            <Tab
              // label="Quotation Request Details"
              label={
                <Badge
                  badgeContent={productCount}
                  color="primary"
                  sx={{
                    "& .MuiBadge-badge": {
                      right: -10,
                      top: 2,
                      padding: "0 4px",
                    },
                  }}
                >
                  Quotation
                </Badge>
              }
              id="simple-tab-0"
              aria-controls="simple-tabpanel-0"
              sx={{
                borderRadius: "7px 7px 0px 0px",
                boxShadow: " 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                fontFamily: "Inter",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
                backgroundColor: "#fff",
                color: "#464C5C  !important",
                border: "0.7px solid #C9C9C9",
                "&.Mui-selected": {
                  backgroundColor: "#E6EBFF ",
                },
              }}
            />
            <Tab
              label={<Badge>Price List</Badge>}
              id="simple-tab-1"
              aria-controls="simple-tabpanel-1"
              sx={{
                borderRadius: "7px 7px 0px 0px",
                boxShadow: " 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                fontFamily: "Inter",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
                backgroundColor: "#fff",
                color: "#464C5C  !important",
                border: "0.7px solid #C9C9C9",
                "&.Mui-selected": {
                  backgroundColor: "#E6EBFF ",
                },
              }}
            />
            {/* <Tab
              label={<Badge>Generic Quotation</Badge>}
              id="simple-tab-2"
              aria-controls="simple-tabpanel-2"
              sx={{
                borderRadius: "7px 7px 0px 0px",
                boxShadow: " 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                fontFamily: "Inter",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
                backgroundColor: "#fff",
                color: "#464C5C  !important",
                border: "0.7px solid #C9C9C9",
              }}
            /> */}
            {user && user.role === "sales" && (
              <Tab
                label={<Badge>Leads</Badge>}
                id="simple-tab-2"
                aria-controls="simple-tabpanel-2"
                sx={{
                  borderRadius: "7px 7px 0px 0px",
                  boxShadow: " 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  fontFamily: "Inter",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "normal",
                  backgroundColor: "#fff",
                  color: "#464C5C  !important",
                  border: "0.7px solid #C9C9C9",
                  "&.Mui-selected": {
                    backgroundColor: "#E6EBFF ",
                  },
                }}
              />
            )}
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <ManualQuotationDetails
            setProductCount={setProductCount}
            fromGenericData={fromGenericData}
            setFromGenericData={setFromGenericData}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <SearchPriceList setProductCount={setProductCount} />
        </CustomTabPanel>
        {/* <CustomTabPanel value={value} index={2}>
          <GenericQuotation
            setValue={setValue}
            setFromGenericData={setFromGenericData}
          />
        </CustomTabPanel> */}
        {user && user.role === "sales" && (
          <CustomTabPanel value={value} index={2}>
            <SalesLeadsData setValue={setValue} />
          </CustomTabPanel>
        )}
      </Box>
    </div>
  );
};

export default ManualQuotation;
