import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./quotationCreation.css";
import "./QueryDetails.css";
import { useParams } from "react-router-dom";

const PaymentTermsTable = ({
  totalamount,
  rows,
  setRows,
  paymentTermsCondition,
  setPaymentTermsCondition,
}) => {
  const { queryId } = useParams();
  const [durationError, setDurationError] = useState(false);
  useEffect(() => {
    const newrow = rows.map((prevRow) =>
      prevRow.paymentTermsPercentage !== 0
        ? {
            ...prevRow,
            paymentTermsAmount:
              (totalamount * prevRow.paymentTermsPercentage) / 100,
            paymentTermsPercentage: prevRow.paymentTermsPercentage,
          }
        : prevRow
    );
    setRows(newrow);

    setNewTotalSumAmount(totalamount);
    if (queryId && rows) {
      const cachedData = JSON.parse(
        localStorage.getItem(`queryDetailsData_${queryId}`)
      );
      if (cachedData) {
        cachedData.paymentTerms = newrow;
        localStorage.setItem(
          `queryDetailsData_${queryId}`,
          JSON.stringify(cachedData)
        );
      }
    }
  }, [totalamount]);

  const [newTotalSumAmount, setNewTotalSumAmount] = useState(totalamount);
  const [newTotalSumPercent, setNewTotalSumPercent] = useState(100);

  const handleBlur = () => {
    let sumPercentage = 0;
    let sumAmount = 0;
    let isCreditEmiValid = true;
    rows.forEach((row) => {
      sumPercentage += parseFloat(row.paymentTermsPercentage || 0);
      sumAmount += parseFloat(row.paymentTermsAmount || 0);

      if (
        (row.paymentTermstype === "Credit(In Days)" ||
          row.paymentTermstype === "EMI(In Months)") &&
        (row.paymentTermsAmount !== 0 || row.paymentTermsPercentage !== 0) &&
        row.duration === ""
      ) {
        isCreditEmiValid = false;
        return;
      }
    });
    // setDurationError(false);
    setNewTotalSumAmount(sumAmount);
    setNewTotalSumPercent(sumPercentage);

    if (sumPercentage === 100 || sumAmount === totalamount) {
      if (isCreditEmiValid) {
        setPaymentTermsCondition(true);
        setRows(rows);

        if (queryId) {
          const cachedData = JSON.parse(
            localStorage.getItem(`queryDetailsData_${queryId}`)
          );
          cachedData.paymentTerms = rows;
          localStorage.setItem(
            `queryDetailsData_${queryId}`,
            JSON.stringify(cachedData)
          );
        }
      } else {
        setPaymentTermsCondition(false);
        return;
      }
    } else {
      setPaymentTermsCondition(false);
      return;
    }
  };
  return (
    <div style={{ width: "fit-content" }}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead sx={{ background: "#D0DEDD" }}>
            <TableRow>
              <TableCell
                sx={{
                  color: "#335856",
                  fontSize: "16px",
                  fontWeight: "700",
                  fontFamily: "Inter",
                  textAlign: "center !important",
                }}
              >
                Payment Terms
              </TableCell>
              <TableCell
                sx={{
                  color: "#335856",
                  fontSize: "16px",
                  fontWeight: "700",
                  fontFamily: "Inter",
                  textAlign: "center !important",
                }}
                align="right"
              >
                Duration
              </TableCell>
              <TableCell
                sx={{
                  color: "#335856",
                  fontSize: "16px",
                  fontWeight: "700",
                  fontFamily: "Inter",
                  textAlign: "center !important",
                }}
                align="right"
              >
                Amount
              </TableCell>
              <TableCell
                sx={{
                  color: "#335856",
                  fontSize: "16px",
                  fontWeight: "700",
                  fontFamily: "Inter",
                  textAlign: "center !important",
                }}
                align="right"
              >
                Percentage(%)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={row.paymentTermstype}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    fontSize: "14px",
                    fontWeight: "600",
                    fontFamily: "Inter",
                    width: "150px",
                    textAlign: "center !important",
                  }}
                >
                  {row.paymentTermstype}
                </TableCell>
                <TableCell sx={{ width: "100px" }}>
                  <input
                    type="number"
                    value={row.duration}
                    onChange={(e) =>
                      setRows((prevRows) =>
                        prevRows.map((prevRow) =>
                          prevRow.paymentTermstype === row.paymentTermstype
                            ? { ...prevRow, duration: e.target.value }
                            : prevRow
                        )
                      )
                    }
                    onBlur={handleBlur}
                    style={{
                      width: "100%",
                      border: "none",
                      borderBottom: "0.5px solid #ECECEC",
                      outline: "none",
                      color: "#0081DF",
                      fontSize: "14px",
                      fontFamily: "Inter",
                      textAlign: "center !important",
                    }}
                    disabled={
                      row.paymentTermstype === "Advance" ||
                      row.paymentTermstype === "On Delivery" ||
                      row.paymentTermstype === "On Installation"
                    }
                  />
                </TableCell>
                <TableCell sx={{ width: "120px", overflowX: "hidden" }}>
                  <input
                    type="number"
                    value={Math.round(row.paymentTermsAmount * 100) / 100}
                    onChange={(e) => {
                      const newAmount = parseFloat(e.target.value);
                      const newPercentage = (newAmount / totalamount) * 100;
                      setRows((prevRows) =>
                        prevRows.map((prevRow) =>
                          prevRow.paymentTermstype === row.paymentTermstype
                            ? {
                                ...prevRow,
                                paymentTermsAmount: newAmount,
                                paymentTermsPercentage: newPercentage,
                              }
                            : prevRow
                        )
                      );
                    }}
                    onBlur={handleBlur}
                    onKeyDown={(e) => (e.key === "Enter" ? handleBlur() : null)}
                    style={{
                      width: "100%",
                      border: "none",
                      borderBottom: "0.5px solid #ECECEC",
                      outline: "none",
                      color: "#0081DF",
                      fontSize: "14px",
                      fontFamily: "Inter",
                      textAlign: "center",
                    }}
                  />
                </TableCell>
                <TableCell sx={{ width: "70px", overflowX: "hidden" }}>
                  <input
                    type="number"
                    value={Math.round(row.paymentTermsPercentage * 100) / 100}
                    onChange={(e) => {
                      const newPercentage = parseFloat(e.target.value);
                      const newAmount = (totalamount * newPercentage) / 100;
                      setRows((prevRows) =>
                        prevRows.map((prevRow) =>
                          prevRow.paymentTermstype === row.paymentTermstype
                            ? {
                                ...prevRow,
                                paymentTermsPercentage: newPercentage,
                                paymentTermsAmount: newAmount,
                              }
                            : prevRow
                        )
                      );
                    }}
                    onBlur={handleBlur}
                    onKeyDown={(e) => (e.key === "Enter" ? handleBlur() : null)}
                    style={{
                      width: "100%",
                      border: "none",
                      borderBottom: "0.5px solid #ECECEC",
                      outline: "none",
                      color: "#0081DF",
                      fontSize: "14px",
                      fontFamily: "Inter",
                      textAlign: "center",
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                  fontFamily: "Inter",
                  width: "150px",
                  textAlign: "center !important",
                }}
              >
                Total:
              </TableCell>
              <TableCell sx={{ width: "100px" }}></TableCell>
              <TableCell
                sx={{
                  width: "120px",
                  overflowX: "hidden",
                  borderBottom: "0.5px solid #ECECEC",
                  outline: "none",
                  color: "#0081DF",
                  fontSize: "14px",
                  fontFamily: "Inter",
                  textAlign: "center",
                }}
              >
                {parseFloat(newTotalSumAmount).toFixed(2)}
              </TableCell>
              <TableCell
                sx={{
                  width: "120px",
                  overflowX: "hidden",
                  borderBottom: "0.5px solid #ECECEC",
                  outline: "none",
                  color: "#0081DF",
                  fontSize: "14px",
                  fontFamily: "Inter",
                  textAlign: "center",
                }}
              >
                {" "}
                {parseFloat(newTotalSumPercent).toFixed(2)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        {!paymentTermsCondition &&
          `Total Amount is not equal to INR ${totalamount} / Enter duration in EMI/Credit(if Applicable) `}
      </div>
    </div>
  );
};

export default PaymentTermsTable;
