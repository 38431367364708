import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { ToastContainer, toast } from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const QuoteGeneratedPopup = ({ show, close, link, poc }) => {

  const pocMessage = `Hi ${poc},\n\nPlease find the quotation you requested for here:\n${link}`;

  return (
    <>
      <Dialog
        open={show}
        TransitionComponent={Transition}
        keepMounted
        disableBackdropClick
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            close();
          }
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Quotation Generated Successfully</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            The quotation has been generated and sent to the salesperson on
            mail.
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            Quotation Link: <a href={link} target="_blank">{link}</a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Close</Button>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(link);
              toast.success("Quotation Message Copied");
            }}
          >
            Copy Link
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer autoClose={1000} />
    </>
  );
};

export default QuoteGeneratedPopup;
