import {  Routes } from 'react-router-dom';
const Adminproduct = () => {

  return (
    <div className="Adminproduct">
        <Routes>
           
            
        </Routes>
    </div>
    
  );
}

export default Adminproduct;