import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet";
import Select from "react-select";

const Vendors = () => {
  const [open, setOpen] = React.useState(false);
  const [enteredValue, setEnteredValue] = useState("");
  const [vendorsList, setVendorsList] = useState([]);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [zoplarPocs, setZoplarPocs] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenEditPopUp = (vendor) => {
    setSelectedVendor(vendor);
    setOpenUpdate(true);
  };

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };

  const divStyle = {
    display: "flex",
    position: "absolute",
    top: "10%",
    width: "88%",
    marginLeft: "120px",
    // left: "20%",
    textAlign: "center",
    flexDirection: "column",
  };

  const tableHeader = [
    // "Date",
    "Prospect ID",
    "Name ",
    "Partner POC Name ",
    "Partner POC No ",
    "City",
    "State",
    // "OnBoarded",
    // "Partner ID",
    "Zoplar POC",
    // "Gst Number",
    "Edit",
  ];

  const handleVendorSearch = () => {
    const filteredVendors = vendorsList.filter((filteredVendor) =>
      ["name", "partnerPocName"].some((key) =>
        filteredVendor[key].toLowerCase().includes(enteredValue.toLowerCase())
      )
    );

    setVendorsList(filteredVendors);
  };

  const [formData, setFormData] = useState({
    name: "",
    partnerPocName: "",
    partnerPocNo: "",
    partnerPocEmail: "",
    country: "",
    state: "",
    city: "",
    // pinCode: "",
    // address: "",
    // gstNo: "",
    zoplarPoc: "",
    // onBoarded: "",
  });

  const handleChange = async (event) => {
    const { id, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = async () => {
    if (
      formData.name === "" ||
      formData.partnerPocName === "" ||
      formData.partnerPocNo === "" ||
      formData.zoplarPoc === ""
    ) {
      toast.error("Fill compulsory Fields", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    const payload = {
      name: formData.name,
      partnerPocName: formData.partnerPocName,
      partnerPocNo: formData.partnerPocNo,
      partnerPocEmail: formData.partnerPocEmail,
      // gstNo: formData.gstNo,
      country: formData.country,
      state: formData.state,
      city: formData.city,
      // pinCode: formData.pinCode,
      // address: formData.address,
      zoplarPoc: formData.zoplarPoc,
      // onBoarded: formData.onBoarded,
    };

    const response = await fetch(`/quotegen/vendors`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ vendorData: payload }),
    });
    const { success, data } = await response.json();

    if (!success) {
      toast.error("Vendor with the same Name or Number already exists", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (success) {
      toast.success("New Vendor Created", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setOpen(false);
      setVendorsList(data);
    }
  };

  const handleUpdate = async () => {
    if (
      selectedVendor.name === "" ||
      selectedVendor.partnerPocName === "" ||
      selectedVendor.partnerPocNo === "" ||
      selectedVendor.zoplarPoc === ""
    ) {
      toast.error("Fill compulsory Fields", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const payload = {
      prospect_id: selectedVendor.prospect_id,
      name: selectedVendor.name,
      partnerPocName: selectedVendor.partnerPocName,
      partnerPocNo: selectedVendor.partnerPocNo,
      partnerPocEmail: selectedVendor.partnerPocEmail,
      // gstNo: formData.gstNo,
      country: selectedVendor.country,
      state: selectedVendor.state,
      city: selectedVendor.city,
      // pinCode: formData.pinCode,
      // address: formData.address,
      zoplarPoc: selectedVendor.zoplarPoc,
      // onBoarded: formData.onBoarded,
    };

    const response = await fetch(`/quotegen/vendors`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ vendorData: payload }),
    });
    const { success, data } = await response.json();

    if (success) {
      toast.success("Vendor updated Successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setOpenUpdate(false);
      setVendorsList(data);
    }
  };

  const getVendors = async () => {
    const response = await fetch(`/quotegen/vendors`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const { success, data: vendorData } = await response.json();

    if (success) {
      setVendorsList(vendorData);
    }
  };

  const getZoplarPoc = async () => {
    try {
      const response = await fetch(`/quotegen/getZoplarPocs`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      const zoplarPocsData = data.categoryUsers.map(({ _id, username }) => ({
        label: username,
        value: username,
      }));
      setZoplarPocs(zoplarPocsData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getVendors();
    getZoplarPoc();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={divStyle}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vendors</title>
      </Helmet>
      <h1 style={{ fontFamily: "Manrope" }}>Vendors</h1>
      <div
        style={{
          display: "flex",
          marginBottom: "20px",
        }}
      >
        <TextField
          style={{ flex: "0 0 60%" }}
          value={enteredValue}
          onChange={(e) => {
            setEnteredValue(e.target.value);
          }}
        />
        <Button
          variant="contained"
          style={{ width: "15%", marginRight: "10%" }}
          onClick={handleVendorSearch}
        >
          Search
        </Button>

        <React.Fragment>
          <Button
            variant="contained"
            style={{ width: "15%" }}
            onClick={handleClickOpen}
          >
            Add New Vendor
          </Button>
          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              Vendor Onboarding
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <TextField
                autoFocus
                required
                margin="dense"
                id="name"
                label="Channel Partner's Name"
                type="text"
                value={formData.name}
                fullWidth
                onChange={handleChange}
              />
              <TextField
                autoFocus
                required
                margin="dense"
                id="partnerPocName"
                label="POC Name"
                type="text"
                value={formData.partnerPocName}
                fullWidth
                onChange={handleChange}
              />
              <TextField
                autoFocus
                required
                margin="dense"
                id="partnerPocNo"
                label="POC No"
                type="text"
                value={formData.partnerPocNo}
                fullWidth
                onChange={handleChange}
              />
              <TextField
                autoFocus
                margin="dense"
                id="partnerPocEmail"
                label="POC Email"
                type="text"
                value={formData.partnerPocEmail}
                fullWidth
                onChange={handleChange}
              />
              {/* <TextField
                margin="dense"
                id="gstNo"
                label="GST Number"
                type="text"
                value={formData.gstNo}
                fullWidth
                onChange={handleChange}
              /> */}
              <TextField
                margin="dense"
                id="country"
                label="Country"
                type="text"
                value={formData.country}
                fullWidth
                onChange={handleChange}
              />
              <TextField
                margin="dense"
                id="state"
                label="State / UT"
                type="text"
                value={formData.state}
                fullWidth
                onChange={handleChange}
              />
              <TextField
                margin="dense"
                id="city"
                label="City"
                type="text"
                value={formData.city}
                fullWidth
                onChange={handleChange}
              />
              {/* <TextField
                margin="dense"
                id="pinCode"
                label="Pin Code"
                type="text"
                value={formData.pinCode}
                fullWidth
                onChange={handleChange}
              />
              <TextField
                margin="dense"
                id="address"
                label="Enter Complete Address"
                type="text"
                value={formData.address}
                fullWidth
                onChange={handleChange}
              /> */}
              {/* <TextField
                margin="dense"
                id="zoplarPoc"
                label="Zoplar POC Name"
                type="text"
                value={formData.zoplarPoc}
                fullWidth
                onChange={handleChange}
              /> */}
              <div
                style={{
                  marginTop: "8px",
                  fontFamily: "Inter",
                  fontSize: "15px",
                  fontWeight: "350",
                }}
              >
                <Select
                  onChange={(newValue) => {
                    setFormData((prevData) => ({
                      ...prevData,
                      zoplarPoc: newValue.value,
                    }));
                  }}
                  options={zoplarPocs}
                  placeholder="Select Zoplar POC*"
                  style={{
                    marginTop: "5px",
                  }}
                />
              </div>
              {/* <TextField
                margin="dense"
                id="onBoarded"
                label="Is this Vendor Procured"
                type="text"
                value={formData.onBoarded}
                fullWidth
                onChange={handleChange}
              /> */}
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleSubmit}>
                Create Vendor
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead sx={{ backgroundColor: "pink" }}>
            <TableRow sx={{ backgroundColor: "#284A5F" }}>
              {tableHeader.map((tableH) => (
                <TableCell
                  key={tableH}
                  align="center"
                  sx={{
                    color: "#ffffff",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: "700",
                    lineHeight: "normal",
                  }}
                >
                  {tableH}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {vendorsList &&
              vendorsList.map((vendor) => (
                <TableRow
                  key={vendor._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{vendor.prospect_id}</TableCell>
                  <TableCell align="center">{vendor.name}</TableCell>
                  <TableCell align="center">{vendor.partnerPocName}</TableCell>
                  <TableCell align="center">{vendor.partnerPocNo}</TableCell>
                  <TableCell align="center">{vendor.city}</TableCell>
                  <TableCell align="center">{vendor.state}</TableCell>
                  {/* <TableCell align="center">{vendor.onBoarded}</TableCell> */}
                  {/* <TableCell align="center">{vendor.partner_id}</TableCell> */}
                  <TableCell align="center">{vendor.zoplarPoc}</TableCell>
                  {/* <TableCell align="center">{vendor.gstNo}</TableCell> */}
                  <TableCell
                    align="center"
                    onClick={() => handleOpenEditPopUp(vendor)}
                  >
                    <EditIcon />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div>
        {openUpdate && (
          <UpdatePopUp
            openUpdate={openUpdate}
            handleCloseUpdate={handleCloseUpdate}
            selectedVendor={selectedVendor}
            handleUpdate={handleUpdate}
            setSelectedVendor={setSelectedVendor}
            zoplarPocs={zoplarPocs}
          />
        )}
      </div>
      <ToastContainer autoClose={1000} />
    </div>
  );
};

const UpdatePopUp = ({
  openUpdate,
  handleCloseUpdate,
  selectedVendor,
  handleUpdate,
  setSelectedVendor,
  zoplarPocs,
}) => {
  const handleChange = (event) => {
    const { id, value } = event.target;
    setSelectedVendor((prevVendor) => ({
      ...prevVendor,
      [id]: value,
    }));
  };

  return (
    <div>
      <Dialog
        onClose={handleCloseUpdate}
        aria-labelledby="customized-dialog-title"
        open={openUpdate}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Vendor Update
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseUpdate}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <TextField
            required
            autoFocus
            margin="dense"
            id="name"
            label="Channel Partner's Name"
            type="text"
            value={selectedVendor ? selectedVendor.name : ""}
            fullWidth
            onChange={handleChange}
          />
          <TextField
            required
            autoFocus
            margin="dense"
            id="partnerPocName"
            label="POC Name"
            type="text"
            value={selectedVendor ? selectedVendor.partnerPocName : ""}
            fullWidth
            onChange={handleChange}
          />
          <TextField
            required
            autoFocus
            margin="dense"
            id="partnerPocNo"
            label="POC No"
            type="text"
            value={selectedVendor ? selectedVendor.partnerPocNo : ""}
            fullWidth
            onChange={handleChange}
          />
          <TextField
            autoFocus
            margin="dense"
            id="partnerPocEmail"
            label="POC Email"
            type="text"
            value={selectedVendor ? selectedVendor.partnerPocEmail : ""}
            fullWidth
            onChange={handleChange}
          />
          {/* <TextField
                margin="dense"
                id="gstNo"
                label="GST Number"
                type="text"
                value={formData.gstNo}
                fullWidth
                onChange={handleChange}
              /> */}
          <TextField
            margin="dense"
            id="country"
            label="Country"
            type="text"
            value={selectedVendor ? selectedVendor.country : ""}
            fullWidth
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="state"
            label="State / UT"
            type="text"
            value={selectedVendor ? selectedVendor.state : ""}
            fullWidth
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="city"
            label="City"
            type="text"
            value={selectedVendor ? selectedVendor.city : ""}
            fullWidth
            onChange={handleChange}
          />
          {/* <TextField
                margin="dense"
                id="pinCode"
                label="Pin Code"
                type="text"
                value={formData.pinCode}
                fullWidth
                onChange={handleChange}
              />
              <TextField
                margin="dense"
                id="address"
                label="Enter Complete Address"
                type="text"
                value={formData.address}
                fullWidth
                onChange={handleChange}
              /> */}
          {/* <TextField
            margin="dense"
            id="zoplarPoc"
            label="Zoplar POC Name"
            type="text"
            value={selectedVendor ? selectedVendor.zoplarPoc : ""}
            fullWidth
            onChange={handleChange}
          /> */}
          <div
            style={{
              marginTop: "8px",
              fontFamily: "Inter",
              fontSize: "15px",
              fontWeight: "350",
            }}
          >
            <Select
              onChange={(newValue) => {
                setSelectedVendor((prevVendor) => ({
                  ...prevVendor,
                  zoplarPoc: newValue.value,
                }));
              }}
              options={zoplarPocs}
              placeholder="Select Zoplar POC*"
              style={{
                marginTop: "5px",
              }}
              // value={selectedVendor ? selectedVendor.zoplarPoc : ""}
              value={
                selectedVendor && selectedVendor.zoplarPoc !== ""
                  ? {
                      value: selectedVendor.zoplarPoc,
                      label: selectedVendor.zoplarPoc,
                    }
                  : ""
              }
            />
          </div>
          {/* <TextField
                margin="dense"
                id="onBoarded"
                label="Is this Vendor Procured"
                type="text"
                value={formData.onBoarded}
                fullWidth
                onChange={handleChange}
              /> */}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleUpdate}>
            Update Vendor
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Vendors;
