import React from "react";
import "./NotFound.css";

const NotFound = () => {
  const NotFoundImg = "https://zoplardatabase.s3.ap-southeast-2.amazonaws.com/pageimages/NotFound.png"
  return (
    <div className="wrapper-error-page">
      <div className="error-page">
        <img src={NotFoundImg} alt="error" />
        <h1>404 Error</h1>
        <p>Page not found</p>
      </div>
    </div>
  );
};

export default NotFound;