import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import QueryDetails from "./QueryDetails";
import SearchPriceList from "./searchPriceList";
import Badge from "@mui/material/Badge";
import "./quotationCreation.css";
import { useParams } from "react-router-dom";

function CustomTabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const QuotationCreation = () => {
  const { queryId, quotationId } = useParams();
  const divStyle = {
    display: "flex",
    position: "absolute",
    top: "16%",
    width: "97%",
    marginLeft: "3%",
    textAlign: "right",
    flexDirection: "column",
    paddingBottom: "5%",
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const storedItemsString = localStorage.getItem("selectedItems");
  const storedItems = storedItemsString ? JSON.parse(storedItemsString) : [];

  const initialProductCount = storedItems.length || 0;
  const [productCount, setProductCount] = useState(initialProductCount);

  return (
    <div style={divStyle}>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            borderBottom: "none",
            borderColor: "divider",
            flex: "1",
            width: "70%",
            marginLeft: "10%",
            gap: "10px",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="fullWidth"
            sx={{
              "& .MuiTabs-root": {
                fontSize: "24px",
                fontWeight: "700",
                fontFamily: "'Inter-SemiBold', 'Helvetica', sans-serif",
              },
            }}
          >
            <Tab
              label={
                <Badge
                  badgeContent={productCount}
                  color="primary"
                  sx={{
                    "& .MuiBadge-badge": {
                      right: -10,
                      top: 2,
                      padding: "0 4px",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "#E6EBFF ",
                    },
                  }}
                >
                  Quotation Details
                </Badge>
              }
              id="simple-tab-0"
              aria-controls="simple-tabpanel-0"
              sx={{
                backgroundColor: "white",
                color: "#464C5C !important",
                borderRadius: "7px 7px 0px 0px",
                boxShadow: " 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                fontFamily: "Inter",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
                border: "0.7px solid #C9C9C9",
                "&.Mui-selected": {
                  backgroundColor: "#E6EBFF ",
                },
              }}
            />
            <Tab
              label={<Badge>Price List</Badge>}
              id="simple-tab-1"
              aria-controls="simple-tabpanel-1"
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#E6EBFF ",
                },
                backgroundColor: "#fff",
                color: "#464C5C  !important",
                borderRadius: "7px 7px 0px 0px",
                boxShadow: " 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                fontFamily: "Inter",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "normal",
                border: "0.7px solid #C9C9C9",
              }}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
          sx={{ border: "10px solid red" }}
        >
          <QueryDetails
            setProductCount={setProductCount}
            queryId={queryId}
            quotationId={quotationId}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <SearchPriceList setProductCount={setProductCount} />
        </CustomTabPanel>
      </Box>
    </div>
  );
};

export default QuotationCreation;
