import React, { useEffect } from "react";
import ImageUploadPopup from "./Imageuploadpopup";
import Slider from "react-slick";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./catalog.css";
import { useLocation, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from "react";

const Opendetail = (results) => {
  const location = useLocation();
  const [pro, setPro] = useState(location.state?.pro || {});

  const [forceRerender, setForceRerender] = useState(false);
  const [editedDescription, setEditedDescription] = useState(pro.description);
  const [showdesc, setshowdesc] = useState(true);
  const [imagepopup, setimagepopup] = useState(false);
  const [showeditdesc, setshoweditdesc] = useState(false);
  const [imageFiles, setImageFiles] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageChange = (index) => {
    setCurrentImageIndex(index);
  };
  const [formData, setFormData] = useState({
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(e.target.value);
    setFormData({ ...formData, [name]: value });
    setEditedDescription(value);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const updateResult = (newResult) => {
    setPro((prevPro) => ({
      ...prevPro,
      image: newResult,
    }));
  };

  const closedesc = () => {
    setshowdesc(true);
    setshoweditdesc(false);
  };

  const handleDeleteImageWithRetry = async () => {
    const maxRetries = 3; // Maximum number of retries
    let retries = 0;
    const confirmed = window.confirm(
      "Are you sure you want to delete this image?"
    );

    while (retries < maxRetries) {
      try {
        if (confirmed) {
          const response = await fetch(
            `/delimage/${pro.Sku}/${currentImageIndex}`,
            {
              method: "DELETE",
            }
          );

          if (response.ok) {
            const data = await response.json();
            const updatedItem = data.updatedItem;

            toast.success("Image Deleted ", {
              position: toast.POSITION.TOP_RIGHT,
            });

            setPro((prevPro) => ({
              ...prevPro,
              image: updatedItem.image,
            }));
            setCurrentImageIndex(0);
            return; // Exit the function if deletion is successful
          } else {
            throw new Error("Image deletion failed");
          }
        }
      } catch (error) {
        console.error("Error deleting image:", error);

        if (retries === maxRetries - 1) {
          toast.error("Image deletion failed ");
          break; // Exit the loop after maximum retries
        }

        retries++;
        await new Promise((resolve) => setTimeout(resolve, 3000)); // Wait for 3 seconds before retrying
      }
    }
  };
  const handleDeleteImage = async () => {
    if (pro) {
      handleDeleteImageWithRetry();
    }
  };

  const EditDescrption = async (Sku) => {
    try {
      if (Sku) {
        const { description } = formData;
        if (formData) {
          if (!description) {
            toast.error("Fill Description", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            const response = await fetch(`/editdetails/${pro.Sku}`, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ description }),
            });

            if (response.ok) {
              const result = await response.json();
              // console.log(result);

              if (result.exist) {
                toast.error("Same Description", {
                  position: toast.POSITION.TOP_RIGHT,
                });
                // console.log(result);
              } else {
                toast.success("Description Updated ", {
                  position: toast.POSITION.TOP_RIGHT,
                });
                pro.description = editedDescription;
                setshoweditdesc(false);
              }
            } else {
              toast.error("Failed to update Description", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
            pro.description = description;
            // console.log("pro.Description updated:", pro.description);
            setshowdesc(true);
            setshoweditdesc(false);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    EditDescrption();
  }, [pro.skuCode]);

  const openImageUploadPopup = () => {
    setimagepopup(true);
    setImageFiles(pro.image);
  };

  const handleFileUpload = () => {
    // console.log("helo");
  };

  const admineditpop = (skuCode) => {
    setshowdesc(false);
    setshoweditdesc(true);

    setFormData({
      ...formData,
      description: pro.description,
    });
  };

  return (
    <div key={forceRerender}>
      {/* <Button className='admincatalogskubut' onClick={openskupage}>CreateSKU</Button> */}
      {pro && (
        <table className="admincatalogtable">
          <div className="admincatalogdetailtable">
            <div className="adminimageborder">
              <tr className="admincatalogrow">
                <Button
                  className="admindeleteimage"
                  onClick={handleDeleteImage}
                >
                  Delete
                </Button>
                <div className="admincatalogcontainer">
                  <Slider {...sliderSettings} afterChange={handleImageChange}>
                    {pro.image.map((image, index) => (
                      <div className="adminimgcontainer" key={index}>
                        <img
                          className="admincatalogproductimage"
                          src={image}
                          alt={`Product Image ${index}`}
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
                <Button
                  className="adminimageupload"
                  onClick={openImageUploadPopup}
                >
                  <span className="adminimageuploadtext">Upload</span>
                </Button>

                {imagepopup && (
                  <ImageUploadPopup
                    onClose={() => setimagepopup(false)}
                    onImageUpload={handleFileUpload}
                    Sku={pro.Sku}
                    result={imageFiles}
                    onUpdateResult={updateResult}
                  />
                )}
              </tr>
              <ToastContainer autoClose={2000} />
            </div>
            <div className="admindetailcatalog">
              <tr className="admincataogrow">
                <th className="admincatalogheader">Product Name :</th>
                <td className="admincatalogcoloumn">{pro.name}</td>
              </tr>
              <hr></hr>
              <tr className="admincataogrow">
                <th className="admincatalogheader">SKU Code :</th>
                <td className="admincatalogcoloumn">{pro.Sku}</td>
              </tr>
              <hr></hr>
              <tr className="admincataogrow">
                <th className="admincatalogheader">Brand Name :</th>
                <td className="admincatalogcoloumn">{pro.Brand}</td>
              </tr>
              <hr></hr>

              <tr className="admincataogrow">
                <th className="admincatalogheader">Specification :</th>
                <td className="admincatalogcoloumn">{pro.Size}</td>
              </tr>

              <hr></hr>
              <tr className="admincataogrow">
                <th className="admincatalogheader">Category :</th>
                <td className="admincatalogcoloumn">{pro.prodcat}</td>
              </tr>
              <hr></hr>
              <tr className="admincataogrow">
                <th className="admincatalogheader">Medical Field 1 :</th>
                <td className="admincatalogcoloumn">{pro.category}</td>
              </tr>
              <hr></hr>
              <tr className="admincataogrow">
                <th className="admincatalogheader">Medical Field 2 :</th>
                <td className="admincatalogcoloumn">{pro.Medical_Field_2}</td>
              </tr>
              <hr></hr>
              <tr className="admincataogrow">
                <th className="admincatalogheader">Medical Field 3 :</th>
                <td className="admincatalogcoloumn">{pro.Medical_Field_3}</td>
              </tr>
              <hr></hr>
              <tr className="admincataogrow">
                <th className="admincatalogheader">GST :</th>
                <td className="admincatalogcoloumn">{pro.GST}</td>
              </tr>
              <hr></hr>
              <tr className="admincataogrow">
                <th className="admincatalogheader">HSN :</th>
                <td className="admincatalogcoloumn">{pro.HSN}</td>
              </tr>
              <hr></hr>
            </div>
          </div>
          {showdesc && (
            <div className="admindescription">
              <h4>
                Description <span>*</span>{" "}
              </h4>
              <h6
                className="admineditdesc"
                onClick={() => admineditpop(pro.Sku)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                >
                  <path
                    d="M22.554 0.764696C21.5377 -0.254899 19.8804 -0.254899 18.8608 0.764696L9.26935 10.3562C9.22617 10.3994 9.19296 10.4459 9.16307 10.499C9.15643 10.5123 9.14978 10.5223 9.14314 10.5355C9.11657 10.5887 9.09665 10.6451 9.08336 10.7016L8.02059 16.2048C7.97742 16.4206 8.04716 16.6465 8.20326 16.7992C8.32946 16.9254 8.49884 16.9952 8.67154 16.9952C8.71471 16.9952 8.75457 16.9919 8.79774 16.9819L14.3009 15.9125C14.3574 15.9025 14.4138 15.8793 14.467 15.8527C14.4802 15.8461 14.4902 15.8394 14.5035 15.8328C14.5533 15.8029 14.6031 15.7697 14.6463 15.7265L24.2378 6.14165C25.2541 5.12537 25.2541 3.46812 24.2378 2.44852L22.554 0.764696ZM9.51179 15.4907L10.1561 12.1762L12.8263 14.8464L9.51179 15.4907ZM14.178 14.3217L10.6808 10.8245L17.5822 3.91979L21.0794 7.41697L14.178 14.3217ZM23.2979 5.20176L22.0193 6.4804L18.5221 2.98323L19.8007 1.70458C20.3022 1.20309 21.1159 1.20309 21.6141 1.70458L23.2979 3.38841C23.7994 3.88658 23.7994 4.70027 23.2979 5.20176Z"
                    fill="#0081DF"
                  />
                  <path
                    d="M22.2982 12.7475C21.9329 12.7475 21.634 13.0464 21.634 13.4117V18.6126C21.634 21.3127 19.4387 23.508 16.7386 23.508H6.22385C3.52375 23.508 1.32846 21.3127 1.32846 18.6126V8.09787C1.32846 5.39777 3.52375 3.20249 6.22385 3.20249H11.4248C11.7901 3.20249 12.089 2.90358 12.089 2.53825C12.089 2.17293 11.7901 1.87402 11.4248 1.87402H6.22385C2.78977 1.87402 0 4.66712 0 8.09787V18.6126C0 22.0467 2.79309 24.8365 6.22385 24.8365H16.7386C20.1727 24.8365 22.9625 22.0434 22.9625 18.6126V13.4117C22.9625 13.0431 22.6669 12.7475 22.2982 12.7475Z"
                    fill="#0081DF"
                  />
                </svg>
              </h6>
              <textarea className="admindescarea" readOnly>
                {pro.description}
              </textarea>
            </div>
          )}
          {showeditdesc && (
            <div>
              <form method="POST">
                <div className="admineditdescheader">Edit Description</div>
                <textarea
                  className="admineditdescarea"
                  type="text"
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                ></textarea>
                <div className="admindescfooterbutton">
                  <Button
                    className="adminclosedetailbutton"
                    onClick={() => closedesc()}
                  >
                    Close
                  </Button>
                  <Button
                    className="admindonebutton"
                    type="button"
                    onClick={() => EditDescrption(pro.Sku)}
                  >
                    Done
                  </Button>
                </div>
              </form>
            </div>
          )}
          {/* {showfinalsubmitbutton && (
            <Button className="adminsubmitdesc">Submit</Button>
          )} */}
        </table>
      )}
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default Opendetail;
